import React from 'react';
import ResetEmailForm from './ResetEmailForm';
import { Avatar } from 'antd';
import './reset-email.css';

const ResetEmail = ({ onResetEmail, onViewLogin }) => (
  <div className="reset-email-form-holder">
    <div className="reset-email-avatar">
      <Avatar size="large" icon="lock" />
      <h2>Send Reset Email</h2>
    </div>
    <ResetEmailForm onViewLogin={onViewLogin} onResetEmail={onResetEmail} />
  </div>
);

export default ResetEmail;
