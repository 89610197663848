import React, { Component } from "react";
import RadioGroup from "antd/es/radio/group";
import Radio from "antd/es/radio/radio";
import FormItem from "../../../shared/form/FormItem";

class AuthTypeRadioGroupFormItem extends Component {
    onChange = (e) => {
        this.props.onChange(e.target.value);
    }
    render() {
        return (
            <FormItem
                label="Auth Type"
                property="authType"
                form={this.props.form}
                isRequired={true}
                initialValue={this.props.initialValue}
                message="Please input the callback auth type"
            >
                <RadioGroup onChange={this.onChange}>
                    <Radio value="KEY">Key</Radio>
                    <Radio value="KEY_SECRET">Key & Secret</Radio>
                    <Radio value="BASIC_AUTH">Basic Authentication</Radio>
                    <Radio value="DISABLED">Disabled</Radio>
                </RadioGroup>
            </FormItem>
        )
    }
}
export default AuthTypeRadioGroupFormItem;