import React, { Component } from 'react';
import CallbackForm from './CallbackForm';

class CreateForm extends Component {
  render() {
    return (
      <CallbackForm
        principal={this.props.principal}
        bean={this.props.create}
        onSubmit={this.props.onCreate}
        onClose={this.props.onClose}
      />
    );
  }
}

export default CreateForm;
