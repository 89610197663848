import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import Logout from '../components/logout';
import { LOGOUT } from "../actions/auth-actions";

const mapStateToProps = (state) => {
  return {
    principal: state.auth.principal
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch({ type: LOGOUT })
  }
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Logout)
);
