import React from 'react';
import { Row, Col, Card, Tabs } from 'antd';
import SimpleXYChart from './cards/SimpleXYChart';

const TrafficBreakdown = ({ filter, dashboard, onSyncCategory }) => (
  <Tabs>
    <Tabs.TabPane tab="Customer" key="1">
      <Row gutter={15}>
        <Col span={12} className="stat-col">
          <Card title="Customer" type="inner">
            <SimpleXYChart
              filter={filter}
              keyword="customerName.keyword"
              dataKey="byCustomerName"
              bucketSize={5}
              xAngle={0}
              yAngle={-35}
              data={dashboard}
              sync={onSyncCategory}
            />
          </Card>
        </Col>
        <Col span={12} className="stat-col">
          <Card title="Customer Type" type="inner">
            <SimpleXYChart
              filter={filter}
              keyword="customerType.keyword"
              dataKey="byCustomerType"
              bucketSize={5}
              xAngle={0}
              yAngle={-35}
              data={dashboard}
              sync={onSyncCategory}
            />
          </Card>
        </Col>
      </Row>
    </Tabs.TabPane>
    <Tabs.TabPane tab="Rating" key="2">
      <Row gutter={15}>
        <Col span={8} className="stat-col">
          <Card title="By Category" type="inner">
            <SimpleXYChart
              filter={filter}
              keyword="category.keyword"
              dataKey="byCategory"
              bucketSize={5}
              xAngle={0}
              yAngle={-35}
              data={dashboard}
              sync={onSyncCategory}
            />
          </Card>
        </Col>
        <Col span={8} className="stat-col">
          <Card title="By Band" type="inner">
            <SimpleXYChart
              filter={filter}
              keyword="band.keyword"
              dataKey="byBand"
              bucketSize={5}
              xAngle={0}
              yAngle={-25}
              data={dashboard}
              sync={onSyncCategory}
            />
          </Card>
        </Col>
        <Col span={8} className="stat-col">
          <Card title="By Classification" type="inner">
            <SimpleXYChart
              filter={filter}
              keyword="classification.keyword"
              dataKey="byChargeType"
              bucketSize={5}
              xAngle={0}
              yAngle={-25}
              data={dashboard}
              sync={onSyncCategory}
            />
          </Card>
        </Col>
      </Row>
    </Tabs.TabPane>
    <Tabs.TabPane tab="Direction" key="3">
      <Row gutter={15}>
        <Col span={8} className="stat-col">
          <Card title="By Direction" type="inner">
            <SimpleXYChart
              filter={filter}
              keyword="direction.keyword"
              dataKey="byDirection"
              bucketSize={5}
              xAngle={0}
              yAngle={-35}
              data={dashboard}
              sync={onSyncCategory}
            />
          </Card>
        </Col>
        <Col span={8} className="stat-col">
          <Card title="By Caller" type="inner">
            <SimpleXYChart
              filter={filter}
              keyword="caller.keyword"
              dataKey="byCaller"
              bucketSize={5}
              xAngle={0}
              yAngle={-25}
              data={dashboard}
              sync={onSyncCategory}
            />
          </Card>
        </Col>
        <Col span={8} className="stat-col">
          <Card title="By Called" type="inner">
            <SimpleXYChart
              filter={filter}
              keyword="called.keyword"
              dataKey="byCalled"
              bucketSize={5}
              xAngle={0}
              yAngle={-25}
              data={dashboard}
              sync={onSyncCategory}
            />
          </Card>
        </Col>
      </Row>
    </Tabs.TabPane>
  </Tabs>
);

export default TrafficBreakdown;
