import React from 'react';
import SimpleXYChart from './cards/SimpleXYChart';

const TrafficBreakdown = ({ filter, dashboard, onSyncCategory, id, height }) => (
  <SimpleXYChart
    id={id}
    filter={filter}
    height={height}
    keyword="customerType.keyword"
    dataKey="byCustomerTypeHistogram"
    type="histogram"
    bucketSize={3}
    gType="line"
    xType="date"
    xAngle={-35}
    yAngle={-35}
    data={dashboard}
    sync={onSyncCategory}
  />
);

export default TrafficBreakdown;
