import { connect } from 'react-redux';
import Trunk from '../components/customer/trunking/Trunk';
import * as actions from '../actions/trunk-actions';

const mapStateToProps = state => {
  return {
    principal: state.auth.principal,
    trunk: state.trunk,
    customer: state.customer.selected
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: {
      onCloseCreate: () => dispatch(actions.closeCreate()),
      onCloseEdit: () => dispatch(actions.closeEdit()),
      onOpenCreate: () => dispatch(actions.openCreate()),
      onSearch: filter => dispatch(actions.search(filter)),
      onSelect: trunk => dispatch(actions.select(trunk)),
      onDelete: id => dispatch(actions.remove(id)),
      onUpdate: payload => dispatch(actions.update(payload)),
      onCreate: payload => dispatch(actions.create(payload))
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Trunk);
