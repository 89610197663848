import { connect } from 'react-redux';
import * as actions from '../actions/customer-actions';
import * as dashboardActions from '../actions/dashboard-actions';
import Customer from '../components/customer/Customer';

const mapStateToProps = state => {
  return {
    principal: state.auth.principal,
    customer: state.customer,
    dashboard: state.dashboard
  };
};

const mapDispatchToProps = dispatch => {
  return {
    customerActions: {
      onCloseCreate: () => dispatch(actions.closeCreate()),
      onSearchCustomer: filter => dispatch(actions.search(filter)),
      onOpenCreateCustomer: () => dispatch(actions.openCreate()),
      onCloseCustomer: () => dispatch(actions.close()),
      onDeleteCustomer: id => dispatch(actions.remove(id)),
      onSyncCustomer: id => dispatch(actions.sync(id)),
      onSelectCustomer: id => dispatch(actions.select(id)),
      onCreateCustomer: payload => dispatch(actions.create(payload)),
      onUpdateCustomer: payload => dispatch(actions.update(payload))
    },
    dashboardActions: {
      onFilter: filter => dispatch(dashboardActions.filter(filter)),
      onSyncIVRErrors: filter => dispatch(dashboardActions.syncIVRErrors(filter)),
      onSyncCategory: (filter, opts) => dispatch(dashboardActions.syncCategory(filter, opts))
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Customer);
