import { CUSTOMER_PATH } from "../Props.js";
import API from "./api-service";


const jsonHeader = (apiKey) => {
    return {
        timeout: 5000,
        headers: {
            "X-VIVA-UserId": apiKey,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }
}

function filter(filter, apiKey) {
    return API.filter(filter, CUSTOMER_PATH, jsonHeader(apiKey));
}

function get(id, apiKey) {
    return API.get(id, CUSTOMER_PATH, jsonHeader(apiKey));
}

function list(apiKey) {
    return API.list(CUSTOMER_PATH, jsonHeader(apiKey));
}

function put(payload, id, apiKey) {
    return API.put(id, payload, CUSTOMER_PATH, jsonHeader(apiKey));
}

function remove(id, apiKey) {
    return API.remove(id, CUSTOMER_PATH, jsonHeader(apiKey));
}

function post(payload, apiKey) {
    return API.post(payload, CUSTOMER_PATH, jsonHeader(apiKey));
}

export default {
    get, filter, put, post, remove, list
}


