import React from 'react';
import { Avatar } from 'antd';
import './logout.css';

const Logout = () => (
  <div className="logout-holder">
    <div className="login-avatar">
      <Avatar size="large" icon="lock" />
      <br />
      <h2 className="logout-header">
        You have successfully logged out and you may close this window.
      </h2>
    </div>
  </div>
);

export default Logout;
