import React, { Fragment } from 'react';
import { Table, Pagination, Card } from 'antd';

const columns = [
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username'
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'Customer',
    dataIndex: 'customer.name',
    key: 'customer.name'
  }
];

const determineRoleClick = (principal, id, onSelectUser) => {
  if (principal.permissions.filter(p => p.permission === 'user/view').length > 0) onSelectUser(id);
};

const renderTable = (principal, pageable, loading, onSelectUser) => {
  if (pageable) {
    return (
      <Table
        rowKey="id"
        bordered
        size="small"
        loading={loading}
        pagination={false}
        onRow={(bean, rowIndex) => {
          return {
            onClick: () => determineRoleClick(principal, bean.id, onSelectUser)
          };
        }}
        dataSource={pageable ? pageable.content : []}
        columns={columns}
      />
    );
  }
};

const renderPagination = (pageable, loading, filter, onSearchUser) => {
  if (pageable) {
    return (
      <Pagination
        size="small"
        loading={loading}
        defaultCurrent={pageable.number + 1}
        page={pageable.number + 1}
        total={pageable.totalElements}
        pageSize={pageable.size}
        onChange={page =>
          onSearchUser({
            ...filter,
            page: page - 1
          })
        }
      />
    );
  }
};

const renderList = (principal, pageable, selected, loading, filter, onSelectUser, onSearchUser) => {
  if (!selected)
    return (
      <Card type="inner" style={{ minHeight: '100vh' }}>
        {renderTable(principal, pageable, loading, onSelectUser)}
        {renderPagination(pageable, loading, filter, onSearchUser)}
      </Card>
    );
};

const UserList = ({
  principal,
  pageable,
  loading,
  selected,
  filter,
  onSelectUser,
  onSearchUser
}) => {
  return (
    <Fragment>
      {renderList(principal, pageable, selected, loading, filter, onSelectUser, onSearchUser)}
    </Fragment>
  );
};

export default UserList;
