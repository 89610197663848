import React, { Component } from "react";
import { Select } from "antd";
import FormItem from "../../../shared/form/FormItem";
import PropTypes from 'prop-types';

class TrunkOverrideSelectFormItem extends Component {
    static propTypes = {
        customer: PropTypes.object,
        form: PropTypes.object,
        initialValue: PropTypes.any
    }
    render() {
        const opts = this.props.customer.trunks.map(trunk => {
            return <Select.Option key={trunk.id}>{trunk.ip}</Select.Option>;
        });
        return (
            <FormItem
                label="Trunk Override"
                property="trunk"
                isRequired={false}
                form={this.props.form}
                initialValue={this.props.initialValue}
            >
                <Select
                    allowClear={true}
                    showSearch
                    placeholder="Select a trunk"
                    optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    {opts}
                </Select>
            </FormItem>
        )
    }
}
export default TrunkOverrideSelectFormItem;