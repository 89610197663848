import { connect } from 'react-redux';
import Callback from '../components/customer/callback/Callback';
import * as actions from '../actions/callback-actions';
import * as dashboardActions from '../actions/dashboard-actions';

const mapStateToProps = state => {
  return {
    principal: state.auth.principal,
    callback: state.callback,
    customer: state.customer.selected,
    dashboard: state.dashboard
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: {
      onCloseCreate: () => dispatch(actions.closeCreate()),
      onCloseEdit: () => dispatch(actions.closeEdit()),
      onOpenCreate: () => dispatch(actions.openCreate()),
      onSearch: filter => dispatch(actions.search(filter)),
      onSelect: callback => dispatch(actions.select(callback)),
      onDelete: id => dispatch(actions.remove(id)),
      onUpdate: payload => dispatch(actions.update(payload)),
      onCreate: payload => dispatch(actions.create(payload))
    },
    dashboardActions: {
      onFilter: filter => dispatch(dashboardActions.filter(filter)),
      onSyncIVRErrors: filter => dispatch(dashboardActions.syncIVRErrors(filter)),
      onSyncCategory: (filter, opts) => dispatch(dashboardActions.syncCategory(filter, opts))
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Callback);
