import React, { Fragment } from 'react';
import TrunkCreateForm from './TrunkCreateForm';
import TrunkEditForm from './TrunkEditForm';
import TrunkFilterForm from './TrunkFilterForm';
import StandardList from '../../shared/StandardList';
import StandardToolbar from '../../shared/StandardToolbar';
import SecuredButton from '../../shared/SecuredButton';

import { Drawer } from 'antd';
import './trunk.css';

const columns = [
  { title: 'ID', dataIndex: 'id', key: 'id' },
  { title: 'IP Address', dataIndex: 'ip', key: 'ip' },
  { title: 'Port', dataIndex: 'port', key: 'port' },
  { title: 'Vendor', dataIndex: 'vendor', key: 'vendor' },
  { title: 'Priority', dataIndex: 'priority', key: 'priority' }
];

const Trunk = ({ actions, trunk, principal }) => (
  <Fragment>
    <StandardToolbar
      left={<TrunkFilterForm filter={trunk.filter} onFilter={actions.onSearch} />}
      right={
        <SecuredButton
          icon="plus"
          principal={principal}
          title="Create Trunk"
          onClick={actions.onOpenCreate}
          permission="customer/create/trunk"
        />
      }
    />

    <StandardList
      {...actions}
      {...trunk}
      columns={columns}
      principal={principal}
      permission="customer/edit/trunk"
    />

    <Drawer
      width={860}
      title="Create new trunk"
      onClose={actions.onCloseCreate}
      visible={trunk.showCreate}
    >
      <TrunkCreateForm
        principal={principal}
        onCreate={actions.onCreate}
        onClose={actions.onCloseCreate}
        loading={trunk.loading}
        create={trunk.create}
      />
    </Drawer>
    <Drawer title="Edit trunk" width={860} onClose={actions.onCloseEdit} visible={trunk.showEdit}>
      {trunk.showEdit && (
        <TrunkEditForm
          principal={principal}
          loading={trunk.loading}
          onUpdate={actions.onUpdate}
          onDelete={actions.onDelete}
          onClose={actions.onCloseEdit}
          selected={trunk.selected}
        />
      )}
    </Drawer>
  </Fragment>
);

export default Trunk;
