import React, { Component, Fragment } from 'react';
import { Form, Input, Button } from 'antd';

const FormItem = Form.Item;

class UserFilterForm extends Component {
  constructor(props) {
    super(props);
    props.onFilter(props.filter);
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let f = {
          ...this.props.filter,
          ...values
        };
        this.props.onFilter(f);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Fragment>
        {!this.props.user && (
          <Form onSubmit={this.handleSubmit} className="user-filter-form" layout="inline">
            <FormItem label="Username">
              {getFieldDecorator('username', {
                rules: [{ required: false }],
                initialValue: this.props.filter.username
              })(<Input placeholder="Username" autoComplete="user-name" autoFocus />)}
            </FormItem>

            <FormItem label="Email">
              {getFieldDecorator('email', {
                rules: [{ required: false }],
                initialValue: this.props.filter.email
              })(<Input placeholder="Email Address" autoComplete="user-email" />)}
            </FormItem>

            <FormItem>
              <Button type="primary" htmlType="submit" className="login-form-button" block={true}>
                Search
              </Button>
            </FormItem>
          </Form>
        )}
      </Fragment>
    );
  }
}

export default Form.create()(UserFilterForm);
