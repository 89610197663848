import React, { Component } from 'react';
import { Form, Input, Modal, Select, Switch } from 'antd';
import FormItem from '../../shared/form/FormItem';
import CancelSubmitButtonBar from '../../shared/CancelSubmitButtonBar';
import RadioGroup from 'antd/es/radio/group';
import Radio from 'antd/es/radio/radio';
import PropTypes from 'prop-types';

const confirm = Modal.confirm;

class TrunkForm extends Component {
  static propTypes = {
    bean: PropTypes.object,
    onSubmit: PropTypes.func,
    onDelete: PropTypes.func
  };
  constructor(props) {
    super();
    this.state = {
      type: props.bean.type
    };
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values);
      }
    });
  };
  handleDelete = () => {
    let p = this.props;
    confirm({
      title: 'Are you sure?',
      onOk() {
        p.onDelete(p.bean.id);
      },
      onCancel() {}
    });
  };
  render() {
    return (
      <Form onSubmit={this.handleSubmit} layout="vertical">
        <FormItem
          label="IP Address"
          property="ip"
          isRequired={true}
          isVisible={true}
          form={this.props.form}
          initialValue={this.props.bean.ip}
          message="Please input the trunk ip address"
        >
          <Input placeholder="IP Address" />
        </FormItem>
        <FormItem
          label="Port"
          property="port"
          isVisible={true}
          isRequired={true}
          form={this.props.form}
          initialValue={this.props.bean.port}
          message="Please input the trunk port"
        >
          <Input placeholder="Port Number" type="number" />
        </FormItem>
        <FormItem
          label="Priority"
          property="priority"
          isVisible={true}
          isRequired={true}
          form={this.props.form}
          initialValue={this.props.bean.priority}
          message="Please input the trunk priority"
        >
          <Input placeholder="Priority" type="number" />
        </FormItem>
        <FormItem
          label="Description"
          property="description"
          isVisible={true}
          isRequired={true}
          form={this.props.form}
          initialValue={this.props.bean.description}
          message="Please input the trunk description"
        >
          <Input placeholder="Description" />
        </FormItem>
        <FormItem
          label="ANI Format"
          property="callerFormat"
          isRequired={true}
          form={this.props.form}
          initialValue={this.props.bean.callerFormat}
          message="Please input the trunk ANI type"
        >
          <RadioGroup>
            <Radio value="national">NATIONAL</Radio>
            <Radio value="e164">E164</Radio>
            <Radio value="e164p">E164+</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem
          label="DNIS Format"
          property="calledFormat"
          isRequired={true}
          form={this.props.form}
          initialValue={this.props.bean.calledFormat}
          message="Please input the trunk DNIS type"
        >
          <RadioGroup>
            <Radio value="national">NATIONAL</Radio>
            <Radio value="e164">E164</Radio>
            <Radio value="e164p">E164+</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem
          label="Vendor/Type"
          property="vendor"
          isRequired={true}
          form={this.props.form}
          initialValue={this.props.bean.vendor}
          message="Please input the trunk vendor/type"
        >
          <Select placeholder="Select Vendor">
            <Select.Option value="avaya">Avaya</Select.Option>
            <Select.Option value="q20">Q20</Select.Option>
            <Select.Option value="generic">Generic</Select.Option>
          </Select>
        </FormItem>
        <FormItem
          label="Monitored?"
          property="monitored"
          isRequired={true}
          form={this.props.form}
          initialValue={this.props.bean.monitored}
          valuePropName="checked"
        >
          <Switch />
        </FormItem>
        <CancelSubmitButtonBar
          showDelete={this.props.bean.id !== undefined}
          onClose={this.props.onClose}
          onDelete={this.handleDelete}
          createText={this.props.bean.id ? 'Edit' : 'Create'}
          principal={this.props.principal}
          permission="customer/delete/trunk"
        />
      </Form>
    );
  }
}

export default Form.create()(TrunkForm);
