import React, { Component } from 'react';
import { Card, Tabs } from 'antd';
import DashboardFilter from './DashboardFilter';
import TrafficBreakdown from './TrafficBreakdown';
import TrafficOverview from './TrafficOverview';
import IVRErrorChart from './cards/IVRErrorChart';
import 'react-vis/dist/style.css';
import './dashboard.css';

class Dashboard extends Component {
  updateFilters = duration => {
    this.setState({ duration });
  };

  render() {
    const { filter, ivrErrors, loading } = this.props.dashboard;
    const { onSyncCategory, onSyncIVRErrors, onFilter } = this.props.actions;
    return (
      <Card
        extra={
          <DashboardFilter onFilter={onFilter} filter={filter} principal={this.props.principal} />
        }
        style={{ margin: '5px' }}
      >
        <Tabs defaultActiveKey="1" size="small">
          <Tabs.TabPane tab="Traffic Overview" key="1">
            <TrafficOverview
              title="By Time"
              filter={filter}
              dashboard={this.props.dashboard}
              onSyncCategory={onSyncCategory}
            />
            <br />
            <TrafficBreakdown
              filter={filter}
              dashboard={this.props.dashboard}
              onSyncCategory={onSyncCategory}
            />
          </Tabs.TabPane>

          {/* <Tabs.TabPane tab="Traffic Breakdown" key="2">
            
          </Tabs.TabPane> */}

          <Tabs.TabPane tab="Integration Errors" key="3">
            <IVRErrorChart
              loading={loading}
              height={600}
              filter={filter}
              ivrErrors={ivrErrors}
              sync={onSyncIVRErrors}
            />
          </Tabs.TabPane>
        </Tabs>
      </Card>
    );
  }
}

export default Dashboard;
