import { connect } from 'react-redux';
import ResetEmail from '../components/resetEmail/ResetEmail';
import { RESET_EMAIL } from '../actions/auth-actions';
import { ActionConst } from '../actions/constants';

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    onResetEmail: email => dispatch({ type: RESET_EMAIL, email }),
    onViewLogin: () => dispatch({ type: ActionConst.view.login })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetEmail);
