import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DeviceForm from './DeviceForm';

class DeviceCreateForm extends Component {
    static propTypes = {
        create: PropTypes.object.isRequired,
        onCreate: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired
    }
    render() {
        return (
            <DeviceForm
                bean={this.props.create}
                onSubmit={this.props.onCreate}
                onClose={this.props.onClose}
            />
        )
    }
}

export default DeviceCreateForm;