import React from 'react';
import { Form, Button, Icon } from 'antd';

const hasRole = (principal, permission) => {
  if (!permission) return true;
  if (!principal) return false;
  return principal.permissions.filter(p => p.permission === permission).length > 0;
};

const CancelSubmitButtonBar = ({
  loading,
  showDelete,
  onDelete,
  onClose,
  principal,
  permission,
  deleteText = 'Delete',
  deleteIcon = 'delete',
  cancelText = 'Cancel',
  cancelIcon = 'rollback',
  submitIcon = 'cloud-upload',
  submitText = 'Save'
}) => (
  <Form.Item style={{ textAlign: 'right' }}>
    <Button.Group>
      {showDelete && hasRole(principal, permission) && (
        <Button type="danger" htmlType="button" onClick={onDelete} title={deleteText}>
          <Icon type={deleteIcon} /> {deleteText}
        </Button>
      )}
      <Button type="dashed" htmlType="button" onClick={onClose} title={cancelText}>
        <Icon type={cancelIcon} /> {cancelText}
      </Button>
      <Button disabled={loading} type="primary" htmlType="submit" title={submitText}>
        <Icon type={submitIcon} /> {submitText}
      </Button>
    </Button.Group>
  </Form.Item>
);

export default CancelSubmitButtonBar;
