import React from 'react';
import { Menu, Modal, Dropdown, Icon, message } from 'antd';

const menuItems = [
  { key: 'delete', icon: 'delete', name: 'Delete Customer', permission: 'customer/delete' },
  {
    key: 'request-ddi',
    icon: 'number',
    name: ' Request DDI (Coming Soon)',
    permission: 'customer/ddi/request'
  },
  {
    key: 'raise-ticket',
    icon: 'issues-close',
    name: 'Raise Ticket (Coming Soon)',
    permission: 'customer/ticket/create'
  }
];

const renderMenu = (principal, selected, customerActions) => (
  <Menu onClick={e => handleMenuClick(e, selected, customerActions)}>
    {menuItems
      .filter(mi => principal.permissions.filter(pp => mi.permission === pp.permission).length > 0)
      .map(mi => (
        <Menu.Item key={mi.key}>
          <Icon type={mi.icon} />
          {mi.name}
        </Menu.Item>
      ))}
  </Menu>
);

const handleMenuClick = (e, selected, customerActions) => {
  switch (e.key) {
    case 'delete':
      handleDelete(selected, customerActions.onDeleteCustomer);
      break;
    case 'raise-ticket':
      message.info('The ability to raise a ticket is coming soon!');
      break;
    case 'request-ddi':
      message.info('The ability to request a ddi is coming soon!');
      break;
  }
};

const handleDelete = (selected, onDeleteCustomer) => {
  Modal.confirm({
    title: 'Are you sure?',
    onOk() {
      onDeleteCustomer(selected.id);
    },
    onCancel() {}
  });
};

const CustomerMenu = ({ principal, selected, customerActions }) => (
  <Dropdown.Button
    onClick={() => customerActions.onSelectCustomer(selected.id)}
    overlay={renderMenu(principal, selected, customerActions)}
  >
    Refresh
  </Dropdown.Button>
);

export default CustomerMenu;
