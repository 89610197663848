import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, InputNumber, DatePicker } from 'antd';
import { filterType } from '../../types';

const FormItem = Form.Item;

class RecorderFilterForm extends Component {
    handleSubmit = (e) => {
      e.preventDefault();
      const { form, onSearchRecorder, filter } = this.props;
      form.validateFields((err, values) => {
        if (!err) {
          let f = {
            ...filter,
            caller: values.caller,
            called: values.called,
            extension: values.extension,
            durationStart: values.durationStart,
            durationEnd: values.durationEnd,
            start: values.start,
            end: values.end
          }
          onSearchRecorder(f);
        }
      });
    };

    render() {
      const { filter, form } = this.props;

      return (
        <div className='recorder-filter-container'>
          <h3>Call Filter</h3>
          <Form
            onSubmit={this.handleSubmit}
            className='recorder-filter-form'
            layout='vertical'
          >
            <FormItem label='From'>
              {form.getFieldDecorator('start', {
                rules: [{ required: false }],
                initialValue: filter.start
              })(
                <DatePicker showTime format='YYYY-MM-DD HH:mm:ss' />
              )}
            </FormItem>
            <FormItem label='To'>
              {form.getFieldDecorator('end', {
                rules: [{ required: false }],
                initialValue: filter.end
              })(
                <DatePicker showTime format='YYYY-MM-DD HH:mm:ss' />
              )}
            </FormItem>
            <FormItem label='Caller'>
              {form.getFieldDecorator('caller', {
                rules: [{ required: false }],
                initialValue: filter.caller
              })(
                <Input placeholder='Caller' autoComplete='recorder-caller' autoFocus />
              )}
            </FormItem>
            <FormItem label='Called'>
              {form.getFieldDecorator('called', {
                rules: [{ required: false }],
                initialValue: filter.called
              })(
                <Input placeholder='Called' autoComplete='recorder-called' autoFocus />
              )}
            </FormItem>
            <FormItem label='Extension'>
              {form.getFieldDecorator('extension', {
                rules: [{ required: false }],
                initialValue: filter.extension
              })(
                <Input placeholder='Extension' autoComplete='recorder-extension' />
              )}
            </FormItem>
            <div className='form-group'>
              <FormItem label='Min Duration'>
                {form.getFieldDecorator('durationStart', {
                  rules: [{ required: false }],
                  initialValue: filter.durationStart
                })(
                  <InputNumber min={0} placeholder='Min' autoComplete='recorder-durationStart' />
                )}
              </FormItem>
              <FormItem label='Max Duration'>
                {form.getFieldDecorator('durationEnd', {
                  rules: [{ required: false }],
                  initialValue: filter.durationStart
                })(
                  <InputNumber min={0} placeholder='Max' autoComplete='recorder-durationEnd' />
                )}
              </FormItem>
            </div>
            <FormItem>
              <Button type='primary' htmlType='submit' className='login-form-button' block={true}>Search</Button>
            </FormItem>
        </Form>
      </div>
    )
  }
}

RecorderFilterForm.propTypes = {
  filter: filterType.isRequired,
  onSearchRecorder: PropTypes.func.isRequired
};

export default Form.create()(RecorderFilterForm)
