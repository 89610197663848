import React from 'react';
import { Drawer } from 'antd';
import CustomerCreateForm from './CustomerCreateForm';

const renderCreateForm = (principal, create, onCreate, onCancel) =>
  create && (
    <CustomerCreateForm
      principal={principal}
      create={create}
      onCreate={onCreate}
      onCancel={onCancel}
    />
  );

const CustomerCreate = ({ principal, create, onCreate, onCancel, width, title }) => (
  <Drawer title={title} width={width} onClose={onCancel} visible={create !== undefined}>
    {renderCreateForm(principal, create, onCreate, onCancel)}
  </Drawer>
);

export default CustomerCreate;
