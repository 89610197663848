export const SELECT = "recorder/select";
export const SYNC = "recorder/sync";
export const CLOSE = "recorder/close";
export const CLOSE_CREATE = "recorder/close-create";
export const OPEN_CREATE = "recorder/open-create";
export const SEARCH = "recorder/search";
export const DELETE = "recorder/delete";
export const UPDATE = "recorder/update";
export const CREATE = "recorder/create";
export const LOAD = "recorder/load";
export const ADD_ROLE = "recorder/add-role";
export const ADD_CUSTOMER = "recorder/add-customer";
export const SELECT_SUCCESS = "recorder/select-success";
export const SEARCH_SUCCESS = "recorder/search-success";
export const DELETE_SUCCESS = "recorder/delete-success";
export const UPDATE_SUCCESS = "recorder/update-success";
export const CREATE_SUCCESS = "recorder/create-success";
export const CLOSE_SUCCESS = "recorder/close-success";
export const LOAD_SUCCESS = "recorder/load-success";
export const ADD_CUSTOMER_SUCCESS = "recorder/add-customer-success";
export const ADD_ROLE_SUCCESS = "recorder/add-role-success";
export const SELECT_FAILED = "recorder/select-failed";
export const SEARCH_FAILED = "recorder/search-failed";
export const DELETE_FAILED = "recorder/delete-failed";
export const UPDATE_FAILED = "recorder/update-failed";
export const CREATE_FAILED = "recorder/create-failed";
export const LOADING = "recorder/loading";

export function loading() {
    return { type: LOADING }
}

export function sync(id) {
    return { type: SYNC, id }
}

export function load(id) {
    return { type: LOAD, id }
}

export function loadSuccess(url) {
    return { type: LOAD_SUCCESS, url }
}

export function close() {
    return { type: CLOSE }
}

export function closeCreate() {
    return { type: CLOSE_CREATE }
}

export function openCreate() {
    return { type: OPEN_CREATE }
}

export function closeSuccess() {
    return { type: CLOSE_SUCCESS }
}


export function removeSuccess(id) {
    return { type: DELETE_SUCCESS, id }
}

export function removeFailed() {
    return { type: DELETE_FAILED }
}

export function remove(id) {
    return { type: DELETE, id }
}

export function selectSuccess(selected) {
    return { type: SELECT_SUCCESS, selected }
}

export function selectFailed() {
    return { type: SELECT_FAILED }
}

export function select(payload) {
    return { type: SELECT, payload }
}

export function createSuccess(payload) {
    return { type: CREATE_SUCCESS, payload }
}

export function createFailed() {
    return { type: CREATE_FAILED }
}

export function create(payload) {
    return { type: CREATE, payload }
}

export function update(payload) {
    return { type: UPDATE, payload }
}

export function updateSuccess(payload) {
    return { type: UPDATE_SUCCESS, payload }
}

export function updateFailed() {
    return { type: UPDATE_FAILED }
}

export function search(filter) {
    return { type: SEARCH, filter }
}

export function searchSuccess(filter, pageable) {
    return { type: SEARCH_SUCCESS, filter, pageable }
}

export function searchFailed() {
    return { type: SEARCH_FAILED }
}

export function addRole(role) {
    return { type: ADD_ROLE, role }
}

export function addCustomer(customer) {
    return { type: ADD_CUSTOMER, customer }
}

export function addRoleSuccess(role) {
    return { type: ADD_ROLE_SUCCESS, role }
}

export function addCustomerSuccess(customer) {
    return { type: ADD_CUSTOMER_SUCCESS, customer }
}