import React, { Component } from 'react';
import CallbackForm from './CallbackForm';

class EditForm extends Component {
  handleSubmit = payload => {
    this.props.onUpdate({
      ...this.props.selected,
      ...payload
    });
  };
  render() {
    return (
      <CallbackForm
        principal={this.props.principal}
        bean={this.props.selected}
        onSubmit={this.handleSubmit}
        onClose={this.props.onClose}
        onDelete={this.props.onDelete}
      />
    );
  }
}

export default EditForm;
