import React, { Component, Fragment } from 'react';
import { Transfer } from 'antd';
import SecuredButton from '../shared/SecuredButton';

class UserRoles extends Component {
  constructor(props) {
    super();
    this.state = {
      targetKeys: props.selected.roles,
      selectedKeys: []
    };
  }

  roles = principal => {
    return principal.permissions
      .filter(p => p.permission.includes('role'))
      .map(p => {
        return {
          key: p.permission.replace('role/', ''),
          name: p.description
        };
      });
  };

  handleSubmit = e => {
    let payload = {
      ...this.props.selected,
      roles: this.getSelectedRoles()
    };
    this.props.onUpdateUser(payload);
  };

  getSelectedRoles = () => {
    return this.roles(this.props.principal)
      .filter(rMap => this.state.targetKeys.indexOf(rMap.key) > -1)
      .map(r => r.key);
  };

  handleChange = (nextTargetKeys, direction, moveKeys) => {
    this.setState({ targetKeys: nextTargetKeys });
  };

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({ selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys] });
  };

  filterOption = (inputValue, option) => {
    return option.name.indexOf(inputValue) > -1;
  };

  isOwnUser = () => {
    return this.props.selected.id === this.props.principal.id;
  };

  render() {
    return (
      <Fragment>
        <Transfer
          style={{ marginBottom: '20px' }}
          disabled={this.isOwnUser()}
          style={{ marginBottom: '20px' }}
          listStyle={{ height: 500, width: 700 }}
          showSearch
          filterOption={this.filterOption}
          dataSource={this.roles(this.props.principal)}
          titles={['Available', 'Selected']}
          targetKeys={this.state.targetKeys}
          selectedKeys={this.state.selectedKeys}
          onChange={this.handleChange}
          onSelectChange={this.handleSelectChange}
          render={item => item.name}
          rowKey={item => item.key}
        />

        <SecuredButton
          icon="cloud-upload"
          type="primary"
          title="Update Roles"
          permission="user/edit/roles"
          onClick={this.handleSubmit}
          principal={this.props.principal}
          disabled={this.props.loading}
        />
      </Fragment>
    );
  }
}

export default UserRoles;
