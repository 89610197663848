import React from 'react';
import RegisterForm from './RegisterForm';
import { Avatar } from "antd";
import "./register.css";

const Register = ({ onViewLogin, onRegister }) => (
  <div className="register-form-holder">
    <div className="register-avatar">
      <Avatar size="large" icon="lock"></Avatar>
      <h2>Register</h2>
    </div>
    <RegisterForm onRegister={onRegister} onViewLogin={onViewLogin} />
  </div>
);

export default Register;
