import React, { Component } from 'react';
import { Form, Input, Icon, Button, Select, Checkbox } from 'antd';

const FormItem = Form.Item;

class UserGeneralForm extends Component {
  constructor(props) {
    super();
    this.state = {
      guid: props.selected.apiKey ? props.selected.apiKey : this.guid()
    };
  }

  generateGuid = e => {
    e.preventDefault();
    this.setState({
      guid: this.guid()
    });
  };

  guid = () => {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  };

  handleSearch = value => {
    this.props.customers.filter(c => c.name.includes(value));
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onUpdateUser({
          ...this.props.selected,
          ...values
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const boltIcon = <Icon type="thunderbolt" theme="filled" />;
    const generateGuid = <button onClick={this.generateGuid}>{boltIcon}</button>;

    const options = this.props.customers.map(c => {
      return <Select.Option key={c.id}>{c.name}</Select.Option>;
    });

    return (
      <Form autoComplete="false" onSubmit={this.handleSubmit} layout="vertical">
        <FormItem label="Customer">
          {getFieldDecorator('customer.id', {
            rules: [{ required: true, message: 'Please input the customer' }],
            initialValue: this.props.selected.customer ? this.props.selected.customer.id : undefined
          })(
            <Select
              showSearch
              defaultActiveFirstOption={false}
              showArrow={false}
              onSearch={this.handleSearch}
              notFoundContent={null}
              placeholder="Select a customer"
              optionFilterProp="children"
              onChange={this.handleChange}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {options}
            </Select>
          )}
        </FormItem>

        <FormItem label="Username">
          {getFieldDecorator('username', {
            rules: [{ required: true, message: 'Please input the username' }],
            initialValue: this.props.selected.username
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Username"
              disabled
            />
          )}
        </FormItem>

        <FormItem label="Email">
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Please input the email' }],
            initialValue: this.props.selected.email
          })(
            <Input
              type="email"
              prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Email"
            />
          )}
        </FormItem>

        <FormItem label="Password">
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input the user password' }],
            initialValue: this.props.selected.password
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Password"
              type="password"
            />
          )}
        </FormItem>

        <FormItem label="Token">
          {getFieldDecorator('apiKey', {
            rules: [{ required: true, message: 'Please input the user token' }],
            initialValue: this.state.guid
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Token"
              addonAfter={generateGuid}
            />
          )}
        </FormItem>

        <FormItem label="CDR User">
          {getFieldDecorator('cdr', {
            valuePropName: 'checked',
            initialValue: this.props.selected.cdr
          })(<Checkbox />)}
        </FormItem>

        <FormItem>
          <Button disabled={this.props.loading} type="primary" htmlType="submit">
            Update
          </Button>
        </FormItem>
      </Form>
    );
  }
}

export default Form.create()(UserGeneralForm);
