import { put, takeEvery, call, select } from 'redux-saga/effects';
import { message } from 'antd';
import API from '../services/dashboard-service';
import { ActionConst } from '../actions/constants';
import * as actions from '../actions/dashboard-actions';

const qs = require('qs');

const getAPIKey = state => state.auth.principal.apiKey;

function* doDashboardSyncIVRErrors({ filter }) {
  yield put(actions.loading());
  const apiKey = yield select(getAPIKey);
  try {
    const query = yield qs.stringify({ duration: filter.duration, id: filter.id });
    const { data } = yield call(API.syncIVRErrors, apiKey, query);
    yield put(actions.syncIVRErrorsSuccess(data));
  } catch (e) {
    message.error(e);
  }
}

function* doDashboardFilter({ filter }) {
  yield put(actions.loading());
  yield put(actions.filterSuccess(filter));
}

function* doDashboardSyncCategory({ filter, opts }) {
  yield put(actions.loading(opts.dataKey));
  const apiKey = yield select(getAPIKey);
  try {
    const filterString = yield qs
      .stringify(filter.filters)
      .replace('&', ';')
      .replace('=', '|');

    const query = yield qs.stringify({
      duration: filter.duration,
      offset: filter.offset,
      aggType: filter.aggType,
      aggField: filter.aggField,
      filters: filter.filters !== undefined ? filterString : filter.filters,
      id: opts.id,
      keyword: opts.keyword,
      bucketSize: opts.bucketSize,
      type: opts.type
    });
    const { data } = yield call(API.syncCategory, apiKey, query);
    yield put(actions.syncCategorySuccess(data, opts.dataKey));
  } catch (e) {
    message.error(e);
  }
}

export default function* watchDashboard() {
  yield takeEvery(ActionConst.dashboard.syncIVRErrors, doDashboardSyncIVRErrors);
  yield takeEvery(ActionConst.dashboard.syncCategory, doDashboardSyncCategory);
  yield takeEvery(ActionConst.dashboard.filter, doDashboardFilter);
}
