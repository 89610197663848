import {
  VIVA_BASE_URL,
  STAT_IVR_ERROR_PATH,
  STAT_CATEGORY_PATH,
  DASHBOARD_PATH
} from '../Props.js';
import { get as _get } from 'lodash';
import axios from 'axios';

const jsonHeader = apiKey => {
  return {
    timeout: 15000,
    headers: {
      'X-VIVA-UserId': apiKey,
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  };
};

function get(apiKey, id, query) {
  return axios
    .get(VIVA_BASE_URL + DASHBOARD_PATH + id + '?' + query, jsonHeader(apiKey))
    .catch(e => error(e));
}

function syncIVRErrors(apiKey, query) {
  return axios
    .get(VIVA_BASE_URL + STAT_IVR_ERROR_PATH + '?' + query, jsonHeader(apiKey))
    .catch(e => error(e));
}

function syncCategory(apiKey, query) {
  return axios
    .get(VIVA_BASE_URL + STAT_CATEGORY_PATH + '?' + query, jsonHeader(apiKey))
    .catch(e => error(e));
}

function error(e) {
  let msg = _get(e.response, 'data.messages[0]');
  if (msg === undefined) msg = 'Sorry an error occurred.';
  return Promise.reject(msg);
}

export default { get, syncIVRErrors, syncCategory };
