export const ActionConst = {
  auth: {
    login: 'auth/login'
  },
  view: {
    register: 'view/register',
    forgotPassword: 'view/forgot-password',
    confirmRegister: 'view/confirm-register',
    login: 'view/login',
    logout: 'view/logout',
    dashboard: 'view/dashboard',
    customers: 'view/customers',
    users: 'view/users',
    recorder: 'view/recorder',
    success: 'view/success',
    error: 'view/error',
    complete: 'view/view-complete',
    start: 'view/view-start'
  },
  dashboard: {
    sync: 'dashboard/sync',
    syncCategory: 'dashboard/sync-category',
    syncCategorySuccess: 'dashboard/sync-category-success',
    syncSuccess: 'dashboard/sync-success',
    syncIVRErrors: 'dashboard/sync-ivr-errors',
    syncIVRErrorsSuccess: 'dashboard/sync-ivr-errors-success',
    filterSuccess: 'dashboard/filter-success',
    filter: 'dashboard/filter',
    loading: 'dashboard/loading'
  }
};
