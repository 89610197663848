import { connect } from "react-redux";
import Navigation from '../components/navigation';

const mapStateToProps = (state) => {
  return {
    principal: state.auth.principal,
    view: state.view.name
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleViewChange: (view) => dispatch({ type: view })
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
