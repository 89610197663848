import { connect } from 'react-redux';
import Ddi from '../components/customer/ddi/Ddi';
import * as actions from '../actions/ddi-actions';

const mapStateToProps = state => {
  return {
    principal: state.auth.principal,
    ddi: state.ddi,
    customer: state.customer.selected
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: {
      onCloseCreate: () => dispatch(actions.closeCreate()),
      onCloseEdit: () => dispatch(actions.closeEdit()),
      onOpenCreate: () => dispatch(actions.openCreate()),
      onSearch: filter => dispatch(actions.search(filter)),
      onSelect: ddi => dispatch(actions.select(ddi)),
      onDelete: id => dispatch(actions.remove(id)),
      onUpdate: payload => dispatch(actions.update(payload)),
      onCreate: payload => dispatch(actions.create(payload))
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Ddi);
