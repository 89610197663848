import { ActionConst } from '../actions/constants';
import routes from '../routes';

const initialState = {
  name: undefined
};

const viewReducer = (state = initialState, { type, value }) => {
  if (type === ActionConst.view.success) {
    let name = undefined;
    let title = undefined;
    switch (value) {
      case routes.dashboard:
        name = ActionConst.view.dashboard;
        title = 'Dashboard';
        break;
      case routes.customers:
        name = ActionConst.view.customers;
        title = 'Customers';
        break;
      case routes.users:
        name = ActionConst.view.users;
        title = 'Users';
        break;
      case routes.recorder:
        name = ActionConst.view.recorder;
        title = 'Recordings';
        break;
      default:
        name = value;
        title = '';
        break;
    }
    return {
      ...state,
      name,
      title
    };
  }
  return {
    ...state
  };
};

export default viewReducer;
