import React, { Component, Fragment } from 'react';
import { Card, Button, Icon, Timeline, Row, Col, Table, Tag } from 'antd';
import moment from 'moment';
import AudioPlayer from '../shared/AudioPlayer';

const BackButton = ({ onCloseRecorder }) => (
  <Button type="primary" onClick={onCloseRecorder}>
    <Icon type="left-circle" />
{' '}
Back
</Button>
);

const columns = [
  {
    title: '',
    dataIndex: 'key',
    key: 'key'
  },
  {
    title: '',
    dataIndex: 'value',
    key: 'value'
  }
];

class RecorderDetails extends Component {
  constructor() {
    super();
    this.state = {
      recorderList: [],
      recordData: null
    };
  }

  componentDidMount = () => {
    const { recorder } = this.props;
    const list = this.getLatestRecords();
    const data = [
      { key: 'From', value: recorder.from },
      { key: 'To', value: recorder.to },
      {
        key: 'Extensions',
        value: [...new Set(recorder.extensions)].map(ext => <Tag color="blue">{ext}</Tag>)
      },
      { key: 'Created', value: moment(recorder.created).calendar() },
      { key: 'Expires', value: moment(recorder.expires).calendar() },
      { key: 'Retention', value: recorder.retention },
      { key: 'PBX ID', value: recorder.pbxId }
    ];
    this.setState({
      recorderList: list,
      recordData: data
    });
  };

  getLatestRecords = () => {
    const { recorder } = this.props;
    let recorderList = [];
    let arrayLength = 10;
    if (recorder.downloads.length < 10) {
      arrayLength = recorder.downloads.length;
    }
    recorderList = recorder.downloads.slice(Math.max(recorder.downloads.length - arrayLength, 0));
    return recorderList;
  };

  render() {
    const { url, recorder, onCloseRecorder } = this.props;
    return (
      <Fragment>
        {url && (
          <Fragment>
            <Card
              className="recorder-details-card"
              extra={<BackButton onCloseRecorder={onCloseRecorder} />}
            >
              <AudioPlayer src={url} duration={recorder.duration} />
            </Card>
            <Card type="inner" className="recorder-downloads-card">
              <Row gutter={60}>
                <Col span={12}>
                  <Card title="Details">
                    <Table
                      showHeader={false}
                      pagination={false}
                      columns={columns}
                      dataSource={this.state.recordData}
                      rowKey="key"
                      size="small"
                    />
                  </Card>
                </Col>
                <Col span={12}>
                  <Card title="History">
                    <Timeline>
                      {this.state.recorderList.map(d => (
                        <Timeline.Item key={`${d.username}-${d.date}`}>

                          Accessed By
{' '}
{d.username}
{' '}
-
{' '}
{moment(d.date).calendar()}
                        </Timeline.Item>
                      ))}
                    </Timeline>
                  </Card>
                </Col>
              </Row>
            </Card>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default RecorderDetails;
