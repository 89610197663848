import React, { Fragment } from 'react';
import UserList from './UserList';
import UserDetails from './UserDetails';
import UserCreateForm from './UserCreateForm';
import UserToolbar from './UserToolbar';
import { Drawer } from 'antd';
import './user.css';

const User = ({ principal, user, actions, customers }) => (
  <Fragment>
    <UserToolbar
      principal={principal}
      loading={user.loading}
      filter={user.filter}
      selected={user.selected}
      onSearchUser={actions.onSearchUser}
      onOpenCreateUser={actions.onOpenCreateUser}
    />
    <UserList
      principal={principal}
      filter={user.filter}
      loading={user.loading}
      selected={user.selected}
      pageable={user.pageable}
      onSelectUser={actions.onSelectUser}
      onSearchUser={actions.onSearchUser}
    />
    <UserDetails
      principal={principal}
      loading={user.loading}
      selected={user.selected}
      customers={customers}
      actions={actions}
    />
    <Drawer
      title="Create new user"
      width={760}
      onClose={actions.onCloseCreate}
      visible={user.create !== undefined}
    >
      {user.create && (
        <UserCreateForm
          customers={customers}
          create={user.create}
          principal={principal}
          onCreateUser={actions.onCreateUser}
        />
      )}
    </Drawer>
  </Fragment>
);

export default User;
