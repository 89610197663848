import { ActionConst } from '../actions/constants';

const initialState = {
  filter: {
    duration: 'PT24H',
    offset: 'PT0S',
    aggType: 'sum',
    aggField: 'seconds',
    aggDivide: 60,
    filters: undefined
  }
};

const buildByDirection = (m, offset) => {
  return {
    inbound: {
      calls: m.inboundTotalCalls,
      minutes: m.inboundTotalMinutes,
      bands: m.inbound.bands.slice(0, 5),
      ddis: m.inbound.ddis.slice(0, 5),
      buckets: m.buckets.map(b => {
        return {
          calls: b.inboundTotalCalls,
          minutes: b.inboundTotalMinutes
        };
      })
    },
    outbound: {
      calls: m.outboundTotalCalls,
      minutes: m.outboundTotalMinutes,
      bands: m.outbound.bands.slice(0, 5),
      ddis: m.outbound.ddis.slice(0, 5),
      buckets: m.buckets.map(b => {
        return {
          calls: b.outboundTotalCalls,
          minutes: b.outboundTotalMinutes
        };
      })
    },
    total: {
      calls: m.totalCalls,
      minutes: m.totalMinutes,
      buckets: m.buckets.map(b => {
        return {
          calls: b.totalCalls,
          minutes: b.totalMinutes
        };
      })
    },
    buckets: m.buckets
      .map((b, i) => {
        return {
          bucket: `Day ${i + 1}`,
          totalCalls: b.totalCalls,
          totalMinutes: b.totalMinutes,
          outboundCalls: b.outboundTotalCalls,
          outboundMinutes: b.outboundTotalMinutes,
          inboundCalls: b.outboundTotalCalls,
          inboundMinutes: b.outboundTotalMinutes
        };
      })
      .sort((a, b) => (a.dom > b.dom ? 1 : b.dom > a.dom ? -1 : 0))
  };
};

const buildDiff = (now, then) => {
  return {
    inbound: {
      calls: ((now.inbound.calls - then.inbound.calls) / then.inbound.calls) * 100,
      minutes: ((now.inbound.minutes - then.inbound.minutes) / then.inbound.minutes) * 100
    },
    outbound: {
      calls: ((now.outbound.calls - then.outbound.calls) / then.outbound.calls) * 100,
      minutes: ((now.outbound.minutes - then.outbound.minutes) / then.outbound.minutes) * 100
    },
    total: {
      calls: ((now.total.calls - then.total.calls) / then.total.calls) * 100,
      minutes: ((now.total.minutes - then.total.minutes) / then.total.minutes) * 100
    }
  };
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionConst.dashboard.loading:
      return {
        ...state,
        loading: true
      };
    case ActionConst.dashboard.syncSuccess:
      const { metric1, metric2 } = action.payload;
      const now = buildByDirection(metric1.minutesByDirection, 0);
      const then = buildByDirection(metric2.minutesByDirection, 30);
      const diff = buildDiff(now, then);
      return {
        ...state,
        now,
        then,
        diff
      };
    case ActionConst.dashboard.syncIVRErrorsSuccess:
      return {
        ...state,
        ivrErrors: action.payload,
        loading: false
      };
    case ActionConst.dashboard.syncCategorySuccess:
      return {
        ...state,
        [action.key]: action.payload,
        [`loading_${action.key}`]: false
      };
    case ActionConst.dashboard.loading:
      return {
        ...state,
        [`loading_${action.key}`]: true
      };
    case ActionConst.dashboard.filterSuccess:
      const filter = action.filter;
      return {
        ...state,
        filter
      };
  }
  return {
    ...state
  };
};

export default dashboardReducer;
