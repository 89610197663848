import {
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  REGISTER_FAILED,
  RESET_EMAIL_FAILED
} from '../actions/auth-actions';

const initialState = {
  principal: undefined
};

const authReducer = (state = initialState, { type, principal }) => {
  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        principal
      };
    case LOGOUT_SUCCESS:
    case LOGIN_FAILED:
    case REGISTER_FAILED:
    case RESET_EMAIL_FAILED:
      return {
        ...state,
        principal: undefined
      };
    default:
      return {
        ...state
      };
  }
};

export default authReducer;
