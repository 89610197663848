import * as actions from "../actions/customer-actions";

const initialState = {
    filter: {
        name: "",
        ip: "",
        type: "",
        ddi: "",
        postalCode: "",
        page: 0,
        size: 15
    },
    create: undefined,
    selected: undefined,
    pageable: undefined,
    loading: false
};

const create = {
    name: "",
    mbn: "",
    type: "viva",
    address: {
        line1: "",
        line2: "",
        level1: "",
        level2: "",
        postalCode: "",
        country: ""
    }
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actions.SELECT_SUCCESS:
            return {
                ...state,
                selected: action.selected,
                loading: false
            }
        case actions.CREATE_SUCCESS:
            return {
                ...state,
                selected: action.payload,
                loading: false
            }
        case actions.CLOSE_SUCCESS:
            return {
                ...state,
                selected: undefined,
                loading: false
            }
        case actions.CLOSE_CREATE:
            return {
                ...state,
                filter: {},
                create: undefined,
                loading: false
            }
        case actions.OPEN_CREATE:
            return {
                ...state,
                create
            }
        case actions.SEARCH_SUCCESS:
            return {
                ...state,
                filter: action.filter,
                pageable: action.pageable,
                loading: false
            }
        case actions.LIST_SUCCESS:
            return {
                ...state,
                list: action.list.map(c=>{return {id: c.id, name: c.name, mbn: c.mbn }})
        }
        case actions.UPDATE_SUCCESS:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    ...action.payload,
                    filter: {},
                },
                loading: false
            }
        case actions.LOADING:
            return {
                ...state,
                loading: true
            }
        default:
            return {
                ...state
            }
    }


};

export default reducer;