import React, { Component, Fragment } from 'react';
import { Transfer } from 'antd';
import SecuredButton from '../shared/SecuredButton';

class UserCustomers extends Component {
  constructor(props) {
    super();
    this.state = {
      targetKeys: props.selected.customers.map(c => c.id),
      selectedKeys: []
    };
  }

  handleSubmit = e => {
    let customers = this.state.targetKeys.map(id => {
      return { id };
    });
    this.props.onUpdateUser({
      ...this.props.selected,
      customers
    });
  };

  handleChange = (nextTargetKeys, direction, moveKeys) => {
    this.setState({ targetKeys: nextTargetKeys });
  };

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({ selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys] });
  };

  filterOption = (inputValue, option) => {
    return option.name.indexOf(inputValue) > -1;
  };

  render() {
    return (
      <Fragment>
        <Transfer
          showSearch
          style={{ marginBottom: '20px' }}
          listStyle={{ height: 500, width: 700 }}
          titles={['Available', 'Selected']}
          filterOption={this.filterOption}
          dataSource={this.props.customers}
          targetKeys={this.state.targetKeys}
          selectedKeys={this.state.selectedKeys}
          onChange={this.handleChange}
          onSelectChange={this.handleSelectChange}
          render={item => item.name}
          rowKey={item => item.id}
        />

        <SecuredButton
          icon="cloud-upload"
          type="primary"
          title="Update Customers"
          permission="user/edit/customers"
          onClick={this.handleSubmit}
          principal={this.props.principal}
          disabled={this.props.loading}
        />
      </Fragment>
    );
  }
}

export default UserCustomers;
