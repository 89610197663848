import React, { Component } from "react";
import { Form } from "antd";
import PropTypes from "prop-types";

class FormItem extends Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
        property: PropTypes.string.isRequired,
        initialValue: PropTypes.any.isRequired,
        form: PropTypes.object.isRequired
    }
    render() {
        const {
            label,
            property,
            initialValue,
            form,
            children,
            isRequired = false,
            isVisible = true,
            message = "",
            valuePropName = "value",
        } = this.props;
        return (
            <span>
                {isVisible &&
                    <Form.Item label={label}>
                        {form.getFieldDecorator(property, {
                            rules: [{ required: isRequired, message }],
                            initialValue,
                            valuePropName
                        })(children)}
                    </Form.Item>
                }
            </span>
        )
    }
}

export default FormItem;