import React from 'react';
import { Row, Col } from 'antd';

const StandardToolbar = ({ left, right, spans = { left: 21, right: 3 } }) => (
  <Row className="toolbar">
    <Col span={spans.left}>{left}</Col>
    <Col span={spans.right} style={{ textAlign: 'right' }}>
      {right}
    </Col>
  </Row>
);

export default StandardToolbar;
