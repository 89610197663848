import React from 'react';
import SecuredButton from '../shared/SecuredButton';
import CustomerFilterForm from './CustomerFilterForm';
import StandardToolbar from '../shared/StandardToolbar';

const createButton = (principal, onOpenCreateCustomer) => (
  <SecuredButton
    icon="plus"
    type="primary"
    title="Create Customer"
    principal={principal}
    permission="customer/create"
    onClick={onOpenCreateCustomer}
  />
);

const filterForm = (principal, loading, filter, onSearchCustomer) => (
  <CustomerFilterForm
    principal={principal}
    loading={loading}
    filter={filter}
    onFilter={onSearchCustomer}
  />
);

const Toolbar = ({
  principal,
  loading,
  filter,
  selected,
  onSearchCustomer,
  onOpenCreateCustomer
}) => {
  if (!selected)
    return (
      <StandardToolbar
        left={filterForm(principal, loading, filter, onSearchCustomer)}
        right={createButton(principal, onOpenCreateCustomer)}
      />
    );
  else return '';
};

const CustomerToolbar = ({
  principal,
  loading,
  filter,
  selected,
  onSearchCustomer,
  onOpenCreateCustomer
}) => (
  <Toolbar
    principal={principal}
    loading={loading}
    filter={filter}
    selected={selected}
    onSearchCustomer={onSearchCustomer}
    onOpenCreateCustomer={onOpenCreateCustomer}
  />
);

export default CustomerToolbar;
