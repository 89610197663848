import { put, takeEvery, call, select } from 'redux-saga/effects';
import * as actions from '../actions/user-actions';
import API from '../services/user-service';
import { message } from 'antd';

const getAPIKey = state => state.auth.principal.apiKey;
const getUserFilter = state => state.user.filter;

function* doUserSelect({ id }) {
  yield put(actions.loading());
  const apiKey = yield select(getAPIKey);
  try {
    const { data } = yield call(API.get, id, apiKey);
    yield put(actions.selectSuccess(data));
  } catch (e) {
    message.error(e);
  }
}

function* doUserUpdate({ payload }) {
  yield put(actions.loading());
  const apiKey = yield select(getAPIKey);
  try {
    yield call(API.put, payload, payload.id, apiKey);
    yield put(actions.updateSuccess(payload));
  } catch (e) {
    message.error(e);
  }
}

function* doUserCreate({ payload }) {
  yield put(actions.loading());
  const apiKey = yield select(getAPIKey);
  try {
    const data = yield call(API.post, payload, apiKey);
    const id = yield data.headers.location.replace('user/', '');
    yield put(actions.select(id));
    yield put(actions.closeCreate());
  } catch (e) {
    message.error(e);
  }
}

function* doUserDelete({ id }) {
  yield put(actions.loading());
  const apiKey = yield select(getAPIKey);
  const userFilter = yield select(getUserFilter);
  try {
    yield call(API.remove, id, apiKey);
    yield put(actions.close());
    yield put(actions.search(userFilter));
  } catch (e) {
    message.error(e);
  }
}

function* doUserFilter({ filter }) {
  yield put(actions.loading());
  const apiKey = yield select(getAPIKey);
  try {
    const { data } = yield call(API.filter, filter, apiKey);
    yield put(actions.searchSuccess(filter, data));
  } catch (e) {
    message.error(e);
  }
}

function* doUserSync({ id }) {
  yield doUserSelect({ id });
}

function* doUserClose() {
  yield put(actions.loading());
  yield put(actions.closeSuccess());
}

export default function* watchUser() {
  yield takeEvery(actions.CLOSE, doUserClose);
  yield takeEvery(actions.SYNC, doUserSync);
  yield takeEvery(actions.DELETE, doUserDelete);
  yield takeEvery(actions.CREATE, doUserCreate);
  yield takeEvery(actions.SEARCH, doUserFilter);
  yield takeEvery(actions.SELECT, doUserSelect);
  yield takeEvery(actions.UPDATE, doUserUpdate);
}
