import React, { Fragment } from 'react';
import { Icon, Button } from 'antd';

const hasRole = (principal, permission) => {
  if (!permission) return true;
  if (!principal) return false;
  return principal.permissions.filter(p => p.permission === permission).length > 0;
};

const SecuredButton = ({
  onClick,
  title,
  icon,
  suffix,
  principal,
  permission,
  type = 'primary',
  disabled = false,
  htmlType = 'button'
}) => (
  <Fragment>
    {hasRole(principal, permission) && (
      <Button type={type} onClick={onClick} disabled={disabled} htmlType={htmlType}>
        {icon ? <Icon type={icon} /> : ''} {title} {suffix ? <Icon type={suffix} /> : ''}
      </Button>
    )}
  </Fragment>
);

export default SecuredButton;
