import React, { Component } from 'react';
import { Form, Button } from "antd";

class FilterForm extends Component {
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onFilter({
                    ...this.props.filter,
                    ...values
                });
            }
        });
    };
    render() {
        return (
            <Form
                onSubmit={this.handleSubmit}
                className="filter-form"
                layout="inline"
            >
                {this.props.children}
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="filter-submit-button">
                        Filter
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}

export default Form.create()(FilterForm);