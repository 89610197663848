import React from 'react';
import { Menu, Icon } from 'antd';
import { ActionConst } from '../../actions/constants';
import NavigationLogo from './NavigationLogo';
import './Navigation.css';

const menuItems = [
  {
    component: <Menu.Item key={ActionConst.view.dashboard}>Dashboard</Menu.Item>,
    permission: 'dashboard/view'
  },
  {
    component: <Menu.Item key={ActionConst.view.customers}>Customers</Menu.Item>,
    permission: 'customer/view'
  },
  {
    component: <Menu.Item key={ActionConst.view.users}>Users</Menu.Item>,
    permission: 'user/view'
  },
  {
    component: <Menu.Item key={ActionConst.view.recorder}>Recorder</Menu.Item>,
    permission: 'recorder/view'
  },
  {
    component: <Menu.Item key={ActionConst.view.login}>Login</Menu.Item>,
    permission: 'view/public'
  },
  {
    component: <Menu.Item key={ActionConst.view.register}>Register</Menu.Item>,
    permission: 'view/public'
  },
  {
    component: <Menu.Item key={ActionConst.view.logout}>Logout</Menu.Item>,
    permission: null
  }
];

const renderMenuItems = principal => {
  let permissions = principal ? principal.permissions.map(p => p.permission) : ['view/public'];
  return menuItems
    .filter(
      mi =>
        (principal && mi.permission == null) ||
        permissions.filter(p => mi.permission === p).length > 0
    )
    .map(mi => mi.component);
};

const Navigation = ({ principal, view, handleViewChange }) => (
  <div className="navigation">
    <div className="logo">
      <NavigationLogo />
    </div>
    <Menu
      className="nav"
      theme="dark"
      onClick={e => handleViewChange(e.key)}
      selectedKeys={[view]}
      mode="horizontal"
    >
      {renderMenuItems(principal)}
    </Menu>
  </div>
);

export default Navigation;
