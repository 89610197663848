import React from 'react';
import { Input, Form } from 'antd';
import FormItem from '../../shared/form/FormItem';
import FilterForm from '../../shared/form/FilterForm';

const CallbackFilterForm = ({ form, filter, onFilter }) => (
  <FilterForm filter={filter} onFilter={onFilter}>
    <FormItem
      label="Application"
      property="app"
      form={form}
      initialValue={filter.app}
      isRequired={false}
    >
      <Input placeholder="Application" autoComplete="filter-application" />
    </FormItem>
  </FilterForm>
);

export default Form.create()(CallbackFilterForm);
