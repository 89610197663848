import React from 'react';
import { Avatar } from 'antd';
import ResetPasswordForm from './ResetPasswordForm';
import './reset-password.css';

const ResetPassword = ({ onResetPassword, match }) => (
  <div className="reset-password-form-holder">
    <div className="reset-password-avatar">
      <Avatar size="large" icon="lock" />
      <h2>Reset Password</h2>
    </div>
    <ResetPasswordForm onResetPassword={onResetPassword} token={match.params.token} />
  </div>
);

export default ResetPassword;
