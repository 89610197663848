import { put, takeEvery, call } from 'redux-saga/effects';
import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  LOGOUT,
  CHECK_PRINCIPAL,
  REGISTER,
  REGISTER_SUCESS,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  RESET_EMAIL,
  RESET_EMAIL_SUCCESS
} from '../actions/auth-actions';
import { login, register, changePassword, resetEmail } from '../services/auth-service';
import { success } from '../actions/view-actions';
import * as customerActions from '../actions/customer-actions';

import { push } from 'react-router-redux';
import routes from '../routes';
import { message } from 'antd';

function hasPermission(principal, permission) {
  return principal.permissions.filter(p => p.permission === permission).length > 0;
}

// Manual successful login landing page based on user permissions
// with Dashboard being the highest precidence
// Probably a better way to do this.
// Such as router history redirect.
function determineLandingPage(principal) {
  if (hasPermission(principal, 'dashboard/view')) return routes.dashboard;
  if (hasPermission(principal, 'recorder/view')) return routes.recorder;
  if (hasPermission(principal, 'customer/view')) return routes.customers;
  if (hasPermission(principal, 'user/view')) return routes.users;
  //todo what if user has none of the above? What do we show them?
}

function* doLogin(payload) {
  try {
    const { data } = yield call(login, payload);
    const { apiKey, username, email, roles, permissions } = yield data;
    const principal = yield { apiKey, username, email, roles, permissions };
    yield localStorage.setItem('user', JSON.stringify(principal));
    yield put({ type: LOGIN_SUCCESS, principal });
    yield put(customerActions.search({}));
    yield put(customerActions.list());
    const route = yield determineLandingPage(principal);
    yield put(push(route));
    yield put(success(route));
  } catch (e) {
    message.error(e);
  }
}

function* doRegister({ payload }) {
  try {
    yield call(register, payload);
    yield put({ type: REGISTER_SUCESS });
    yield put(push(routes.login));
    yield message.info(
      'An email has been sent to ' + payload.email + ', please validate by clicking on the link'
    );
  } catch (e) {
    message.error(e);
  }
}

function* doChangePassword({ password, token }) {
  try {
    yield call(changePassword, { password, token });
    yield put({ type: CHANGE_PASSWORD_SUCCESS });
    yield put(push(routes.login));
    yield message.info('Password change successful, you can now login');
  } catch (e) {
    message.error(e);
  }
}

function* doResetEmail({ email }) {
  try {
    yield call(resetEmail, { email });
    yield put({ type: RESET_EMAIL_SUCCESS });
    yield put(push(routes.login));
    yield message.info('A reset email has been sent to ' + email);
  } catch (e) {
    message.error(e);
  }
}

function* doLogout() {
  yield localStorage.removeItem('user');
  yield put({ type: LOGOUT_SUCCESS });
  yield put(push(routes.public));
}

function* doCheckPrincipal({ redirect }) {
  const userString = yield localStorage.getItem('user');
  if (userString) yield put({ type: LOGIN_SUCCESS, principal: JSON.parse(userString) });
  else if (redirect) yield put(push(routes.login));
}

export default function* watchAuth() {
  yield takeEvery(LOGIN, doLogin);
  yield takeEvery(LOGOUT, doLogout);
  yield takeEvery(REGISTER, doRegister);
  yield takeEvery(CHECK_PRINCIPAL, doCheckPrincipal);
  yield takeEvery(CHANGE_PASSWORD, doChangePassword);
  yield takeEvery(RESET_EMAIL, doResetEmail);
}
