import { connect } from 'react-redux';
import { CHECK_PRINCIPAL } from '../actions/auth-actions';
import * as actions from '../actions/user-actions';
import User from '../components/user/User';

const mapStateToProps = state => {
  return {
    principal: state.auth.principal,
    customers: state.customer.list,
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: {
      onCheckPrincipal: redirect => dispatch({ type: CHECK_PRINCIPAL, redirect: redirect }),
      onCloseCreate: () => dispatch(actions.closeCreate()),
      onSelectUser: id => dispatch(actions.select(id)),
      onOpenCreateUser: () => dispatch(actions.openCreate()),
      onUpdateUser: payload => dispatch(actions.update(payload)),
      onCloseUser: () => dispatch(actions.close()),
      onDeleteUser: id => dispatch(actions.remove(id)),
      onSyncUser: id => dispatch(actions.sync(id)),
      onSearchUser: filter => dispatch(actions.search(filter)),
      onCreateUser: payload => dispatch(actions.create(payload))
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(User);
