import axios from 'axios';
import { VIVA_BASE_URL, REDIRECT_BASE_URL, LOGIN_PATH, REGISTER_PATH, CHANGE_PASSWORD_PATH, RESET_EMAIL_PATH } from '../Props';
import routes from "../routes"

var qs = require("qs");

const formUrlEncodedHeader = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Accept': 'application/json'
};

const jsonHeader = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Accept': 'application/json'
};

export function login(payload) {
    return axios.post(VIVA_BASE_URL + LOGIN_PATH, qs.stringify(payload), { formUrlEncodedHeader })
        .catch(error => {
            return Promise.reject("Cannot Login");
        });
}

export function register(payload) {
    payload.html = registerEmailBody(payload.email);
    return axios.post(VIVA_BASE_URL + REGISTER_PATH, payload, { jsonHeader })
        .catch(error => {
            let msg = error.response.data && error.response.data.messages ?
                error.response.data.messages[0] :
                "Sorry an error occurred.";
            return Promise.reject(msg);
        });
}

export function changePassword(payload) {
    return axios.post(VIVA_BASE_URL + CHANGE_PASSWORD_PATH, qs.stringify(payload), { formUrlEncodedHeader })
        .catch(error => {
            let msg = error.response.status === 404 ?
                "Sorry, token invalid." :
                "Sorry an error occurred.";
            return Promise.reject(msg);
        });
}

export function resetEmail(payload) {
    payload.html = resetEmailBody(payload.email);
    return axios.post(VIVA_BASE_URL + RESET_EMAIL_PATH, qs.stringify(payload), { formUrlEncodedHeader })
        .catch(error => {
            return Promise.reject("Failed");
        });
}

function registerEmailBody(email) {
    return `
        <div>
            Somebody (hopefully you) registered for a new VIVA account for </b>${email}</b>
            <br/><br/>
            To continue, you need to confirm a new password by clicking the link below:
            <br/><br/> 
            <a href="${REDIRECT_BASE_URL + routes.resetPassword}">Click here</a>
            <br/><br/
            The link will expire in 7 days, after which, you will have to re-register
            <br/><br/>
            We are here to help you so please email support@vibecoms.co.uk if you need any help.
            <br/><br/>
            Kind Regards,<br/>
            <b>The VIVA Team<b/>
        </div>`;
}

function resetEmailBody(email) {
    return `
        <div>
                Somebody (hopefully you) requested a new password for the VIVA account for <b>${email}</b>. No changes have been made to your account yet.
                <br/><br/>
                You can reset your password by clicking the link below:
                <br/><br/>
                <a href="${REDIRECT_BASE_URL + routes.resetPassword}">Click here</a>
                <br/><br/>
                Your reset token will expire in 4 Hours or invalidated if a new token is generated.
                <br/><br/>
                We are here to help you so please email support@vibecoms.co.uk if you need any help.
                <br/><br/>
                If you did not request a new password, please let us know immediately sending an email to support@vibecoms.co.uk.
                <br/><br/>
                Kind Regards,<br/>
                <b>The VIVA Team<b/>"
        </div>`;
}


