import React, { Component } from 'react';
import { Form, Input, Icon, Button, Select, Checkbox } from 'antd';

const FormItem = Form.Item;

class UserGeneralForm extends Component {
  constructor(props) {
    super();
    this.state = {
      guid: this.guid()
    };
  }

  generateGuid = e => {
    e.preventDefault();
    this.setState({
      guid: this.guid()
    });
  };

  guid = () => {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  };

  getRolesFromUserType = profile => {
    switch (profile) {
      case 'customer':
        return ['ROLE_CUSTOMER'];
      case 'reseller':
        return ['ROLE_RESELLER'];
      case 'recorder':
        return ['ROLE_RECORDER'];
      case 'pbx':
        return [
          'ROLE_VIEW_CUSTOMER',
          'ROLE_VIEW_CUSTOMER_TRUNK',
          'ROLE_CREATE_CUSTOMER_TRUNK',
          'ROLE_EDIT_CUSTOMER_TRUNK',
          'ROLE_DELETE_CUSTOMER_TRUNK',
          'ROLE_VIEW_CUSTOMER_DDI'
        ];
      case 'integrate':
        return [
          'ROLE_VIEW_CUSTOMER',
          'ROLE_VIEW_CUSTOMER_CALLBACK',
          'ROLE_CREATE_CUSTOMER_CALLBACK',
          'ROLE_EDIT_CUSTOMER_CALLBACK',
          'ROLE_DELETE_CUSTOMER_CALLBACK',
          'ROLE_VIEW_CUSTOMER_DDI'
        ];
      case 'number':
        return [
          'ROLE_VIEW_CUSTOMER',
          'ROLE_VIEW_CUSTOMER_DDI',
          'ROLE_CREATE_CUSTOMER_DDI',
          'ROLE_EDIT_CUSTOMER_DDI',
          'ROLE_DELETE_CUSTOMER_DDI'
        ];
      case 'cdr':
        return ['ROLE_CDR'];
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let payload = {
          ...this.props.create,
          ...values,
          username: values.email,
          apiKey: this.state.guid,
          password: this.state.guid,
          roles: this.getRolesFromUserType(values.profile)
        };
        this.props.onCreateUser(payload);
      }
    });
  };

  handleSearch = value => {
    this.props.customers.filter(c => c.name.includes(value));
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const boltIcon = <Icon type="thunderbolt" theme="filled" />;
    const generateGuid = <button onClick={this.generateGuid}>{boltIcon}</button>;
    const options = this.props.customers.map(c => {
      return <Select.Option key={c.id}>{c.name}</Select.Option>;
    });

    return (
      <Form onSubmit={this.handleSubmit} layout="vertical">
        <FormItem label="Customer">
          {getFieldDecorator('customer.id', {
            rules: [{ required: true, message: 'Please input the customer' }],
            initialValue: this.props.create.customer ? this.props.create.customer.id : undefined
          })(
            <Select
              showSearch
              defaultActiveFirstOption={false}
              showArrow={true}
              filterOption={false}
              onSearch={this.handleSearch}
              notFoundContent={null}
              placeholder="Select a customer"
              optionFilterProp="children"
              onChange={this.handleChange}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {options}
            </Select>
          )}
        </FormItem>

        <FormItem label="User Profile">
          {getFieldDecorator('profile', {
            rules: [{ required: true, message: 'Please input the user profile' }],
            initialValue: 'customer'
          })(
            <Select
              defaultActiveFirstOption={false}
              showArrow={true}
              filterOption={false}
              notFoundContent={null}
              placeholder="Select a user type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Select.Option value="customer">Customer Admin</Select.Option>
              <Select.Option value="reseller">Mulitple Customers Admin</Select.Option>
              <Select.Option value="recorder">Recording Listener</Select.Option>
              <Select.Option value="dashboard">Dasboard Only</Select.Option>
              <Select.Option value="pbx">PBX Maintainer</Select.Option>
              <Select.Option value="integrate">3rd Party Integrator</Select.Option>
              <Select.Option value="number">Number Manager</Select.Option>
              <Select.Option value="cdr">CDR User</Select.Option>
            </Select>
          )}
        </FormItem>

        {/* <FormItem label="Username">
          {getFieldDecorator('username', {
            rules: [{ required: true, message: 'Please input the username' }],
            initialValue: ''
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Username"
            />
          )}
        </FormItem> */}

        <FormItem label="Email Address">
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Please input the email' }],
            initialValue: ''
          })(
            <Input
              type="email"
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Email"
            />
          )}
        </FormItem>

        {/* <FormItem label="Password">
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input the user password' }],
            initialValue: this.props.create.password
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Password"
              type="password"
            />
          )}
        </FormItem> */}

        {/* <FormItem label="Token">
          {getFieldDecorator('apiKey', {
            rules: [{ required: true, message: 'Please input the user token' }],
            initialValue: this.state.guid
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Token"
              addonAfter={generateGuid}
            />
          )}
        </FormItem> */}

        {/* <FormItem label="Recording User">
          {getFieldDecorator('recorder', {
            valuePropName: 'checked',
            initialValue: this.props.create.recorder
          })(<Checkbox />)}
        </FormItem>

        <FormItem label="CDR User">
          {getFieldDecorator('cdr', {
            valuePropName: 'checked',
            initialValue: this.props.create.cdr
          })(<Checkbox />)}
        </FormItem> */}

        <FormItem style={{ textAlign: 'right' }}>
          <Button.Group>
            <Button type="dashed" onClick={this.props.onCloseCreate} title="cancel">
              <Icon type="rollback" />
            </Button>

            <Button disabled={this.props.loading} type="primary" htmlType="submit" title="create">
              <Icon type="cloud-upload" /> Create
            </Button>
          </Button.Group>
        </FormItem>
      </Form>
    );
  }
}

export default Form.create()(UserGeneralForm);
