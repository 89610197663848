import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Table, Pagination, Card, Row, Col, Icon, Button, Tag } from 'antd';
import moment from 'moment';
import { pageableListType, filterType } from '../../types';

class RecorderList extends Component {
  constructor() {
    super();
    this.state = {
      columns: null
    }
  }

  pageHandle = (page) => {
    const { filter, onSearchRecorder } = this.props
    let f = {
      ...filter,
      page: page - 1
    };
    onSearchRecorder(f);
  }

  onSelectChange = (record) => {
    const { onSelectRecorder } = this.props;
    onSelectRecorder(record);
  }

  componentDidMount = () => {
    const { filter, onSearchRecorder } = this.props;
    onSearchRecorder(filter);

    const columns = [
      {
        title: 'Customer',
        dataIndex: 'customer.name',
        key: 'customer.name',
      },
      {
        title: 'From',
        dataIndex: 'from',
        key: 'from',
      },
      {
        title: 'To',
        dataIndex: 'to',
        key: 'to'
      },
      {
        title: 'PBX ID',
        dataIndex: 'pbxId',
        key: 'pbxId'
      },
      {
        title: 'Duration',
        dataIndex: 'duration',
        key: 'duration',
        render: d => d.toFixed(0)
      },
      {
        title: 'Extension(s)',
        dataIndex: 'extensions',
        key: 'extensions',
        render: tags => (
          <span>
            {tags.filter(t => t.trim() !== 'cpaq' && t.trim() !== 'sherlock' && t.trim() !== 'magenta').filter(t => t.trim() !== '').map(tag => <Tag color='blue' key={tag}>{tag}</Tag>)}
          </span>
        )
      },
      {
        title: 'Recorded',
        dataIndex: 'created',
        key: 'created',
        render: field => (
          <span>
            {moment(field).calendar()}
          </span>
        )
      },
      {
        title: 'Expires',
        dataIndex: 'expires',
        key: 'expires',
        render: field => (
          <span>
            {moment(field).calendar()}
          </span>
        )
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: (text, record) => (
          <Button title='Play' type='primary' onClick={() => this.onSelectChange(record)}>
            More <Icon type='more' />
          </Button>
        )
      }
    ];

    this.setState({
      columns: columns
    });
  }

  render() {
    const { loading, pageable } = this.props;
    return (
      <div className='recorder-list-container'>
        <Card type='inner'>
          {this.props.pageable &&
            <Fragment>
              <Row gutter={3} >
                <Col span={24}>
                  <Table
                    rowKey='id'
                    size='small'
                    loading={loading}
                    pagination={false}
                    dataSource={pageable.content}
                    columns={this.state.columns}
                  />
                </Col>
              </Row>
              <Row gutter={3} style={{ marginTop: '10px' }} >
                <Col offset={18} span={6} style={{ textAlign: 'right' }}>
                  <Pagination
                    size='small'
                    defaultCurrent={pageable.number + 1}
                    page={pageable.number + 1}
                    total={pageable.totalElements}
                    pageSize={pageable.size}
                    onChange={this.pageHandle}
                  />
                </Col>
              </Row>
            </Fragment>
          }
        </Card>
      </div>
    )
  }
}

RecorderList.propTypes = {
  pageable: pageableListType.isRequired,
  filter: filterType.isRequired,
  loading: PropTypes.bool.isRequired,
  onSearchRecorder: PropTypes.func.isRequired
};

export default RecorderList;
