import React, { Component } from 'react';
import { Form } from 'antd';
import DdiForm from './DdiForm';
import PropTypes from 'prop-types';

class DdiEditForm extends Component {
  static propTypes = {
    customer: PropTypes.object.isRequired,
    selected: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
  };
  handleSubmit = payload => {
    this.props.onUpdate({
      ...this.props.selected,
      ...payload
    });
  };
  render() {
    return (
      <DdiForm
        principal={this.props.principal}
        customer={this.props.customer}
        bean={this.props.selected}
        onSubmit={this.handleSubmit}
        onClose={this.props.onClose}
        onDelete={this.props.onDelete}
      />
    );
  }
}

export default DdiEditForm;
