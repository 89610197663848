import React, { Component } from 'react';
import TrunkForm from './TrunkForm';
import PropTypes from 'prop-types';

class TrunkCreateForm extends Component {
  static propTypes = {
    selected: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
  };
  handleSubmit = payload => {
    this.props.onUpdate({
      ...this.props.selected,
      ...payload
    });
  };
  render() {
    return (
      <TrunkForm
        principal={this.props.principal}
        bean={this.props.selected}
        onSubmit={this.handleSubmit}
        onClose={this.props.onClose}
        onDelete={this.props.onDelete}
      />
    );
  }
}

export default TrunkCreateForm;
