import React, { Fragment } from 'react';
import DeviceCreateForm from './DeviceCreateForm';
import DeviceEditForm from './DeviceEditForm';
import DeviceFilterForm from './DeviceFilterForm';
import StandardList from '../../shared/StandardList';
import StandardToolbar from '../../shared/StandardToolbar';
import SecuredButton from '../../shared/SecuredButton';
import { Drawer } from 'antd';
import './device.css';

const columns = [
  { title: 'ID', dataIndex: 'id', key: 'id' },
  { title: 'Username', dataIndex: 'username', key: 'username' },
  { title: 'Extension', dataIndex: 'extension', key: 'extension' }
];

const Device = ({ actions, device, customer, principal }) => (
  <Fragment>
    <StandardToolbar
      left={<DeviceFilterForm filter={device.filter} onFilter={actions.onSearch} />}
      right={
        <SecuredButton
          icon="plus"
          principal={principal}
          title="Create Device"
          onClick={actions.onOpenCreate}
          permission="customer/create/device"
        />
      }
    />

    <StandardList
      {...actions}
      {...device}
      columns={columns}
      principal={principal}
      permission="customer/edit/device"
    />
    <Drawer
      width={860}
      title="Create new device"
      onClose={actions.onCloseCreate}
      visible={device.showCreate}
    >
      <DeviceCreateForm
        principal={principal}
        onCreate={actions.onCreate}
        onClose={actions.onCloseCreate}
        loading={device.loading}
        customer={customer}
        create={device.create}
      />
    </Drawer>
    <Drawer title="Edit device" width={860} onClose={actions.onCloseEdit} visible={device.showEdit}>
      {device.showEdit && (
        <DeviceEditForm
          principal={principal}
          loading={device.loading}
          onUpdate={actions.onUpdate}
          onDelete={actions.onDelete}
          onClose={actions.onCloseEdit}
          customer={customer}
          selected={device.selected}
        />
      )}
    </Drawer>
  </Fragment>
);

export default Device;
