import React, { Component } from 'react';
import { Form, Select } from 'antd';
import { get as _get } from 'lodash';
import PropTypes from 'prop-types';
import { filterType } from '../../types';

class DashboardFilter extends Component {
  onTimeSpanChange = duration => {
    const { filter, onFilter } = this.props;
    onFilter({
      ...filter,
      duration
    });
  };

  onAggTypeChange = aggTypeString => {
    const { filter, onFilter } = this.props;
    onFilter({
      ...filter,
      aggField: aggTypeString.split('|')[0],
      aggType: aggTypeString.split('|')[1],
      aggDivide: parseInt(aggTypeString.split('|')[2])
    });
  };

  onDirectionChange = direction => {
    const { filter, onFilter } = this.props;
    const filters = filter.filters === undefined ? {} : filter.filters;
    filters.direction = direction;
    onFilter({
      ...filter,
      filters
    });
  };

  render() {
    const { filter, principal } = this.props;
    const { aggField, aggType, aggDivide } = filter;
    const aggValue = `${aggField}|${aggType}|${aggDivide}`;
    return (
      <Form layout="inline">
        <Form.Item label="Time Span">
          <Select
            style={{ width: '100px' }}
            value={_get(this.props, 'filter.duration')}
            onChange={this.onTimeSpanChange}
          >
            <Select.Option value="P30D">30 Days</Select.Option>
            <Select.Option value="P14D">14 Days</Select.Option>
            <Select.Option value="P7D">7 Days</Select.Option>
            <Select.Option value="PT24H">24 Hours</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Direction">
          <Select
            allowClear
            style={{ width: '130px' }}
            value={_get(this.props, 'filter.filters.direction')}
            onChange={this.onDirectionChange}
          >
            <Select.Option value=" " />
            <Select.Option value="inbound">Inbound</Select.Option>
            <Select.Option value="outbound">Outbound</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Aggregation">
          <Select style={{ width: '180px' }} value={aggValue} onChange={this.onAggTypeChange}>
            <Select.Option value="seconds|sum|60">Duration</Select.Option>
            <Select.Option value="seconds|count|1">Calls</Select.Option>
            {principal.roles.includes('ROLE_ADMIN') && (
              <Select.Option value="charge|sum|1">Total Cost</Select.Option>
            )}
            {principal.roles.includes('ROLE_ADMIN') && (
              <Select.Option value="compute|sum|100">Compute Cost</Select.Option>
            )}
            {principal.roles.includes('ROLE_ADMIN') && (
              <Select.Option value="carrier|sum|100">Carrier Cost</Select.Option>
            )}
          </Select>
        </Form.Item>
      </Form>
    );
  }
}

DashboardFilter.propTypes = {
  // id: PropTypes.string.isRequired,
  filter: filterType.isRequired,
  onFilter: PropTypes.func.isRequired
};

export default DashboardFilter;
