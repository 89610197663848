import React, { Component } from 'react';
import PropTypes from 'prop-types';
import WaveSurfer from 'wavesurfer.js';
import TimelinePlugin from 'wavesurfer.js/src/plugin/timeline.js';
import { Button, Icon } from 'antd';
import './AudioPlayer.css';

const PlayButton = ({handleClick}) => (
  <Button title='Play' type='primary' onClick={handleClick}>
    <Icon type='play-circle' /> Play
  </Button>
)

const PauseButton = ({handleClick}) => (
  <Button title='Play' type='primary' onClick={handleClick}>
    <Icon type='pause-circle' /> Pause
  </Button>
)

class AudioPlayer extends Component {
  constructor() {
    super();
    this.state = {
      isPlaying: false,
    }
    this.wavesurfer = null;
  }

  getDuration = () => {
    const { duration } = this.props;
    let timeInterval = 0.5;
    if (duration > 10) {
      timeInterval = 1
    }
    console.log('timeInterval', timeInterval)
    return timeInterval
  }

  componentDidMount() {
    const { src } = this.props;
    this.wavesurfer = WaveSurfer.create({
      container: '#waveform',
      responsive: true,
      waveColor: '#ddd',
      cursorColor: '#cecece',
      barHeight: 400,
      height: 200,
      cursorWidth: 2,
      mediaControls: true,
      plugins: [
        TimelinePlugin.create({
          container: '#waveform-timeline',
          timeInterval: this.getDuration(),
          primaryLabelInterval: 5,
          secondaryLabelInterval: 5
        })
      ]
    });
    this.wavesurfer.load(src);
    this.wavesurfer.on('finish', () => {
      this.resetAudio();
      this.wavesurfer.stop();
    });
  }

  componentWillUnmount() {
    this.resetAudio();
    this.wavesurfer.stop();
    this.wavesurfer = null;
  }

  handleDownload = () => {
    const { src } = this.props;
    const link = document.createElement('a');
    link.href = src;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  resetAudio = () => {
    this.setState({
      isPlaying: false
    });
  }

  playAudio = () => {
    if (!this.state.isPlaying) {
      this.setState({
        isPlaying: true
      })
      this.wavesurfer.play();
      return;
    }

    this.setState({
      isPlaying: false
    });
    this.wavesurfer.pause();
  }

  render() {
    return (
      <div className='player-container'>
        <div id='waveform'></div>
        <div id='waveform-timeline'></div>
        <Button.Group>
          {
            this.state.isPlaying
                ? <PauseButton handleClick={this.playAudio} />
                : <PlayButton handleClick={this.playAudio} />
          }
          <Button title='Download' type='primary' onClick={this.handleDownload}>
            <Icon type='cloud-download' /> Download
          </Button>
        </Button.Group>
      </div>
    )
  }
}

AudioPlayer.propTypes = {
  duration: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired
};

export default AudioPlayer;
