import React, { Fragment } from 'react';
import UserTabs from './UserTabs';
import UserMenu from './UserMenu';
import { PageHeader } from 'antd';

const renderMenu = (principal, selected, actions) => {
  return <UserMenu principal={principal} selected={selected} actions={actions} />;
};

const renderPageHeader = (principal, selected, loading, actions) => {
  if (selected)
    return (
      <PageHeader
        principal={principal}
        loading={loading}
        title={selected.username}
        subTitle={selected.email}
        extra={renderMenu(principal, selected, actions)}
        onBack={actions.onCloseUser}
      />
    );
  return '';
};

const renderTabs = (principal, selected, loading, actions, customers) => {
  if (selected)
    return (
      <UserTabs
        selected={selected}
        principal={principal}
        loading={loading}
        actions={actions}
        customers={customers}
      />
    );
  return '';
};

const UserDetails = ({ principal, loading, selected, actions, customers }) => (
  <Fragment>
    {renderPageHeader(principal, selected, loading, actions)}
    {renderTabs(principal, selected, loading, actions, customers)}
  </Fragment>
);

export default UserDetails;
