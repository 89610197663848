import { DEVICE_PATH } from "../Props.js";
import API from "./api-service";


const jsonHeader = (apiKey) => {
    return {
        timeout: 5000,
        headers: {
            "X-VIVA-Client-ID": apiKey,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }
}

//Devices are already present in customer payload 
//so filter locally
function filter(filter, customer) {

    if (customer.devices === undefined || customer.devices === null) {
        let result = {
            number: filter.page,
            size: filter.size,
            totalElements: 0,
            content: []
        };
        return { data: result };
    };

    let filteredContent = customer.devices.filter(d => {
        return d.username.includes(filter.username);
    });
    let pagContent = filteredContent.slice(filter.page * filter.size, (filter.page + 1) * filter.size);
    let result = {
        number: filter.page,
        size: filter.size,
        totalElements: filteredContent.length,
        content: pagContent
    };
    return { data: result };
}

//Devices are already present in customer payload 
//so simply return device
function get(data) {
    return {
        data
    };
}

function put(device, customer) {
    return API.put(device.id, device, DEVICE_PATH, jsonHeader(customer.clientID));
}

function remove(id, customer) {
    return API.remove(id, DEVICE_PATH, jsonHeader(customer.clientID));
}

function post(device, customer) {
    return API.post(device, DEVICE_PATH, jsonHeader(customer.clientID));
}

export default {
    get, filter, put, post, remove
}


