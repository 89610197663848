import { connect } from 'react-redux';
import Dashboard from '../components/dashboard/Dashboard';
import * as actions from '../actions/dashboard-actions';

const mapStateToProps = state => {
  return {
    principal: state.auth.principal,
    dashboard: state.dashboard
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: {
      onFilter: filter => dispatch(actions.filter(filter)),
      onSync: filter => dispatch(actions.sync(filter)),
      onSyncIVRErrors: filter => dispatch(actions.syncIVRErrors(filter)),
      onSyncCategory: (filter, opts) => dispatch(actions.syncCategory(filter, opts))
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
