import * as actions from '../actions/user-actions';

const initialState = {
  filter: {
    username: '',
    email: '',
    page: 0,
    size: 15
  },
  create: undefined,
  selected: undefined,
  pageable: undefined,
  loading: false,
  roles: []
};

const create = {
  username: '',
  email: '',
  password: '',
  roles: ['ROLE_USER'],
  customers: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SELECT_SUCCESS:
      return {
        ...state,
        selected: action.selected,
        loading: false
      };
    case actions.CREATE_SUCCESS:
      return {
        ...state,
        selected: action.payload,
        loading: false
      };
    case actions.CLOSE_SUCCESS:
      return {
        ...state,
        selected: undefined,
        loading: false
      };
    case actions.CLOSE_CREATE:
      return {
        ...state,
        create: undefined,
        loading: false
      };
    case actions.OPEN_CREATE:
      return {
        ...state,
        create
      };
    case actions.SEARCH_SUCCESS:
      return {
        ...state,
        filter: action.filter,
        pageable: action.pageable,
        loading: false
      };
    case actions.UPDATE_SUCCESS:
      return {
        ...state,
        selected: {
          ...state.selected,
          ...action.payload
        },
        loading: false
      };
    case actions.LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return {
        ...state
      };
  }
};

export default reducer;
