import React, { Component } from 'react';
import { Form, Input, Button } from 'antd';

const FormItem = Form.Item;

class CustomerFilterForm extends Component {
  constructor(props) {
    super(props);
    props.onFilter(this.props.filter);
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const f = {
          ...this.props.filter,
          name: values.name,
          ip: values.ip,
          ddi: values.ddi,
          type: values.type
        };
        this.props.onFilter(f);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.handleSubmit} className="customer-filter-form" layout="inline">
        <FormItem label="Name">
          {getFieldDecorator('name', {
            rules: [{ required: false }],
            initialValue: this.props.filter.name
          })(
            <Input
              placeholder="Customer Name"
              autoComplete="customer-name"
              autoFocus
              onKeyUp={this.handleSubmit}
            />
          )}
        </FormItem>
        <FormItem label="IP Address">
          {getFieldDecorator('ip', {
            rules: [{ required: false }],
            initialValue: this.props.filter.ip
          })(
            <Input
              placeholder="IP Address"
              autoComplete="customer-ip"
              onKeyUp={this.handleSubmit}
            />
          )}
        </FormItem>
        <FormItem label="DDI">
          {getFieldDecorator('ddi', {
            rules: [{ required: false }],
            initialValue: this.props.filter.ddi
          })(
            <Input
              placeholder="Telephone Number"
              autoComplete="customer-ddi"
              onKeyUp={this.handleSubmit}
            />
          )}
        </FormItem>
        <FormItem label="Type">
          {getFieldDecorator('type', {
            rules: [{ required: false }],
            initialValue: this.props.filter.type
          })(
            <Input
              placeholder="Customer Type"
              autoComplete="customer-type"
              onKeyUp={this.handleSubmit}
            />
          )}
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit" className="login-form-button" block>

            Search
</Button>
        </FormItem>
      </Form>
    );
  }
}

export default Form.create()(CustomerFilterForm);
