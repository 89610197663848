import React, { Component } from 'react';
import { Form, Input, Button } from "antd";
import FormItem from "../../shared/form/FormItem";
import FilterForm from "../../shared/form/FilterForm";

class SettingFilterForm extends Component {
    render() {
        return (
            <FilterForm
                form={this.props.form}
                filter={this.props.filter}
                onFilter={this.props.onFilter} >
                <FormItem
                    label="Key"
                    property="key"
                    form={this.props.form}
                    initialValue={this.props.filter.key}
                    isRequired={false}
                >
                    <Input placeholder="Key" autoComplete="filter-key" />
                </FormItem >
            </FilterForm >
        )
    }
}

export default Form.create()(SettingFilterForm);