import React, { Component } from "react";
import { Select } from "antd";
import FormItem from "../../../shared/form/FormItem";

class ApplicationSelectFormItem extends Component {
    render() {
        return (
            <FormItem
                label="Application"
                property="app"
                isRequired={true}
                form={this.props.form}
                initialValue={this.props.initialValue}
                message="Please input the callback application"
            >

                <Select
                    onChange={this.props.onChange}
                >
                    <Select.OptGroup label="Events">
                        <Select.Option value="event.call">Telephony Events</Select.Option>
                        <Select.Option value="event.stat">Statistic Events</Select.Option>
                        <Select.Option value="event.recording">Recording Events</Select.Option>
                        <Select.Option value="event.summary">Call Summary Events</Select.Option>
                        <Select.Option value="ViVa-TAPI">Legacy TAPI Events</Select.Option>
                    </Select.OptGroup>
                    <Select.OptGroup label="IVR">
                        <Select.Option value="ivr.auriga">Auriga</Select.Option>
                        <Select.Option value="ivr.cordic">Cordic</Select.Option>
                        <Select.Option value="ViVa-D2C">Magenta</Select.Option>
                        <Select.Option value="ivr.mti">MTI</Select.Option>
                        <Select.Option value="ivr.sherlock">Sherlock</Select.Option>
                        <Select.Option value="ivr.generic">Generic</Select.Option>
                    </Select.OptGroup>
                    <Select.OptGroup label="Other">
                        <Select.Option value="pbx">PBX</Select.Option>
                    </Select.OptGroup>
                </Select>

            </FormItem>
        )
    }
}
export default ApplicationSelectFormItem;