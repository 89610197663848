import { connect } from "react-redux";
import Register from '../components/register/Register';
import { REGISTER } from '../actions/auth-actions';
import { ActionConst } from '../actions/constants';

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
      onRegister: (values) => dispatch({ type: REGISTER, payload: values }),
      onViewLogin: () => dispatch({ type: ActionConst.view.login })
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
