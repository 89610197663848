import React, { Component } from 'react';
import { Spin } from 'antd';
import {
  FlexibleXYPlot,
  VerticalBarSeries,
  HorizontalBarSeries,
  LineMarkSeries,
  XAxis,
  YAxis
} from 'react-vis';
import { get as _get } from 'lodash';
import Numeral from 'numeral';
import moment from 'moment';

class SimpleXYChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props
    };
    this.sync(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const { filter } = this.state;
    if (nextProps.filter !== filter) {
      this.setState({ filter: nextProps.filter }, () => this.sync(nextProps));
    }
  }

  sync = props => {
    console.log('SYNCING');
    const opts = {
      keyword: props.keyword,
      dataKey: props.dataKey,
      bucketSize: props.bucketSize,
      type: props.type,
      id: props.id
    };
    props.sync(props.filter, opts);
  };

  render() {
    const { data, dataKey, xAngle, yAngle } = this.props;
    const gLoading = _get(data, `loading_${dataKey}`);
    const gData = _get(data, dataKey);

    const getSeries = () => {
      const { gType } = this.props;
      if (gType === 'line') return LineMarkSeries;
      if (gType === 'hbar') return HorizontalBarSeries;
      return VerticalBarSeries;
    };

    const getXTickFormat = v => {
      const { xType } = this.props;
      return xType === 'date' ? `${moment(parseInt(v)).format('DD/MM HH')}hrs` : v.split('(')[0];
    };

    const getYTickFormat = v => {
      const { aggDivide } = this.props.filter;
      return Numeral(v / aggDivide).format('0.0a');
    };

    const onMouseOver = v => {
      const { xType } = this.props;
      if (v.x && v.y) {
        const date = 'date' ? `${moment(parseInt(v)).format('DD/MM HH')}hrs` : v.split('(')[0];
        this.setState({
          hoveredCell: {
            x: date,
            y: v.y
          }
        });
      } else {
        this.setState({
          hoveredCell: {}
        });
      }
    };

    const onMouseOut = v => {
      this.setState({ hoveredCell: {} });
    };

    const Series = getSeries();

    const { height } = this.props;

    return (
      <div style={{ height: height || '200px', textAlign: 'center' }}>
        {!gLoading && (
          <FlexibleXYPlot xType="ordinal" margin={{ left: 50, bottom: 50 }}>
            <XAxis tickFormat={v => getXTickFormat(v)} tickLabelAngle={xAngle} />
            <YAxis tickFormat={v => getYTickFormat(v)} tickLabelAngle={yAngle} />
            <Series
              onValueMouseOver={v => onMouseOver(v)}
              onValueMouseOut={v => onMouseOut(v)}
              data={gData}
            />
          </FlexibleXYPlot>
        )}
        {gLoading && <Spin tip="Loading..." style={{ marginTop: '30px' }} />}
      </div>
    );
  }
}

export default SimpleXYChart;
