import React, { Component } from 'react';
import { Form, Input, Button } from "antd";
import FormItem from "../../shared/form/FormItem";
import FilterForm from "../../shared/form/FilterForm";

class DeviceFilterForm extends Component {
    render() {
        return (
            <FilterForm
                form={this.props.form}
                filter={this.props.filter}
                onFilter={this.props.onFilter} >
                <FormItem
                    label="Username"
                    property="username"
                    form={this.props.form}
                    initialValue={this.props.filter.username}
                    isRequired={false}
                >
                    <Input placeholder="Username" autoComplete="filter-username" />
                </FormItem >
            </FilterForm >
        )
    }
}

export default Form.create()(DeviceFilterForm);