import React, { Component } from 'react';
import { Form, Input } from 'antd';
import SecuredButton from '../shared/SecuredButton';

const FormItem = Form.Item;

const updateRoles = [
  'ROLE_ADMIN',
  'ROLE_RESELLER',
  'ROLE_CUSTOMER',
  'ROLE_UPDATE_CUSTOMER_GENERAL'
];

class CustomerAddressForm extends Component {
  handleSubmit = () => {
    const { customer, form, onUpdate } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        onUpdate({
          ...customer,
          ...values
        });
      }
    });
  };
  render() {
    const { principal, loading, form, address } = this.props;
    return (
      <Form layout="vertical" style={{ width: '600px' }}>
        <FormItem label="Premises">
          {form.getFieldDecorator('address.line1', {
            rules: [{ required: true, message: 'Please input your premises!' }],
            initialValue: address.line1
          })(<Input placeholder="Premises" autoComplete="address-line1" />)}
        </FormItem>
        <FormItem label="Street">
          {form.getFieldDecorator('address.line2', {
            rules: [{ required: true, message: 'Please input your street!' }],
            initialValue: address.line2
          })(<Input placeholder="Street" autoComplete="address-line2" />)}
        </FormItem>
        <FormItem label="Town">
          {form.getFieldDecorator('address.level2', {
            rules: [{ required: true, message: 'Please input your town!' }],
            initialValue: address.level2
          })(<Input placeholder="Town" autoComplete="address-level2" />)}
        </FormItem>
        <FormItem label="State / Province">
          {form.getFieldDecorator('address.level1', {
            rules: [{ required: true, message: 'Please input your state / province!' }],
            initialValue: address.level1
          })(<Input placeholder="State / Province" autoComplete="address-level1" />)}
        </FormItem>
        <FormItem label="PostalCode" className="split">
          {form.getFieldDecorator('address.postalCode', {
            rules: [{ required: true, message: 'Please input your postal code!' }],
            initialValue: address.postalCode
          })(<Input placeholder="Postal Code" autoComplete="postal-code" />)}
        </FormItem>
        <FormItem label="Country" className="split">
          {form.getFieldDecorator('address.country', {
            rules: [{ required: true, message: 'Please input your country!' }],
            initialValue: address.country
          })(<Input placeholder="Country" autoComplete="country" />)}
        </FormItem>
        <FormItem>
          <FormItem>
            <SecuredButton
              type="primary"
              title="Update"
              icon="cloud-upload"
              principal={principal}
              disabled={loading}
              onClick={this.handleSubmit}
              permission="customer/edit/address"
            />
          </FormItem>
        </FormItem>
      </Form>
    );
  }
}

export default Form.create()(CustomerAddressForm);
