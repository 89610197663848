import { CALLBACK_PATH } from "../Props.js";
import API from "./api-service";


const jsonHeader = (apiKey) => {
    return {
        timeout: 5000,
        headers: {
            "X-VIVA-Client-ID": apiKey,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }
}

//Callbacks are already present in customer payload 
//so filter locally
function filter(filter, customer) {
    let filteredContent = customer.callbackURLS.filter(t => {
        return t.app.includes(filter.app);
    });
    let pagContent = filteredContent.slice(filter.page * filter.size, (filter.page + 1) * filter.size);
    let result = {
        number: filter.page,
        size: filter.size,
        totalElements: filteredContent.length,
        content: pagContent
    };
    return { data: result };
}

//Callbacks are already present in customer payload 
//so simply return callback
function get(data) {
    return {
        data
    };
}

function put(callback, customer) {
    return API.put(callback.id, callback, CALLBACK_PATH, jsonHeader(customer.clientID));
}

function remove(id, customer) {
    return API.remove(id, CALLBACK_PATH, jsonHeader(customer.clientID));
}

function post(callback, customer) {
    return API.post(callback, CALLBACK_PATH, jsonHeader(customer.clientID));
}

export default {
    get, filter, put, post, remove
}


