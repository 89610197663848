import React, { Component } from 'react';
import { Form, Input, Button } from "antd";
import { connect } from "react-redux";
import * as actions from "../../../actions/trunk-actions";

const FormItem = Form.Item;

class TrunkFilterForm extends Component {

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let f = {
                    ...this.props.filter,
                    ip: values.ip
                }
                this.props.onSearchTrunk(f);
            }
        });
    };


    render() {

        const { getFieldDecorator } = this.props.form;

        return (
            <div>

                <Form
                    onSubmit={this.handleSubmit}
                    className="customer-filter-form"
                    layout="inline">


                    <FormItem label="IP Address">
                        {getFieldDecorator('ip', {
                            rules: [{ required: false }],
                            initialValue: this.props.filter.ip
                        })(
                            <Input placeholder="IP Address" autoComplete="customer-ip" />
                        )}
                    </FormItem>

                    <FormItem>
                        <Button type="primary" htmlType="submit" className="login-form-button" block={true}>Search</Button>
                    </FormItem>
                </Form>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        filter: state.trunk.filter,
        loading: state.trunk.loading
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSearchTrunk: (filter) => dispatch(actions.search(filter))
    }
};

const thisForm = Form.create()(TrunkFilterForm)

export default connect(mapStateToProps, mapDispatchToProps)(thisForm);