import { connect } from 'react-redux';
import ResetPassword from '../components/resetPassword/ResetPassword';
import { CHANGE_PASSWORD } from '../actions/auth-actions';

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    onResetPassword: (password, token) => dispatch({ type: CHANGE_PASSWORD, password, token }),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
