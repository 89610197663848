import React from 'react';
import { Avatar } from 'antd';
import './notfound.css';

const NotFound = () => (
  <div className="center-wrapper">
    <div className="login-avatar">
      <Avatar size="large" icon="exclamation-circle" />
      <h2>404</h2>
      <h4 className="center-header">Sorry we cannot find the url you requested</h4>
    </div>
  </div>
);

export default NotFound;
