import { put, takeEvery, call, select } from "redux-saga/effects";
import * as actions from "../actions/recorder-actions";
import API from "../services/recorder-service";
import { message } from 'antd';

const getAPIKey = (state) => state.auth.principal.apiKey;

function* doRecorderSelect({ payload }) {
    yield put(actions.loading());
    yield put(actions.load(payload.id))
    yield put(actions.selectSuccess(payload));
};

function* doRecorderUpdate({ payload }) {
    yield put(actions.loading());
    const apiKey = yield select(getAPIKey);
    try {
        yield call(API.put, payload, payload.id, apiKey);
        yield put(actions.updateSuccess(payload));
    } catch (e) {
        message.error(e);
    }
};


function* doRecorderFilter({ filter }) {
    yield put(actions.loading())
    const apiKey = yield select(getAPIKey);
    try {
        const { data } = yield call(API.filter, filter, apiKey);
        yield put(actions.searchSuccess(filter, data));
    } catch (e) {
        message.error(e);
    }
};

function* doRecorderSync({ id }) {
    yield doRecorderSelect({ id });
};

function* doRecorderLoad({ id }) {
    yield put(actions.loading())
    const apiKey = yield select(getAPIKey);
    try {
        const { data } = yield call(API.getS3URL, id, apiKey);
        yield put(actions.loadSuccess(data));
    } catch (e) {
        message.error(e);
    }
};

function* doRecorderClose() {
    yield put(actions.loading());
    yield put(actions.closeSuccess());
};


export default function* watchRecorder() {
    yield takeEvery(actions.CLOSE, doRecorderClose);
    yield takeEvery(actions.SYNC, doRecorderSync);
    yield takeEvery(actions.LOAD, doRecorderLoad);
    yield takeEvery(actions.SEARCH, doRecorderFilter);
    yield takeEvery(actions.SELECT, doRecorderSelect);
    yield takeEvery(actions.UPDATE, doRecorderUpdate);
};