import { ActionConst } from './constants';

export function complete(route, redirect) {
  return {
    type: ActionConst.view.complete,
    route,
    redirect
  };
}

export function success(route) {
  return {
    type: ActionConst.view.success,
    value: route
  };
}
