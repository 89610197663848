import PropTypes from 'prop-types';

const { shape, string, bool, number, arrayOf } = PropTypes;

const permissionType = shape({
  permission: string.isRequired,
  description: string.isRequired,
  roles: arrayOf(string)
});

const contentListType = shape({
  id: string.isRequired
});

const sortType = shape({
  sorted: bool.isrequired,
  unsorted: bool.isRequired,
  empty: bool.isRequired
});

const pageable = shape({
  offset: number.isRequired,
  pageNumber: number.isRequired,
  pageSize: number.isRequired,
  paged: bool.isRequired,
  sort: sortType.isRequired
});

export const principalType = shape({
  username: string.isRequired,
  apiKey: string.isRequired,
  email: string,
  role: arrayOf(string),
  permissions: arrayOf(permissionType)
});

export const pageableListType = shape({
  content: arrayOf(contentListType),
  empty: bool.isRequired,
  first: bool.isRequired,
  last: bool.isRequired,
  number: number.isRequired,
  numberOfElements: number.isRequired,
  pageable: pageable.isRequired,
  size: number.isRequired,
  sort: sortType.isRequired,
  totalElements: number.isRequired,
  totalPages: number.isRequired
});

export const columnType = shape({
  title: string.isRequired,
  dataIndex: string.isRequired,
  key: string.isRequired
});

export const filterType = shape({
  id: string.isOptional,
  duration: string.isRequired,
  offset: string.isRequired,
  aggType: string.isRequired,
  aggField: string.isRequired,
  aggDivide: number.isRequired,
  filters: arrayOf(string).isOptional
});
