import { SETTING_PATH } from "../Props.js";
import API from "./api-service";


const jsonHeader = (apiKey) => {
    return {
        timeout: 5000,
        headers: {
            "X-VIVA-Client-ID": apiKey,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }
}

//Settings are already present in customer payload 
//so filter locally
function filter(filter, customer) {
    let filteredContent = customer.settings.filter(item => {
        return item.key.includes(filter.key);
    });
    let pagContent = filteredContent.slice(filter.page * filter.size, (filter.page + 1) * filter.size);
    let result = {
        number: filter.page,
        size: filter.size,
        totalElements: filteredContent.length,
        content: pagContent
    };
    return { data: result };
}

//Settings are already present in customer payload 
//so simply return setting
function get(data) {
    return {
        data
    };
}

function put(setting, customer) {
    return API.put(setting.id, setting, SETTING_PATH, jsonHeader(customer.clientID));
}

function remove(id, customer) {
    return API.remove(id, SETTING_PATH, jsonHeader(customer.clientID));
}

function post(setting, customer) {
    return API.post(setting, SETTING_PATH, jsonHeader(customer.clientID));
}

export default {
    get, filter, put, post, remove
}


