export const LOGIN = "auth/login";
export const LOGOUT = "auth/logout";
export const REGISTER = "auth/register";
export const RESET_EMAIL = "auth/forgot-password";
export const CHANGE_PASSWORD = "auth/change-password";
export const CHECK_PRINCIPAL = "auth/check-principal";

//Outcomes 

export const LOGIN_SUCCESS = "auth/login-success";
export const LOGIN_FAILED = "auth/login-failed";
export const LOGOUT_SUCCESS = "auth/logout-success";
export const REGISTER_SUCESS = "auth/register-success";
export const REGISTER_FAILED = "auth/register-failed";
export const CHANGE_PASSWORD_SUCCESS = "auth/change-password-success";
export const CHANGE_PASSWORD_FAILED = "auth/change-password-failed";
export const RESET_EMAIL_SUCCESS = "auth/reset-email-success";
export const RESET_EMAIL_FAILED = "auth/reset-email-failed";
