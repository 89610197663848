import { put, takeEvery, call, select } from "redux-saga/effects";
import * as actions from "../actions/ddi-actions";
import * as customerActions from "../actions/customer-actions";
import API from "../services/ddi-service";
import { message } from 'antd';

const getCustomer = (state) => state.customer.selected;

function* doDdiSelect({ selected }) {
    yield put(actions.loading());
    try {
        const { data } = yield call(API.get, selected);
        yield put(actions.selectSuccess(data));
    } catch (e) {
        message.error(e);
    }
};


function* doDdiUpdate({ payload }) {
    yield put(actions.loading());
    const customer = yield select(getCustomer);
    try {
        yield call(API.put, payload, customer);
        yield put(actions.updateSuccess(payload));
    } catch (e) {
        message.error(e);
    }
};

function* doDdiCreate({ payload }) {
    yield put(actions.loading());
    const customer = yield select(getCustomer);
    try {
        const data = yield call(API.post, payload, customer);
        payload.id = yield data.headers.location.replace("ddi/", "");
        yield put(actions.createSuccess(payload));
        yield put(customerActions.select(customer.id))
        yield put(actions.closeCreate());
    } catch (e) {
        message.error(e);
    }
};

function* doDdiDelete({ id }) {
    yield put(actions.loading());
    const customer = yield select(getCustomer);
    try {
        yield call(API.remove, id, customer);
        yield put(actions.removeSuccess(id));
        yield put(customerActions.select(customer.id))
        yield put(actions.closeEdit());
    } catch (e) {
        message.error(e);
    }
};


function* doDdiFilter({ filter }) {
    yield put(actions.loading());
    const customer = yield select(getCustomer);
    try {
        const { data } = yield call(API.filter, filter, customer);
        yield put(actions.searchSuccess(filter, data));
    } catch (e) {
        message.error(e);
    }
};


export default function* watchDdi() {
    yield takeEvery(actions.CREATE, doDdiCreate);
    yield takeEvery(actions.DELETE, doDdiDelete);
    yield takeEvery(actions.SEARCH, doDdiFilter);
    yield takeEvery(actions.SELECT, doDdiSelect);
    yield takeEvery(actions.UPDATE, doDdiUpdate);
};