import React, { Component } from 'react';
import { Button, Form, Icon, Input } from "antd";

const FormItem = Form.Item;

class RegisterForm extends Component {
    handleSubmit = (e) => {
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        if (!err) {
          this.props.onRegister(values.registerRequest);
        }
      });
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        const userIcon = <Icon type="user" />;
        const globalIcon = <Icon type="global" />;
        const mailIcon = <Icon type="mail" />;
        const phoneIcon = <Icon type="phone" />;
        const addrIcon = <Icon type="environment" />;

        const requiredArgs = { rules: [{ required: true }] };

        return (
            <Form onSubmit={this.handleSubmit} className="register-form">

                <FormItem help="">
                    {getFieldDecorator('registerRequest.username', requiredArgs)(
                        <Input
                            prefix={userIcon}
                            placeholder="Username"
                            autoComplete="name"
                        />
                    )}
                </FormItem>

                <FormItem help="">
                    {getFieldDecorator('registerRequest.email', requiredArgs)(
                        <Input
                            prefix={mailIcon}
                            placeholder="Email"
                            autoComplete="email"
                        />
                    )}
                </FormItem>

                <FormItem help="">
                    {getFieldDecorator('registerRequest.mobile', requiredArgs)(
                        <Input
                            prefix={phoneIcon}
                            placeholder="Mobile"
                            autoComplete="tel"
                        />
                    )}
                </FormItem>

                <FormItem help="">
                    {getFieldDecorator('registerRequest.companyName', requiredArgs)(
                        <Input
                            prefix={globalIcon}
                            placeholder="Company Name" />
                    )}
                </FormItem>

                <FormItem>
                    <h4>Address</h4>
                </FormItem>

                <FormItem help="">
                    {getFieldDecorator('registerRequest.address.line1', requiredArgs)(
                        <Input
                            prefix={addrIcon}
                            placeholder="Premises"
                            autoComplete="address-line1" />
                    )}
                </FormItem>

                <FormItem help="">
                    {getFieldDecorator('registerRequest.address.line2', requiredArgs)(
                        <Input
                            prefix={addrIcon}
                            placeholder="Street"
                            autoComplete="address-line2" />
                    )}
                </FormItem>

                <FormItem help="" className="split">
                    {getFieldDecorator('registerRequest.address.level2', requiredArgs)(
                        <Input
                            prefix={addrIcon}
                            placeholder="Town"
                            autoComplete="address-level2"
                        />
                    )}
                </FormItem>

                <FormItem help="" className="split">
                    {getFieldDecorator('registerRequest.address.level1', requiredArgs)(
                        <Input
                            prefix={addrIcon}
                            placeholder="State / Province"
                            autoComplete="address-level1"
                        />
                    )}
                </FormItem>

                <FormItem help="" >
                    {getFieldDecorator('registerRequest.address.postalCode', requiredArgs)(
                        <Input
                            prefix={addrIcon}
                            placeholder="Postal Code"
                            autoComplete="postal-code"
                        />
                    )}
                </FormItem>

                <FormItem help="" >
                    {getFieldDecorator('registerRequest.address.country', requiredArgs)(
                        <Input
                            prefix={addrIcon}
                            placeholder="Country"
                            autoComplete="country"
                        />
                    )}
                </FormItem>

                <FormItem>
                  <Button type="primary" htmlType="submit" className="register-form-button" block={true}>Register</Button>
                  <Button type="dashed" htmlType="button" onClick={() => this.props.onViewLogin()} block={true}>Back to Login</Button>
                </FormItem>
            </Form>
        )
    }
}

export default Form.create()(RegisterForm);
