import React, { Component } from "react";
import { Select } from "antd";
import FormItem from "../../../shared/form/FormItem";
import PropTypes from 'prop-types';

class IVROverrideSelectFormItem extends Component {
    static propTypes = {
        customer: PropTypes.object,
        form: PropTypes.object,
        initialValue: PropTypes.any
    }
    render() {
        console.log("CUST", this.props.customer);
        const opts = this.props.customer.callbackURLS.filter(cb => {
            return cb.app.startsWith("ivr") || cb.app.includes("D2C")
        }).map(ivr => {
            return <Select.Option key={ivr.id}>{ivr.url}</Select.Option>;
        });
        return (
            <FormItem
                label="Trunk Override"
                property="trunk"
                isRequired={false}
                form={this.props.form}
                initialValue={this.props.initialValue}
            >
                <Select
                    allowClear={true}
                    showSearch
                    placeholder="Select an ivr application"
                    optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    {opts}
                </Select>
            </FormItem>
        )
    }
}
export default IVROverrideSelectFormItem;