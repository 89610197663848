import { connect } from 'react-redux';
import Device from '../components/customer/device/Device';
import * as actions from '../actions/device-actions';

const mapStateToProps = state => {
  return {
    principal: state.auth.principal,
    device: state.device,
    customer: state.customer.selected
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: {
      onCloseCreate: () => dispatch(actions.closeCreate()),
      onCloseEdit: () => dispatch(actions.closeEdit()),
      onOpenCreate: () => dispatch(actions.openCreate()),
      onSearch: filter => dispatch(actions.search(filter)),
      onSelect: device => dispatch(actions.select(device)),
      onDelete: id => dispatch(actions.remove(id)),
      onUpdate: payload => dispatch(actions.update(payload)),
      onCreate: payload => dispatch(actions.create(payload))
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Device);
