import React from 'react';
import DdiCreateForm from './DdiCreateForm';
import DdiEditForm from './DdiEditForm';
import DdiFilterForm from './DdiFilterForm';
import StandardList from '../../shared/StandardList';
import StandardToolbar from '../../shared/StandardToolbar';
import SecuredButton from '../../shared/SecuredButton';
import { Drawer, Icon } from 'antd';
import './ddi.css';

const columns = [
  { title: 'ID', dataIndex: 'id', key: 'id' },
  { title: 'Name', dataIndex: 'name', key: 'name' },
  { title: 'Direction', dataIndex: 'direction', key: 'direction' },
  { title: 'Match', dataIndex: 'match', key: 'match' },
  {
    title: 'Record',
    dataIndex: 'record',
    key: 'record',
    render: b => {
      return b ? <Icon type="customer-service" /> : '';
    }
  }
];

const Ddi = ({ principal, actions, ddi, customer }) => (
  <div>
    <StandardToolbar
      left={<DdiFilterForm filter={ddi.filter} onFilter={actions.onSearch} />}
      right={
        <SecuredButton
          icon="plus"
          principal={principal}
          title="Create Number"
          onClick={actions.onOpenCreate}
          permission="customer/create/ddi"
        />
      }
    />

    <StandardList
      {...actions}
      {...ddi}
      columns={columns}
      principal={principal}
      permission="customer/edit/ddi"
    />

    <Drawer
      width={860}
      title="Create new ddi"
      onClose={actions.onCloseCreate}
      visible={ddi.showCreate}
    >
      <DdiCreateForm
        principal={principal}
        onCreate={actions.onCreate}
        onClose={actions.onCloseCreate}
        loading={ddi.loading}
        customer={customer}
        create={ddi.create}
      />
    </Drawer>
    <Drawer title="Edit ddi" width={860} onClose={actions.onCloseEdit} visible={ddi.showEdit}>
      {ddi.showEdit && (
        <DdiEditForm
          principal={principal}
          loading={ddi.loading}
          onUpdate={actions.onUpdate}
          onDelete={actions.onDelete}
          onClose={actions.onCloseEdit}
          customer={customer}
          selected={ddi.selected}
        />
      )}
    </Drawer>
  </div>
);

export default Ddi;
