import React, { Fragment } from 'react';
import { Drawer, Tabs } from 'antd';
import CallbackCreateForm from './CallbackCreateForm';
import CallbackEditForm from './CallbackEditForm';
import CallbackFilterForm from './CallbackFilterForm';
import StandardList from '../../shared/StandardList';
import StandardToolbar from '../../shared/StandardToolbar';
import SecuredButton from '../../shared/SecuredButton';
import IVRErrorChart from '../../dashboard/cards/IVRErrorChart';
import DashboardFilter from '../../dashboard/DashboardFilter';

import './callback.css';

const columns = [
  { title: 'ID', dataIndex: 'id', key: 'id' },
  { title: 'Application', dataIndex: 'app', key: 'app' },
  { title: 'URL', dataIndex: 'url', key: 'url' },
  { title: 'Auth Type', dataIndex: 'authType', key: 'authType' }
];

const createButton = (principal, onOpenCreate) => (
  <SecuredButton
    icon="plus"
    principal={principal}
    title="Create Integration"
    onClick={onOpenCreate}
    permission="customer/create/callback"
  />
);

const Callback = ({ principal, actions, callback, customer, dashboard, dashboardActions }) => (
  <Fragment>
    <Tabs>
      <Tabs.TabPane key="list" tab="Integrations">
        <StandardToolbar
          left={<CallbackFilterForm filter={callback.filter} onFilter={actions.onSearch} />}
          right={createButton(principal, actions.onOpenCreate)}
        />
        <StandardList
          {...actions}
          {...callback}
          columns={columns}
          principal={principal}
          permission="customer/edit/callback"
        />
      </Tabs.TabPane>
      <Tabs.TabPane key="errors" tab="Errors">
        <DashboardFilter onFilter={dashboardActions.onFilter} filter={dashboard.filter} />
        <IVRErrorChart
          id={customer.id}
          height={400}
          loading={dashboard.loading}
          filter={dashboard.filter}
          onFilter={dashboardActions.onFilter}
          ivrErrors={dashboard.ivrErrors}
          sync={dashboardActions.onSyncIVRErrors}
        />
      </Tabs.TabPane>
    </Tabs>

    <Drawer
      width={860}
      title="Create new callback"
      onClose={actions.onCloseCreate}
      visible={callback.showCreate}
    >
      <CallbackCreateForm
        principal={principal}
        onCreate={actions.onCreate}
        onClose={actions.onCloseCreate}
        loading={callback.loading}
        create={callback.create}
      />
    </Drawer>

    <Drawer
      title="Edit callback"
      width={860}
      onClose={actions.onCloseEdit}
      visible={callback.showEdit}
    >
      {callback.showEdit && (
        <CallbackEditForm
          principal={principal}
          loading={callback.loading}
          onUpdate={actions.onUpdate}
          onDelete={actions.onDelete}
          onClose={actions.onCloseEdit}
          selected={callback.selected}
        />
      )}
    </Drawer>
  </Fragment>
);

export default Callback;
