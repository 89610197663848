import React, { Component } from 'react';
import StandardPagination from './StandardPagination';
import StandardTable from './StandardTable';

class StandardList extends Component {
  render() {
    const {
      principal,
      permission,
      pageable,
      columns,
      filter,
      onOpenCreate,
      onSelect,
      onSearch,
      children
    } = this.props;
    return (
      <div>
        {pageable && (
          <div>
            <StandardTable
              principal={principal}
              permission={permission}
              columns={columns}
              onOpenCreate={onOpenCreate}
              onSelect={onSelect}
              pageable={pageable}
            >
              {children}
            </StandardTable>
            <StandardPagination filter={filter} pageable={pageable} onPage={onSearch} />
          </div>
        )}
      </div>
    );
  }
}

export default StandardList;
