import React, { Component } from 'react';
import { Form, Input, Icon, Button, Col, Row } from "antd";
import { connect } from "react-redux";
import * as actions from "../../actions/customer-actions";
import RadioGroup from "antd/es/radio/group";
import Radio from "antd/es/radio/radio";
import CancelSubmitButtonBar from '../shared/CancelSubmitButtonBar';

const FormItem = Form.Item;

class CustomerGeneralForm extends Component {

    constructor(props) {
        super();
        this.state = {
            guid: this.guid()
        }
    }

    generateGuid = (e) => {
        e.preventDefault();
        this.setState({
            guid: this.guid()
        })
    }

    guid = () => {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let payload = {
                    ...this.props.create,
                    ...values
                }
                this.props.onCreate(payload);
            }
        });
    };

    render() {

        const { getFieldDecorator } = this.props.form;

        const buildingIcon = <Icon type="build" />
        const numberIcon = <Icon type="number" />
        const lockIcon = <Icon type="lock" />
        const envIcon = <Icon type="environment" />
        const boltIcon = <Icon type="thunderbolt" theme="filled" />


        const generateGuid = <button onClick={this.generateGuid}>{boltIcon}</button>;

        return (
            <Form onSubmit={this.handleSubmit} layout="vertical">

                <Row gutter={30}>

                    <Col span={12}>

                        <FormItem label="Customer Name">
                            {getFieldDecorator('name', {
                                rules: [{
                                    required: true,
                                    message: 'Please input the customer name'
                                }],
                                initialValue: this.props.create.name
                            })(
                                <Input prefix={buildingIcon}
                                    placeholder="Name" />
                            )}
                        </FormItem>


                        <FormItem label="Main Presentation Number">
                            {getFieldDecorator('mbn', {
                                rules: [{
                                    required: true, message: 'Please input the customer main billing number'
                                }],
                                initialValue: this.props.create.mbn,
                            })(
                                <Input prefix={numberIcon}
                                    placeholder="Main Billing Number" />
                            )}
                        </FormItem>

                        <FormItem label="Token">
                            {getFieldDecorator('clientID', {
                                rules: [{
                                    required: true, message: 'Please input the customer clientID'
                                }],
                                initialValue: this.state.guid
                            })(
                                <Input prefix={lockIcon}
                                    placeholder="ClientID"
                                    addonAfter={generateGuid} />
                            )}
                        </FormItem>

                        <FormItem label="Type">
                            {getFieldDecorator('type', {
                                initialValue: this.props.create.type
                            })(
                                <RadioGroup>
                                    <Radio value="viva">VIVA</Radio>
                                    <Radio value="m6">M6</Radio>
                                    <Radio value="q20">Q20</Radio>
                                    <Radio value="other">Other</Radio>
                                    <Radio value="prepay">PRE-PAY</Radio>
                                </RadioGroup>
                            )}
                        </FormItem>
                    </Col>

                    <Col span={12}>

                        <h4>Address</h4>

                        <FormItem label="Premises">
                            {getFieldDecorator('address.line1', {
                                rules: [{ required: true, message: 'Please input your premises!' }],
                                initialValue: this.props.create.address.line1
                            })(
                                <Input
                                    prefix={envIcon}
                                    placeholder="Premises"
                                    autoComplete="address-line1" />
                            )}
                        </FormItem>

                        <FormItem label="Street">
                            {getFieldDecorator('address.line2', {
                                rules: [{ required: true, message: 'Please input your street!' }],
                                initialValue: this.props.create.address.line2
                            })(
                                <Input
                                    prefix={envIcon}
                                    placeholder="Street"
                                    autoComplete="address-line2" />
                            )}
                        </FormItem>

                        <FormItem label="Town">
                            {getFieldDecorator('address.level2', {
                                rules: [{ required: true, message: 'Please input your town!' }],
                                initialValue: this.props.create.address.level2
                            })(
                                <Input
                                    prefix={envIcon}
                                    placeholder="Town"
                                    autoComplete="address-level2"
                                />
                            )}
                        </FormItem>

                        <FormItem label="State / Province">
                            {getFieldDecorator('address.level1', {
                                rules: [{ required: true, message: 'Please input your state / province!' }],
                                initialValue: this.props.create.address.level1
                            })(
                                <Input
                                    prefix={envIcon}
                                    placeholder="State / Province"
                                    autoComplete="address-level1"
                                />
                            )}
                        </FormItem>

                        <FormItem label="PostalCode" className="split">
                            {getFieldDecorator('address.postalCode', {
                                rules: [{ required: true, message: 'Please input your postal code!' }],
                                initialValue: this.props.create.address.postalCode
                            })(
                                <Input
                                    prefix={envIcon}
                                    placeholder="Postal Code"
                                    autoComplete="postal-code"
                                />
                            )}
                        </FormItem>

                        <FormItem label="Country" className="split">
                            {getFieldDecorator('address.country', {
                                rules: [{ required: true, message: 'Please input your country!' }],
                                initialValue: this.props.create.address.country
                            })(
                                <Input
                                    prefix={envIcon}
                                    placeholder="Country"
                                    autoComplete="country"
                                />
                            )}

                        </FormItem>
                    </Col>
                </Row>

                <CancelSubmitButtonBar
                    onClose={this.props.onCancel}
                    createText="Create"
                    cancelText="Cancel"
                />

            </Form>
        )
    }
}

export default Form.create()(CustomerGeneralForm);
