import React, { Component } from "react";
import { Pagination } from "antd";

class StandardPagination extends Component {
    pageHandle = (page) => {
        this.props.onPage({
            ...this.props.filter,
            page: page - 1
        });
    }
    render() {
        const { pageable } = this.props;
        return (
            <Pagination
                size="small"
                className="pagination"
                defaultCurrent={pageable.number + 1}
                page={pageable.number + 1}
                total={pageable.totalElements}
                pageSize={pageable.size}
                onChange={this.pageHandle}
            />
        )
    }
}

export default StandardPagination;