import React, { Component } from 'react';
import { Form, Input, Icon } from 'antd';
import RadioGroup from 'antd/es/radio/group';
import Radio from 'antd/es/radio/radio';
import SecuredButton from '../shared/SecuredButton';

const FormItem = Form.Item;

class CustomerGeneralForm extends Component {
  constructor(props) {
    super();
    this.state = {
      guid: props.customer.clientID ? props.customer.clientID : this.guid()
    };
  }

  generateGuid = e => {
    e.preventDefault();
    this.setState({
      guid: this.guid()
    });
  };

  guid = () => {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  };

  hasRole = role => {
    return this.props.principal.roles.filter(r => r === role).length > 0;
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let payload = {
          ...this.props.customer,
          ...values
        };
        this.props.onUpdate(payload);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const buildingIcon = <Icon type="build" />;
    const numberIcon = <Icon type="number" />;
    const lockIcon = <Icon type="lock" />;
    const boltIcon = <Icon type="thunderbolt" theme="filled" />;

    const generateGuid = this.hasRole('ROLE_ADMIN') ? (
      <button onClick={this.generateGuid}>{boltIcon}</button>
    ) : (
      <span />
    );

    return (
      <Form onSubmit={this.handleSubmit} layout="vertical" style={{ width: '600px' }}>
        <FormItem label="Customer Name">
          {getFieldDecorator('name', {
            rules: [
              {
                required: true,
                message: 'Please input the customer name'
              }
            ],
            initialValue: this.props.customer.name
          })(
            <Input
              prefix={buildingIcon}
              placeholder="Name"
              disabled={!this.hasRole('ROLE_ADMIN')}
            />
          )}
        </FormItem>

        <FormItem label="Main Presentation Number">
          {getFieldDecorator('mbn', {
            rules: [
              {
                required: true,
                message: 'Please input the customer main billing number'
              }
            ],
            initialValue: this.props.customer.mbn
          })(
            <Input
              prefix={numberIcon}
              placeholder="Main Billing Number"
              disabled={!this.hasRole('ROLE_ADMIN')}
            />
          )}
        </FormItem>

        <FormItem label="Token">
          {getFieldDecorator('clientID', {
            rules: [
              {
                required: true,
                message: 'Please input the customer clientID'
              }
            ],
            initialValue: this.state.guid
          })(
            <Input
              prefix={lockIcon}
              placeholder="ClientID"
              addonAfter={generateGuid}
              disabled={!this.hasRole('ROLE_ADMIN')}
            />
          )}
        </FormItem>

        <FormItem label="Type">
          {getFieldDecorator('type', {
            initialValue: this.props.customer.type
          })(
            <RadioGroup>
              <Radio value="viva">VIVA</Radio>
              <Radio value="m6">M6</Radio>
              <Radio value="q20">Q20</Radio>
              <Radio value="other">Other</Radio>
              <Radio value="prepay">PRE-PAY</Radio>
            </RadioGroup>
          )}
        </FormItem>
        <FormItem>
          <SecuredButton
            type="primary"
            title="Update"
            icon="cloud-upload"
            principal={this.props.principal}
            disabled={this.props.loading}
            onClick={this.handleSubmit}
            permission="customer/edit/general"
          />
        </FormItem>
      </Form>
    );
  }
}

export default Form.create()(CustomerGeneralForm);
