import React, { Component } from 'react';
import { Form, Input, Modal } from 'antd';
import FormItem from '../../shared/form/FormItem';
import ApplicationSelectFormItem from './inputs/ApplicationSelectFormItem';
import AuthTypeRadioGroupFormItem from './inputs/AuthTypeRadioGroupFormItem';
import RestMethodRadioGroupFormItem from './inputs/RestMethodRadioGroupFormItem';
import CancelSubmitButtonBar from '../../shared/CancelSubmitButtonBar';

const confirm = Modal.confirm;

class CallbackForm extends Component {
  constructor(props) {
    super();
    this.state = {
      authType: props.bean.authType,
      app: props.bean.app
    };
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values);
      }
    });
  };
  handleDelete = () => {
    let p = this.props;
    confirm({
      title: 'Are you sure?',
      onOk() {
        p.onDelete(p.bean.id);
      },
      onCancel() {}
    });
  };
  render() {
    const isApiKeyNameVisible =
      this.state.authType === 'KEY' || this.state.authType === 'KEY_SECRET';
    const isApiKeyValueVisible =
      this.state.authType === 'KEY' ||
      this.state.authType === 'KEY_SECRET' ||
      (this.state.authType === 'DISABLED' && this.state.app === 'ivr.cordic');
    const isApiSecretNameVisible =
      this.state.authType === 'BASIC_AUTH' || this.state.authType === 'KEY_SECRET';
    const isApiSecretValueVisible =
      this.state.authType === 'BASIC_AUTH' || this.state.authType === 'KEY_SECRET';
    return (
      <Form onSubmit={this.handleSubmit} layout="vertical">
        <FormItem
          label="URL"
          property="url"
          isRequired={true}
          form={this.props.form}
          initialValue={this.props.bean.url}
          message="Please input the callback url"
        >
          <Input placeholder="URL" />
        </FormItem>
        <ApplicationSelectFormItem
          form={this.props.form}
          initialValue={this.props.bean.app}
          onChange={value => this.setState({ app: value })}
        />
        <AuthTypeRadioGroupFormItem
          form={this.props.form}
          initialValue={this.props.bean.authType}
          onChange={value => this.setState({ authType: value })}
        />
        <FormItem
          label="API Key Name"
          property="apiKeyName"
          form={this.props.form}
          initialValue={this.props.bean.apiKeyName}
          message="Please input the callback api key name"
          isRequired={isApiKeyNameVisible}
          isVisible={isApiKeyNameVisible}
        >
          <Input placeholder="Key Name" />
        </FormItem>
        <FormItem
          label="API Key Value"
          property="apiKeyValue"
          form={this.props.form}
          initialValue={this.props.bean.apiKeyValue}
          message="Please input the callback api key value"
          isRequired={isApiKeyValueVisible}
          isVisible={isApiKeyValueVisible}
        >
          <Input placeholder="Key Value" />
        </FormItem>
        <FormItem
          label="API Secret Name"
          property="apiSecretName"
          form={this.props.form}
          initialValue={this.props.bean.apiSecretName}
          message="Please input the callback api secret name"
          isRequired={isApiSecretNameVisible}
          isVisible={isApiSecretNameVisible}
        >
          <Input placeholder="Secret Name" />
        </FormItem>
        <FormItem
          label="API Secret Value"
          property="apiSecretValue"
          form={this.props.form}
          initialValue={this.props.bean.apiSecretValue}
          message="Please input the callback api secret value"
          isRequired={isApiSecretValueVisible}
          isVisible={isApiSecretValueVisible}
        >
          <Input placeholder="Secret Value" />
        </FormItem>

        <RestMethodRadioGroupFormItem
          form={this.props.form}
          initialValue={this.props.bean.method}
        />

        <FormItem
          label="Priority"
          property="priority"
          form={this.props.form}
          initialValue={this.props.bean.priority}
          message="Please input the callback priority"
          isRequired={true}
        >
          <Input placeholder="Priority" type="number" />
        </FormItem>

        <CancelSubmitButtonBar
          showDelete={this.props.bean.id !== undefined}
          onClose={this.props.onClose}
          onDelete={this.handleDelete}
          createText={this.props.bean.id ? 'Edit' : 'Create'}
          principal={this.props.principal}
          permission="customer/delete/callback"
        />
      </Form>
    );
  }
}
export default Form.create()(CallbackForm);
