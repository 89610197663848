import React, { Component } from 'react';
import { Form, Input, Modal, Select, Checkbox } from 'antd';
import FormItem from '../../shared/form/FormItem';
import CancelSubmitButtonBar from '../../shared/CancelSubmitButtonBar';
import RadioGroup from 'antd/es/radio/group';
import Radio from 'antd/es/radio/radio';
import PropTypes from 'prop-types';

const confirm = Modal.confirm;

class SettingForm extends Component {
  static propTypes = {
    bean: PropTypes.object,
    onSubmit: PropTypes.func,
    onDelete: PropTypes.func
  };
  constructor(props) {
    super();
    this.state = {
      type: props.bean.type
    };
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values);
      }
    });
  };
  handleDelete = () => {
    let p = this.props;
    confirm({
      title: 'Are you sure?',
      onOk() {
        p.onDelete(p.bean.id);
      },
      onCancel() {}
    });
  };
  getValueComponent = () => {
    const isNumber =
      this.state.type === 'integer' || this.state.type === 'long' || this.state.type === 'double';
    if (this.state.type === 'string') return <Input placeholder="Value" />;
    if (isNumber) return <Input placeholder="Value" type="number" />;
    if (this.state.type === 'boolean') return <Checkbox />;
    if (this.state.type === 'duration')
      return (
        <Select>
          <Select.OptGroup label="Time">
            <Select.Option value="PT5M">5 Minutes</Select.Option>
            <Select.Option value="PT15M">15 Minutes</Select.Option>
            <Select.Option value="PT30M">30 Minutes</Select.Option>
            <Select.Option value="PT1H">1 Hour</Select.Option>
            <Select.Option value="PT3H">3 Hours</Select.Option>
            <Select.Option value="PT6H">6 Hours</Select.Option>
            <Select.Option value="PT12H">12 Hours</Select.Option>
            <Select.Option value="PT24H">24 Hours</Select.Option>
          </Select.OptGroup>
          <Select.OptGroup label="Days">
            <Select.Option value="P1D">1 Day</Select.Option>
            <Select.Option value="P3D">3 Days</Select.Option>
            <Select.Option value="P15D">15 Days</Select.Option>
            <Select.Option value="P30D">30 Days</Select.Option>
          </Select.OptGroup>
          <Select.OptGroup label="Months">
            <Select.Option value="P90D">3 Months</Select.Option>
            <Select.Option value="P120D">4 Months</Select.Option>
            <Select.Option value="P180D">6 Months</Select.Option>
            <Select.Option value="P365D">12 Months</Select.Option>
          </Select.OptGroup>
          <Select.OptGroup label="Years">
            <Select.Option value="P730D">2 Year</Select.Option>
            <Select.Option value="P1095D">3 Years</Select.Option>
            <Select.Option value="P2555D">7 Years</Select.Option>
          </Select.OptGroup>
        </Select>
      );
  };
  render() {
    return (
      <Form onSubmit={this.handleSubmit} layout="vertical">
        <FormItem
          label="Key"
          property="key"
          isRequired={true}
          isVisible={true}
          form={this.props.form}
          initialValue={this.props.bean.key}
          message="Please input the setting key"
        >
          <Input placeholder="Key" />
        </FormItem>
        <FormItem
          label="Type"
          property="type"
          isRequired={true}
          isVisible={this.props.bean.id === undefined}
          form={this.props.form}
          initialValue={this.props.bean.type}
          message="Please input the setting type"
        >
          <RadioGroup onChange={e => this.setState({ type: e.target.value })}>
            <Radio value="string">String</Radio>
            <Radio value="integer">Integer</Radio>
            <Radio value="long">Long</Radio>
            <Radio value="double">Double</Radio>
            <Radio value="boolean">Boolean</Radio>
            <Radio value="duration">Duration</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem
          label="Value"
          property="value"
          isVisible={true}
          isRequired={true}
          form={this.props.form}
          initialValue={this.props.bean.value}
          message="Please input the setting value"
        >
          {this.getValueComponent()}
        </FormItem>
        <CancelSubmitButtonBar
          showDelete={this.props.bean.id !== undefined}
          onClose={this.props.onClose}
          onDelete={this.handleDelete}
          createText={this.props.bean.id ? 'Edit' : 'Create'}
          principal={this.props.principal}
          permission="customer/delete/setting"
        />
      </Form>
    );
  }
}

export default Form.create()(SettingForm);
