export const SELECT = "customer/select";
export const SYNC = "customer/sync";
export const CLOSE = "customer/close";
export const CLOSE_CREATE = "customer/close-create";
export const OPEN_CREATE = "customer/open-create";
export const SEARCH = "customer/search";
export const LIST = "customer/list";
export const DELETE = "customer/delete";
export const UPDATE = "customer/update";
export const CREATE = "customer/create";
export const SELECT_SUCCESS = "customer/select-success";
export const SEARCH_SUCCESS = "customer/search-success";
export const LIST_SUCCESS = "customer/list-success";
export const DELETE_SUCCESS = "customer/delete-success";
export const UPDATE_SUCCESS = "customer/update-success";
export const CREATE_SUCCESS = "customer/create-success";
export const CLOSE_SUCCESS = "customer/close-success";
export const SELECT_FAILED = "customer/select-failed";
export const SEARCH_FAILED = "customer/search-failed";
export const DELETE_FAILED = "customer/delete-failed";
export const UPDATE_FAILED = "customer/update-failed";
export const CREATE_FAILED = "customer/create-failed";
export const LOADING = "customer/loading";

export function loading() {
    return { type: LOADING }
}

export function sync(id) {
    return { type: SYNC, id }
}

export function close() {
    return { type: CLOSE }
}

export function closeCreate() {
    return { type: CLOSE_CREATE }
}

export function openCreate() {
    return { type: OPEN_CREATE }
}

export function closeSuccess() {
    return { type: CLOSE_SUCCESS }
}


export function removeSuccess(id) {
    return { type: DELETE_SUCCESS, id }
}

export function removeFailed() {
    return { type: DELETE_FAILED }
}

export function remove(id) {
    return { type: DELETE, id }
}

export function selectSuccess(selected) {
    return { type: SELECT_SUCCESS, selected }
}

export function selectFailed() {
    return { type: SELECT_FAILED }
}

export function select(id) {
    return { type: SELECT, id }
}

export function createSuccess(payload) {
    return { type: CREATE_SUCCESS, payload }
}

export function createFailed() {
    return { type: CREATE_FAILED }
}

export function create(payload) {
    return { type: CREATE, payload }
}

export function update(payload) {
    return { type: UPDATE, payload }
}

export function updateSuccess(payload) {
    return { type: UPDATE_SUCCESS, payload }
}

export function updateFailed() {
    return { type: UPDATE_FAILED }
}

export function search(filter) {
    return { type: SEARCH, filter }
}

export function searchSuccess(filter, pageable) {
    return { type: SEARCH_SUCCESS, filter, pageable }
}

export function list() {
    return { type: LIST }
}

export function listSuccess(list) {
    return { type: LIST_SUCCESS, list }
}


export function searchFailed() {
    return { type: SEARCH_FAILED }
}