import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import RecorderList from './RecorderList';
import RecorderDetails from './RecorderDetails';
import RecorderFilterForm from './RecorderFilterForm';
import { pageableListType, filterType } from '../../types';
import './recorder.css';

class Recorder extends Component {
  render() {
    const {
      selected,
      filter,
      loading,
      pageable,
      url,
      onSearchRecorder,
      onOpenCreateRecorder,
      onSelectRecorder,
      onDeleteRecorder,
      onCloseRecorder,
      onSyncRecorder
    } = this.props;

    return (
      <Fragment>
        {!selected && (
          <div className='recorder-container'>
            <RecorderFilterForm
              filter={filter}
              loading={loading}
              recorder={selected}
              onSearchRecorder={onSearchRecorder}
            />
            <RecorderList
              filter={filter}
              loading={loading}
              pageable={pageable}
              recorder={selected}
              onSearchRecorder={onSearchRecorder}
              onOpenCreateRecorder={onOpenCreateRecorder}
              onSelectRecorder={onSelectRecorder}
              onDeleteRecorder={onDeleteRecorder}
            />
          </div>
        )}
        { selected &&
          <RecorderDetails
            recorder={selected}
            url={url}
            onDeleteRecorder={onDeleteRecorder}
            onCloseRecorder={onCloseRecorder}
            onSyncRecorder={onSyncRecorder}
          />
        }
      </Fragment>
    )
  }
}

Recorder.propTypes = {
  filter: filterType.isRequired,
  pageable: pageableListType.isRequired,
  loading: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  onSearchRecorder: PropTypes.func.isRequired,
  onOpenCreateRecorder: PropTypes.func.isRequired,
  onSelectRecorder: PropTypes.func.isRequired,
  onDeleteRecorder: PropTypes.func.isRequired,
  onCloseRecorder: PropTypes.func.isRequired,
  onSyncRecorder: PropTypes.func.isRequired
};

export default Recorder;
