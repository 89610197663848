import { DDI_PATH } from "../Props.js";
import API from "./api-service";


const jsonHeader = (apiKey) => {
    return {
        timeout: 5000,
        headers: {
            "X-VIVA-Client-ID": apiKey,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }
}

//Ddis are already present in customer payload 
//so filter locally
function filter(filter, customer) {
    let filteredContent = customer.ddis.filter(d => {
        return d.name.includes(filter.name);
    });
    let pagContent = filteredContent.slice(filter.page * filter.size, (filter.page + 1) * filter.size);
    let result = {
        number: filter.page,
        size: filter.size,
        totalElements: filteredContent.length,
        content: pagContent
    };
    return { data: result };
}

//Ddis are already present in customer payload 
//so simply return ddi
function get(data) {
    return {
        data
    };
}

function put(ddi, customer) {
    return API.put(ddi.id, ddi, DDI_PATH, jsonHeader(customer.clientID));
}

function remove(id, customer) {
    return API.remove(id, DDI_PATH, jsonHeader(customer.clientID));
}

function post(ddi, customer) {
    return API.post(ddi, DDI_PATH, jsonHeader(customer.clientID));
}

export default {
    get, filter, put, post, remove
}


