import * as actions from '../actions/callback-actions';

const filter = {
  app: '',
  page: 0,
  size: 5
};

const create = {
  app: 'ivr.generic',
  apiKeyName: '',
  apiKeyValue: '',
  apiSecretName: '',
  apiSecretValue: '',
  url: '',
  authType: 'KEY',
  method: 'POST',
  priority: 10
};

const initialState = {
  filter,
  create,
  showCreate: false,
  showSelected: false,
  selected: undefined,
  pageable: undefined,
  loading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.RESET:
      return {
        ...initialState
      };
    case actions.OPEN_CREATE:
      return {
        ...state,
        create: { ...create },
        showCreate: true
      };
    case actions.CLOSE_CREATE:
      return {
        ...state,
        create: { ...create },
        showCreate: false
      };
    case actions.SELECT_SUCCESS:
      return {
        ...state,
        selected: action.selected,
        loading: false,
        showEdit: true
      };
    case actions.CLOSE_EDIT:
      return {
        ...state,
        selected: undefined,
        showEdit: false
      };
    case actions.SEARCH_SUCCESS:
      return {
        ...state,
        filter: action.filter,
        pageable: action.pageable,
        loading: false
      };
    case actions.UPDATE_SUCCESS:
      const list1 = state.pageable.content.filter(item => item.id !== action.payload.id);
      list1.push(action.payload);
      return {
        ...state,
        selected: undefined,
        showEdit: false,
        loading: false,
        pageable: {
          ...state.pageable,
          content: list1
        }
      };
    case actions.CREATE_SUCCESS:
    case actions.DELETE_SUCCESS:
      return {
        ...state,
        selected: undefined,
        showEdit: false,
        loading: false
      };
    case actions.LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return {
        ...state
      };
  }
};

export default reducer;
