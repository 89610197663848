import React from 'react';
import { Menu, Modal, Dropdown, Icon, message } from 'antd';

const menuItems = [
  { key: 'delete', icon: 'delete', name: 'Delete User', permission: 'user/delete' },
  {
    key: 'reset-password',
    icon: 'issues-close',
    name: 'Reset Password',
    permission: 'user/send/reset'
  },
  {
    key: 'send-activation-email',
    icon: 'issues-close',
    name: 'Send Activation Email',
    permission: 'user/send/activation'
  }
];

const renderMenu = (principal, selected, actions) => (
  <Menu onClick={e => handleMenuClick(e, selected, actions)}>
    {menuItems
      .filter(mi => principal.permissions.filter(pp => mi.permission === pp.permission).length > 0)
      .map(mi => (
        <Menu.Item key={mi.key}>
          <Icon type={mi.icon} />
          {mi.name}
        </Menu.Item>
      ))}
  </Menu>
);

const handleMenuClick = (e, selected, actions) => {
  switch (e.key) {
    case 'delete':
      handleDelete(selected, actions.onDeleteUser);
      break;
    case 'reset-password':
      message.info('The ability to reset password is coming soon!');
      break;
    case 'send-activation-email':
      message.info('The ability to send activation email is coming soon!');
      break;
  }
};

const handleDelete = (selected, onDeleteUser) => {
  Modal.confirm({
    title: 'Are you sure?',
    onOk() {
      onDeleteUser(selected.id);
    },
    onCancel() {}
  });
};

const UserMenu = ({ principal, selected, actions }) => (
  <Dropdown.Button
    onClick={() => actions.onSelectUser(selected.id)}
    overlay={renderMenu(principal, selected, actions)}
  >
    Refresh
  </Dropdown.Button>
);

export default UserMenu;
