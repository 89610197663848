import React, { Fragment } from 'react';
import SettingCreateForm from './SettingCreateForm';
import SettingEditForm from './SettingEditForm';
import SettingFilterForm from './SettingFilterForm';
import StandardToolbar from '../../shared/StandardToolbar';
import SecuredButton from '../../shared/SecuredButton';
import StandardList from '../../shared/StandardList';
import { Drawer } from 'antd';
import './setting.css';

const columns = [
  { title: 'ID', dataIndex: 'id', key: 'id' },
  { title: 'Key', dataIndex: 'key', key: 'key' },
  { title: 'Value', dataIndex: 'value', key: 'value' },
  { title: 'Type', dataIndex: 'type', key: 'type' }
];

const Setting = ({ actions, setting, principal }) => (
  <Fragment>
    <StandardToolbar
      left={<SettingFilterForm filter={setting.filter} onFilter={actions.onSearch} />}
      right={
        <SecuredButton
          icon="plus"
          principal={principal}
          title="Create Setting"
          onClick={actions.onOpenCreate}
          permission="customer/create/setting"
        />
      }
    />

    <StandardList
      {...actions}
      {...setting}
      columns={columns}
      principal={principal}
      permission="customer/edit/setting"
    />
    <Drawer
      width={860}
      title="Create new setting"
      onClose={actions.onCloseCreate}
      visible={setting.showCreate}
    >
      <SettingCreateForm
        principal={principal}
        onCreate={actions.onCreate}
        onClose={actions.onCloseCreate}
        loading={setting.loading}
        create={setting.create}
      />
    </Drawer>
    <Drawer
      title="Edit setting"
      width={860}
      onClose={actions.onCloseEdit}
      visible={setting.showEdit}
    >
      {setting.showEdit && (
        <SettingEditForm
          principal={principal}
          loading={setting.loading}
          onUpdate={actions.onUpdate}
          onDelete={actions.onDelete}
          onClose={actions.onCloseEdit}
          selected={setting.selected}
        />
      )}
    </Drawer>
  </Fragment>
);

export default Setting;
