import React, { Component } from 'react';
import { Form, Input, Modal, Icon, Switch } from 'antd';
import FormItem from '../../shared/form/FormItem';
import CancelSubmitButtonBar from '../../shared/CancelSubmitButtonBar';
import IVROverrideSelectFormItem from './inputs/IVROverrideSelectFormItem';
import TrunkOverrideSelectFormItem from './inputs/TrunkOverrideSelectFormItem';
import RadioGroup from 'antd/es/radio/group';
import Radio from 'antd/es/radio/radio';
import TextArea from 'antd/es/input/TextArea';
import PropTypes from 'prop-types';

const confirm = Modal.confirm;

class DdiForm extends Component {
  static propTypes = {
    customer: PropTypes.object,
    bean: PropTypes.object,
    onSubmit: PropTypes.func,
    onDelete: PropTypes.func
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values);
      }
    });
  };
  handleDelete = () => {
    let p = this.props;
    confirm({
      title: 'Are you sure?',
      onOk() {
        p.onDelete(p.bean.id);
      },
      onCancel() {}
    });
  };
  cleanNumbers = event => {
    let txt = event.currentTarget.value.match(/[0-9]{9,20}/g);
    if (txt != null) {
      txt = txt
        .map(value => {
          let s = value;
          if (s.startsWith('00')) s = value.substr(2);
          if (s.startsWith('0')) s = '44' + value.substr(1);
          return s;
        })
        .filter(value => {
          return (
            this.props.customer.ddis.filter(ddi => {
              return ddi.name === value;
            }).length === 0
          );
        })
        .join('\n');
      this.props.form.setFieldsValue({
        names: txt
      });
    }
  };
  render() {
    return (
      <Form onSubmit={this.handleSubmit} layout="vertical">
        <FormItem
          label="Number"
          property="name"
          isRequired={true}
          form={this.props.form}
          isVisible={this.props.bean.id !== undefined}
          initialValue={this.props.bean.name}
          message="Please input the ddi number"
        >
          <Input placeholder="DDI Number" />
        </FormItem>
        <FormItem
          label="Numbers"
          property="names"
          isRequired={true}
          form={this.props.form}
          isVisible={this.props.bean.id === undefined}
          initialValue={this.props.bean.name}
          message="Please input the ddi number"
        >
          <TextArea
            style={{ height: '180px' }}
            prefix={<Icon type="number" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Number(s)"
            onBlur={this.cleanNumbers}
          />
        </FormItem>
        <FormItem
          label="Direction"
          property="direction"
          isRequired={true}
          form={this.props.form}
          initialValue={this.props.bean.direction}
          message="Please input the ddi direction"
        >
          <RadioGroup>
            <Radio value="inbound">Inbound</Radio>
            <Radio value="outbound">Outbound</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem
          label="Match On"
          property="match"
          isRequired={true}
          form={this.props.form}
          initialValue={this.props.bean.match}
          message="Please input the ddi match type"
        >
          <RadioGroup>
            <Radio value="prefix">Prefix</Radio>
            <Radio value="full">Full</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem
          label="Record"
          property="record"
          isRequired={true}
          form={this.props.form}
          valuePropName="checked"
          initialValue={this.props.bean.record}
          message="Please enter if recorded"
        >
          <Switch />
        </FormItem>
        <IVROverrideSelectFormItem
          form={this.props.form}
          initialValue={this.props.bean.ivr}
          customer={this.props.customer}
        />
        <TrunkOverrideSelectFormItem
          form={this.props.form}
          initialValue={this.props.bean.trunk}
          customer={this.props.customer}
        />
        <CancelSubmitButtonBar
          showDelete={this.props.bean.id !== undefined}
          onClose={this.props.onClose}
          onDelete={this.handleDelete}
          createText={this.props.bean.id ? 'Edit' : 'Create'}
          principal={this.props.principal}
          permission="customer/delete/ddi"
        />
      </Form>
    );
  }
}

export default Form.create()(DdiForm);
