export const SELECT = "trunk/select";
export const DELETE = "trunk/delete";
export const UPDATE = "trunk/update";
export const CREATE = "trunk/create";
export const SEARCH = "trunk/search";

export const SELECT_SUCCESS = "trunk/select-success";
export const SEARCH_SUCCESS = "trunk/search-success";
export const DELETE_SUCCESS = "trunk/delete-success";
export const UPDATE_SUCCESS = "trunk/update-success";
export const CREATE_SUCCESS = "trunk/create-success";

export const SELECT_FAILED = "trunk/select-failed";
export const SEARCH_FAILED = "trunk/search-failed";
export const DELETE_FAILED = "trunk/delete-failed";
export const UPDATE_FAILED = "trunk/update-failed";
export const CREATE_FAILED = "trunk/create-failed";

export const LOADING = "trunk/loading";
export const RESET = "trunk/reset";
export const OPEN_CREATE = "trunk/open-create";
export const CLOSE_CREATE = "trunk/close-create";
export const OPEN_EDIT = "trunk/open-edit";
export const CLOSE_EDIT = "trunk/close-edit";


export function loading() {
    return { type: LOADING }
}

export function reset() {
    return { type: RESET }
}

export function closeCreate() {
    return { type: CLOSE_CREATE }
}

export function closeEdit() {
    return { type: CLOSE_EDIT }
}

export function openCreate() {
    return { type: OPEN_CREATE }
}

export function openEdit() {
    return { type: OPEN_EDIT }
}

export function removeSuccess(id) {
    return { type: DELETE_SUCCESS, id }
}

export function removeFailed() {
    return { type: DELETE_FAILED }
}

export function remove(id) {
    return { type: DELETE, id }
}


export function selectSuccess(selected) {
    return { type: SELECT_SUCCESS, selected }
}

export function selectFailed() {
    return { type: SELECT_FAILED }
}

export function select(selected) {
    return { type: SELECT, selected }
}

export function createSuccess(payload) {
    return { type: CREATE_SUCCESS, payload }
}

export function createFailed() {
    return { type: CREATE_FAILED }
}

export function create(payload) {
    return { type: CREATE, payload }
}

export function update(payload) {
    return { type: UPDATE, payload }
}

export function updateSuccess(payload) {
    return { type: UPDATE_SUCCESS, payload }
}

export function updateFailed() {
    return { type: UPDATE_FAILED }
}

export function search(filter) {
    return { type: SEARCH, filter }
}

export function searchSuccess(filter, pageable) {
    return { type: SEARCH_SUCCESS, filter, pageable }
}

export function searchFailed() {
    return { type: SEARCH_FAILED }
}

