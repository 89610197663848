import React from 'react';
import UserGeneralForm from './UserGeneralForm';
import UserCustomers from './UserCustomers';
import UserRoles from './UserRoles';
import { Card, Tabs } from 'antd';

const tabEnabled = (principal, key) => {
  return principal.permissions.filter(p => p.permission === key);
};

const UserTabs = ({ principal, loading, selected, customers, actions }) => (
  <Card className="user-details-card">
    <Tabs>
      {tabEnabled(principal, 'user/view/general') && (
        <Tabs.TabPane key="general" tab="General">
          <UserGeneralForm
            principal={principal}
            loading={loading}
            selected={selected}
            customers={customers}
            onCustomerList={actions.onCustomerList}
            onUpdateUser={actions.onUpdateUser}
          />
        </Tabs.TabPane>
      )}
      {tabEnabled(principal, 'user/view/customers') && (
        <Tabs.TabPane key="customers" tab="Customers">
          <UserCustomers
            principal={principal}
            loading={loading}
            selected={selected}
            customers={customers}
            onUpdateUser={actions.onUpdateUser}
          />
        </Tabs.TabPane>
      )}
      {tabEnabled(principal, 'user/view/roles') && (
        <Tabs.TabPane key="roles" tab="Roles">
          <UserRoles
            principal={principal}
            loading={loading}
            selected={selected}
            onUpdateUser={actions.onUpdateUser}
          />
        </Tabs.TabPane>
      )}
    </Tabs>
  </Card>
);

export default UserTabs;
