import { put, takeEvery, takeLeading, call, select } from 'redux-saga/effects';
import { message } from 'antd';
import * as actions from '../actions/customer-actions';
import * as trunkActions from '../actions/trunk-actions';
import * as ddiActions from '../actions/ddi-actions';
import * as deviceActions from '../actions/device-actions';
import * as settingActions from '../actions/setting-actions';
import * as callbackActions from '../actions/callback-actions';
import API from '../services/customer-service';

const getAPIKey = state => state.auth.principal.apiKey;
const getTrunkFilter = state => state.trunk.filter;
const getCustomerFilter = state => state.customer.filter;
const getDdiFilter = state => state.ddi.filter;
const getDeviceFilter = state => state.device.filter;
const getSettingFilter = state => state.setting.filter;
const getCallbackFilter = state => state.callback.filter;

function* doCustomerSelect({ id }) {
  yield put(actions.loading());
  const apiKey = yield select(getAPIKey);
  const trunkFilter = yield select(getTrunkFilter);
  const ddiFilter = yield select(getDdiFilter);
  const deviceFilter = yield select(getDeviceFilter);
  const settingFilter = yield select(getSettingFilter);
  const callbackFilter = yield select(getCallbackFilter);
  try {
    const { data } = yield call(API.get, id, apiKey);
    yield put(actions.selectSuccess(data));
    yield put(trunkActions.search(trunkFilter));
    yield put(ddiActions.search(ddiFilter));
    yield put(deviceActions.search(deviceFilter));
    yield put(settingActions.search(settingFilter));
    yield put(callbackActions.search(callbackFilter));
  } catch (e) {
    message.error(e);
  }
}

function* doCustomerUpdate({ payload }) {
  yield put(actions.loading());
  const apiKey = yield select(getAPIKey);
  try {
    yield call(API.put, payload, payload.id, apiKey);
    yield put(actions.updateSuccess(payload));
  } catch (e) {
    message.error(e);
  }
}

function* doCustomerCreate({ payload }) {
  yield put(actions.loading());
  const apiKey = yield select(getAPIKey);
  try {
    const data = yield call(API.post, payload, apiKey);
    const id = yield data.headers.location.replace('customer/', '');
    yield put(actions.select(id));
    yield put(actions.closeCreate());
  } catch (e) {
    message.error(e);
  }
}

function* doCustomerDelete({ id }) {
  yield put(actions.loading());
  const apiKey = yield select(getAPIKey);
  const customerFilter = yield select(getCustomerFilter);
  try {
    yield call(API.remove, id, apiKey);
    yield put(actions.close());
    yield put(actions.search(customerFilter));
  } catch (e) {
    message.error(e);
  }
}

function* doCustomerFilter({ filter }) {
  yield put(actions.loading());
  const apiKey = yield select(getAPIKey);
  try {
    const { data } = yield call(API.filter, filter, apiKey);
    yield put(actions.searchSuccess(filter, data));
  } catch (e) {
    message.error(e);
  }
}

function* doCustomerList() {
  const apiKey = yield select(getAPIKey);
  try {
    const { data } = yield call(API.list, apiKey);
    yield put(actions.listSuccess(data));
  } catch (e) {
    message.error(e);
  }
}

function* doCustomerSync({ id }) {
  yield doCustomerSelect({ id });
}

function* doCustomerClose() {
  yield put(actions.loading());
  yield put(trunkActions.reset());
  yield put(ddiActions.reset());
  yield put(deviceActions.reset());
  yield put(settingActions.reset());
  yield put(callbackActions.reset());
  yield put(actions.closeSuccess());
}

export default function* watchCustomer() {
  yield takeEvery(actions.CLOSE, doCustomerClose);
  yield takeEvery(actions.SYNC, doCustomerSync);
  yield takeEvery(actions.DELETE, doCustomerDelete);
  yield takeEvery(actions.CREATE, doCustomerCreate);
  yield takeLeading(actions.SEARCH, doCustomerFilter);
  yield takeEvery(actions.LIST, doCustomerList);
  yield takeEvery(actions.SELECT, doCustomerSelect);
  yield takeEvery(actions.UPDATE, doCustomerUpdate);
}
