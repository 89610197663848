import { connect } from "react-redux";
import Recorder from '../components/recorder/Recorder';
import * as actions from '../actions/recorder-actions';

const mapStateToProps = (state) => {
  return {
    title: state.view.title,
    selected: state.recorder.selected,
    filter: state.recorder.filter,
    loading: state.recorder.loading,
    pageable: state.recorder.pageable,
    url: state.recorder.url
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSearchRecorder: (filter) => dispatch(actions.search(filter)),
    onOpenCreateRecorder: () => dispatch(actions.openCreate()),
    onSelectRecorder: (payload) => dispatch(actions.select(payload)),
    onDeleteRecorder: (id) => dispatch(actions.remove(id)),
    onCloseRecorder: () => dispatch(actions.close()),
    onSyncRecorder: (id) => dispatch(actions.sync(id))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Recorder);
