import { VIVA_BASE_URL, RECORDER_PATH } from "../Props.js";
import API from "./api-service";
import axios from "axios";
import { get as _get } from "lodash";

const jsonHeader = (apiKey) => {
    return {
        timeout: 10000,
        headers: {
            "X-VIVA-UserID": apiKey
        }
    }
}

function filter(filter, apiKey) {
    return API.filter(filter, RECORDER_PATH, jsonHeader(apiKey));
}

function get(id, apiKey) {
    return API.get(id, RECORDER_PATH, jsonHeader(apiKey));
}

function put(payload, id, apiKey) {
    return API.put(id, payload, RECORDER_PATH, jsonHeader(apiKey));
}

function getS3URL(id, apiKey) {
    return axios.get(VIVA_BASE_URL + RECORDER_PATH + "user/url/" + id, jsonHeader(apiKey))
        .catch(e => error(e));
}

function error(e) {
    let msg = _get(e.response, "data.messages[0]");
    if (msg === undefined)
        msg = "Sorry an error occurred.";
    return Promise.reject(msg);
}

export default {
    get, filter, put, getS3URL
}


