import React from 'react';
import { Col, Row, Card, Tabs } from 'antd';
import TrafficOverview from '../dashboard/TrafficOverview';
import DashboardFilter from '../dashboard/DashboardFilter';
import SimpleXYChart from '../dashboard/cards/SimpleXYChart';

const renderFilter = (customer, dashboard, dashboardActions, principal) => {
  if (customer && dashboard) {
    return (
      <DashboardFilter
        principal={principal}
        id={customer.id}
        onFilter={dashboardActions.onFilter}
        filter={dashboard.filter}
      />
    );
  }
  return '';
};

const CustomerDashboard = ({ customer, dashboard, dashboardActions, principal }) => (
  <Row>
    <Col span={24}>
      <Card type="inner" extra={renderFilter(customer, dashboard, dashboardActions, principal)}>
        <Row>
          <Col span={24} style={{ height: '300px' }}>
            <Tabs style={{ marginBottom: '20px' }}>
              <Tabs.TabPane key="1" tab="Traffic">
                <TrafficOverview
                  title="Traffic"
                  height="190px"
                  id={customer.id}
                  filter={dashboard.filter}
                  dashboard={dashboard}
                  onSyncCategory={dashboardActions.onSyncCategory}
                />
              </Tabs.TabPane>
              {/* <Tabs.TabPane key="2" tab="Integration Errors">
                <IVRErrorChart
                  id={customer.id}
                  filter={dashboard.filter}
                  ivrErrors={dashboard.ivrErrors}
                  sync={dashboardActions.onSyncIVRErrors}
                />
              </Tabs.TabPane> */}
            </Tabs>
          </Col>
          <Col span={8}>
            <SimpleXYChart
              id={customer.id}
              filter={dashboard.filter}
              keyword="band.keyword"
              dataKey="byBandCustomer"
              bucketSize={5}
              xAngle={0}
              yAngle={-25}
              data={dashboard}
              sync={dashboardActions.onSyncCategory}
            />
          </Col>
          <Col span={8}>
            <SimpleXYChart
              id={customer.id}
              filter={dashboard.filter}
              keyword="category.keyword"
              dataKey="byCategoryCustomer"
              bucketSize={5}
              xAngle={0}
              yAngle={-25}
              data={dashboard}
              sync={dashboardActions.onSyncCategory}
            />
          </Col>
          <Col span={8}>
            <SimpleXYChart
              id={customer.id}
              filter={dashboard.filter}
              keyword="direction.keyword"
              dataKey="byDirectionCustomer"
              bucketSize={5}
              xAngle={0}
              yAngle={-35}
              data={dashboard}
              sync={dashboardActions.onSyncCategory}
            />
          </Col>
        </Row>
      </Card>
    </Col>
  </Row>
);

export default CustomerDashboard;
