import { put, takeEvery } from 'redux-saga/effects';
import { ActionConst } from '../actions/constants';
import { complete, success } from '../actions/view-actions';
import { LOGIN_SUCCESS, LOGOUT_SUCCESS } from '../actions/auth-actions';
import * as customerActions from '../actions/customer-actions';
import { push } from 'react-router-redux';
import routes from '../routes';

function* doViewRegister() {
  yield put(complete(routes.register, false));
}

function* doViewLogin() {
  yield put(complete(routes.login, false));
}

function* doViewLogout() {
  yield localStorage.removeItem('user');
  yield put({ type: LOGOUT_SUCCESS });
  yield put(complete(routes.logout, false));
}

function* doViewForgotPassword() {
  yield put(complete(routes.forgotPassword, false));
}

function* doViewDashboard() {
  yield put(complete(routes.dashboard, true));
}

function* doViewCustomers() {
  yield put(complete(routes.customers, true));
}

function* doViewUsers() {
  yield put(complete(routes.users, true));
}

function* doViewRecorder() {
  yield put(complete(routes.recorder, true));
}

function* doCheckPrincipal() {
  const userString = yield localStorage.getItem('user');
  if (userString) {
    yield put({ type: LOGIN_SUCCESS, principal: JSON.parse(userString) });
    yield put(customerActions.list());
    return yield JSON.parse(userString);
  } else {
    return yield false;
  }
}

function* doViewComplete({ route, redirect }) {
  const principal = yield doCheckPrincipal();
  if (!principal && redirect) {
    yield put(push(routes.login));
  } else {
    const permitted = yield checkPrincipalHasViewPermission(principal, route);
    if (permitted) {
      yield put(push(route));
      yield put(success(route));
    } else {
      //TODO Return a not found page.
      yield put(push(routes.notfound));
      yield put(success(routes.notfound));
    }
  }
}

function* checkPrincipalHasViewPermission(principal, route) {
  switch (route) {
    case routes.login:
    case routes.logout:
    case routes.register:
    case routes.forgotPassword:
    case routes.resetPassword:
    case routes.public:
    case routes.notfound:
      return true;
    case routes.dashboard:
      return hasPermission(principal, 'dashboard/view');
    case routes.recorder:
      return hasPermission(principal, 'recorder/view');
    case routes.customers:
      return hasPermission(principal, 'customer/view');
    case routes.users:
      return hasPermission(principal, 'user/view');
    default:
      return false;
  }
}

function hasPermission(principal, permission) {
  return principal.permissions.filter(p => p.permission === permission).length > 0;
}

export default function* watchViews() {
  yield takeEvery(ActionConst.view.register, doViewRegister);
  yield takeEvery(ActionConst.view.login, doViewLogin);
  yield takeEvery(ActionConst.view.logout, doViewLogout);
  yield takeEvery(ActionConst.view.forgotPassword, doViewForgotPassword);
  yield takeEvery(ActionConst.view.dashboard, doViewDashboard);
  yield takeEvery(ActionConst.view.customers, doViewCustomers);
  yield takeEvery(ActionConst.view.users, doViewUsers);
  yield takeEvery(ActionConst.view.recorder, doViewRecorder);
  yield takeEvery(ActionConst.view.complete, doViewComplete);
}
