import React, { Component } from "react";
import RadioGroup from "antd/es/radio/group";
import Radio from "antd/es/radio/radio";
import FormItem from "../../../shared/form/FormItem";

class RestMethodRadioGroupFormItem extends Component {
    render() {
        return (
            <FormItem
                label="Method"
                property="method"
                form={this.props.form}
                initialValue={this.props.initialValue}
                message="Please input the callback rest method"
                isRequired={true}
                isVisible={true}
            >
                <RadioGroup>
                    <Radio value="POST">POST</Radio>
                    <Radio value="GET">GET</Radio>
                </RadioGroup>
            </FormItem>
        )
    }
}

export default RestMethodRadioGroupFormItem;