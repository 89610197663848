import * as actions from "../actions/trunk-actions";

const filter = {
    ip: "",
    page: 0,
    size: 5
}

const create = {
    ip: "",
    port: 5060,
    priority: 10,
    callerFormat: "national",
    calledFormat: "national",
    description: "",
    monitored: false,
    vendor: "generic",
    obp: false
}

const initialState = {
    filter,
    create,
    selected: undefined,
    pageable: undefined,
    loading: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.RESET:
            return {
                ...initialState
            }
        case actions.OPEN_CREATE:
            return {
                ...state,
                create,
                showCreate: true
            }
        case actions.CLOSE_CREATE:
            return {
                ...state,
                create,
                showCreate: false
            }
        case actions.SELECT_SUCCESS:
            return {
                ...state,
                selected: action.selected,
                loading: false,
                showEdit: true
            }
        case actions.CLOSE_EDIT:
            return {
                ...state,
                selected: undefined,
                showEdit: false
            }
        case actions.SEARCH_SUCCESS:
            return {
                ...state,
                filter: action.filter,
                pageable: action.pageable,
                loading: false
            }
        case actions.UPDATE_SUCCESS:
            const list1 = state.pageable.content.filter(item => item.id !== action.payload.id)
            list1.push(action.payload);
            return {
                ...state,
                selected: undefined,
                showEdit: false,
                loading: false,
                pageable: {
                    ...state.pageable,
                    content: list1
                }
            }
        case actions.CREATE_SUCCESS:
        case actions.DELETE_SUCCESS:
            return {
                ...state,
                selected: undefined,
                showEdit: false,
                loading: false,
            }
        case actions.LOADING:
            return {
                ...state,
                loading: true
            }
        default:
            return {
                ...state
            }
    }
};
export default reducer;