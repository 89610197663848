import React, { Fragment } from 'react';
import CustomerList from './CustomerList';
import CustomerDetails from './CustomerDetails';
import CustomerToolbar from './CustomerToolbar';
import CustomerCreate from './CustomerCreate';
import './customer.css';

const Customer = ({ principal, customer, dashboard, customerActions, dashboardActions }) => (
  <Fragment>
    <CustomerToolbar
      principal={principal}
      loading={customer.loading}
      filter={customer.filter}
      selected={customer.selected}
      onSearchCustomer={customerActions.onSearchCustomer}
      onOpenCreateCustomer={customerActions.onOpenCreateCustomer}
    />
    <CustomerList
      principal={principal}
      filter={customer.filter}
      loading={customer.loading}
      selected={customer.selected}
      pageable={customer.pageable}
      onSelectCustomer={customerActions.onSelectCustomer}
      onSearchCustomer={customerActions.onSearchCustomer}
    />
    <CustomerDetails
      principal={principal}
      loading={customer.loading}
      selected={customer.selected}
      dashboard={dashboard}
      customerActions={customerActions}
      dashboardActions={dashboardActions}
    />
    <CustomerCreate
      width={840}
      title="Create Customer"
      create={customer.create}
      onCancel={customerActions.onCloseCreate}
      onCreate={customerActions.onCreateCustomer}
    />
  </Fragment>
);

export default Customer;
