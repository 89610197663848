import React, { Fragment } from 'react';
import { PageHeader } from 'antd';
import CustomerTabs from './CustomerTabs';
import CustomerMenu from './CustomerMenu';

const renderCustomerMenu = (principal, selected, customerActions) => {
  return (
    <CustomerMenu principal={principal} selected={selected} customerActions={customerActions} />
  );
};

const renderPageHeader = (principal, selected, loading, customerActions) => {
  if (selected)
    return (
      <PageHeader
        principal={principal}
        loading={loading}
        title={selected.name}
        subTitle={selected.id}
        extra={renderCustomerMenu(principal, selected, customerActions)}
        onBack={customerActions.onCloseCustomer}
      />
    );
  return '';
};

const renderCustomerTabs = (
  principal,
  selected,
  loading,
  customerActions,
  dashboard,
  dashboardActions
) => {
  if (selected)
    return (
      <CustomerTabs
        selected={selected}
        principal={principal}
        loading={loading}
        dashboard={dashboard}
        customerActions={customerActions}
        dashboardActions={dashboardActions}
      />
    );
  return '';
};

const CustomerDetails = ({
  principal,
  selected,
  loading,
  customerActions,
  dashboard,
  dashboardActions
}) => (
  <Fragment>
    {renderPageHeader(principal, selected, loading, customerActions)}
    {renderCustomerTabs(principal, selected, loading, customerActions, dashboard, dashboardActions)}
  </Fragment>
);

export default CustomerDetails;
