import { ActionConst } from './constants';

export function loading(key) {
  return { type: ActionConst.dashboard.loading, key };
}

export function syncCategory(filter, opts) {
  return {
    type: ActionConst.dashboard.syncCategory,
    filter,
    opts
  };
}

export function sync(filter) {
  return { type: ActionConst.dashboard.sync, filter };
}

export function filter(filter) {
  return { type: ActionConst.dashboard.filter, filter };
}

export function filterSuccess(filter) {
  return { type: ActionConst.dashboard.filterSuccess, filter };
}

export function syncIVRErrors(filter) {
  return { type: ActionConst.dashboard.syncIVRErrors, filter };
}

export function syncCategorySuccess(payload, key) {
  return { type: ActionConst.dashboard.syncCategorySuccess, payload, key };
}

export function syncSuccess(payload) {
  return { type: ActionConst.dashboard.syncSuccess, payload };
}

export function syncIVRErrorsSuccess(payload) {
  return { type: ActionConst.dashboard.syncIVRErrorsSuccess, payload };
}
