import watchAuth from "./auth-saga";
import watchViews from "./view-saga";
import watchCustomer from "./customer-saga";
import watchTrunk from "./trunk-saga";
import watchDdi from "./ddi-saga";
import watchDevice from "./device-saga";
import watchSetting from "./setting-saga";
import watchUser from "./user-saga";
import watchCallback from "./callback-saga";
import watchRecorder from "./recorder-saga";
import watchDashboard from "./dashboard-saga";

import { all } from "redux-saga/effects";

export default function* rootSaga() {
    yield all([
        watchAuth(),
        watchViews(),
        watchCustomer(),
        watchTrunk(),
        watchDdi(),
        watchDevice(),
        watchSetting(),
        watchUser(),
        watchCallback(),
        watchRecorder(),
        watchDashboard()
    ])
}