import { connect } from "react-redux";
import Login from '../components/login/Login';
import { ActionConst } from "../actions/constants";

const { auth, view } = ActionConst;

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLogin: (values) => dispatch({ type: auth.login, username: values.username, password: values.password }),
        onViewRegister: () => dispatch({ type: view.register }),
        onViewForgotPassword: () => dispatch({ type: view.forgotPassword }),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
