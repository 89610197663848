import * as actions from "../actions/recorder-actions";
import moment from "moment";

const initialState = {
    filter: {
        start: moment().subtract(1, "days"),
        end: moment().add(1, "days"),
        caller: undefined,
        called: undefined,
        page: 0,
        size: 10
    },
    selected: undefined,
    pageable: undefined,
    loading: false,
    url: undefined
};

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actions.SELECT_SUCCESS:
            return {
                ...state,
                selected: action.selected,
                loading: false
            }
        case actions.LOAD_SUCCESS:
            return {
                ...state,
                url: action.url
            }
        case actions.CLOSE_SUCCESS:
            return {
                ...state,
                selected: undefined,
                loading: false
            }
        case actions.SEARCH_SUCCESS:
            return {
                ...state,
                filter: action.filter,
                pageable: action.pageable,
                loading: false
            }
        case actions.LOADING:
            return {
                ...state,
                loading: true
            }
        default:
            return {
                ...state
            }
    }


};

export default reducer;