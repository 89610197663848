export default {
  public: '/',
  login: '/ui/login',
  logout: '/ui/logout',
  register: '/ui/register',
  forgotPassword: '/ui/forgot-password',
  resetPassword: '/ui/reset-password/:token',
  dashboard: '/ui/dashboard',
  customers: '/ui/customers',
  users: '/ui/users',
  recorder: '/ui/recorder',
  notfound: '/ui/notfound'
};
