import { USER_PATH } from "../Props.js";
import API from "./api-service";


const jsonHeader = (apiKey) => {
    return {
        timeout: 10000,
        headers: {
            "X-VIVA-UserId": apiKey,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }
}

function filter(filter, apiKey) {
    return API.filter(filter, USER_PATH, jsonHeader(apiKey));
}

function get(id, apiKey) {
    return API.get(id, USER_PATH, jsonHeader(apiKey));
}

function put(payload, id, apiKey) {
    return API.put(id, payload, USER_PATH, jsonHeader(apiKey));
}

function remove(id, apiKey) {
    return API.remove(id, USER_PATH, jsonHeader(apiKey));
}

function post(payload, apiKey) {
    return API.post(payload, USER_PATH, jsonHeader(apiKey));
}

export default {
    get, filter, put, post, remove
}


