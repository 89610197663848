import React, { Component } from 'react';
import DdiForm from './DdiForm';
import PropTypes from 'prop-types';

class DdiCreateForm extends Component {
  static propTypes = {
    customer: PropTypes.object.isRequired,
    create: PropTypes.object.isRequired,
    onCreate: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
  };
  handleSubmit = payload => {
    payload.name = payload.names.replace(/\n/g, ',');
    this.props.onCreate({
      ...payload,
      names: undefined
    });
  };
  render() {
    return (
      <DdiForm
        principal={this.props.principal}
        bean={this.props.create}
        customer={this.props.customer}
        onSubmit={this.handleSubmit}
        onClose={this.props.onClose}
      />
    );
  }
}

export default DdiCreateForm;
