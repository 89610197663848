import React, { Component } from 'react';
import { Layout } from 'antd';
import './Footer.css';

class Footer extends Component {
  render() {
    let today = new Date();
    let year = today.getFullYear();
    return (
      <Layout.Footer className="layout-footer">{`Vibe Communications UK Ltd ©${year}`}</Layout.Footer>
    )
  }
}

export default Footer;
