import React, { Component } from 'react';
import { Card, Tabs } from 'antd';
import CustomerGeneralForm from './CustomerGeneralForm';
import CallbackContainer from '../../containers/CallbackContainer';
import DdiContainer from '../../containers/DdiContainer';
import DeviceContainer from '../../containers/DeviceContainer';
import SettingContainer from '../../containers/SettingContainer';
import TrunkContainer from '../../containers/TrunkContainer';
import CustomerDashboard from './CustomerDashboard';
import CustomerAddressForm from './CustomerAddressForm';

const tabEnabled = (principal, key) => {
  return principal.permissions.filter(p => p.permission === `customer/view/${  key}`).length > 0;
};

const CustomerTabs = ({
  principal,
  loading,
  selected,
  dashboard,
  customerActions,
  dashboardActions
}) => (
  <Card className="customer-details-card">
    <Tabs>
      {tabEnabled(principal, 'general') && (
        <Tabs.TabPane key="general" tab="General">
          <CustomerGeneralForm
            principal={principal}
            loading={loading}
            customer={selected}
            onUpdate={customerActions.onUpdateCustomer}
          />
        </Tabs.TabPane>
      )}

      {tabEnabled(principal, 'address') && (
        <Tabs.TabPane key="address" tab="Address">
          <CustomerAddressForm
            principal={principal}
            customer={selected}
            loading={loading}
            address={selected.address ? selected.address : {}}
            onUpdate={customerActions.onUpdateCustomer}
          />
        </Tabs.TabPane>
      )}
      {tabEnabled(principal, 'callback') && (
        <Tabs.TabPane key="callbacks" tab="Integrations">
          <CallbackContainer />
        </Tabs.TabPane>
      )}
      {tabEnabled(principal, 'trunk') && (
        <Tabs.TabPane key="trunks" tab="SIP Trunks">
          <TrunkContainer />
        </Tabs.TabPane>
      )}
      {tabEnabled(principal, 'ddi') && (
        <Tabs.TabPane key="ddis" tab="Numbers">
          <DdiContainer />
        </Tabs.TabPane>
      )}
      {tabEnabled(principal, 'device') && (
        <Tabs.TabPane key="devices" tab="Devices">
          <DeviceContainer />
        </Tabs.TabPane>
      )}
      {tabEnabled(principal, 'setting') && (
        <Tabs.TabPane key="settings" tab="Settings">
          <SettingContainer />
        </Tabs.TabPane>
      )}
      {tabEnabled(principal, 'stats') && (
        <Tabs.TabPane key="stats" tab="Statistics">
          <CustomerDashboard
            principal={principal}
            customer={selected}
            dashboard={dashboard}
            dashboardActions={dashboardActions}
          />
        </Tabs.TabPane>
      )}
    </Tabs>
  </Card>
);

export default CustomerTabs;
