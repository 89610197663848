import React, { Component } from 'react';
import { Form, Input, Modal, Icon, Checkbox } from 'antd';
import FormItem from '../../shared/form/FormItem';
import CancelSubmitButtonBar from '../../shared/CancelSubmitButtonBar';
import RadioGroup from 'antd/es/radio/group';
import Radio from 'antd/es/radio/radio';
import TextArea from 'antd/es/input/TextArea';
import PropTypes from 'prop-types';

const confirm = Modal.confirm;

class DeviceForm extends Component {
  static propTypes = {
    customer: PropTypes.object,
    bean: PropTypes.object,
    onSubmit: PropTypes.func,
    onDelete: PropTypes.func
  };
  constructor(props) {
    super();
    this.state = {
      type: props.bean.type
    };
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values);
      }
    });
  };
  handleDelete = () => {
    let p = this.props;
    confirm({
      title: 'Are you sure?',
      onOk() {
        p.onDelete(p.bean.id);
      },
      onCancel() {}
    });
  };
  render() {
    return (
      <Form onSubmit={this.handleSubmit} layout="vertical">
        <FormItem
          label="Username"
          property="username"
          isRequired={true}
          isVisible={true}
          form={this.props.form}
          initialValue={this.props.bean.username}
          message="Please input the device username"
        >
          <Input placeholder="Username" />
        </FormItem>
        <FormItem
          label="Extension"
          property="extension"
          isVisible={true}
          isRequired={true}
          form={this.props.form}
          initialValue={this.props.bean.extension}
          message="Please input the device extension"
        >
          <Input placeholder="Extension" type="number" />
        </FormItem>
        <FormItem
          label="Type"
          property="type"
          isRequired={true}
          isVisible={this.props.bean.id === undefined}
          form={this.props.form}
          initialValue={this.props.bean.type}
          message="Please input the device type"
        >
          <RadioGroup onChange={e => this.setState({ type: e.target.value })}>
            <Radio value="CLIENT">SIP Client</Radio>
            <Radio value="SIP">SIP Extension</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem
          label="Password"
          property="password"
          isVisible={this.state.type === 'CLIENT'}
          isRequired={this.state.type === 'CLIENT'}
          form={this.props.form}
          initialValue={this.props.bean.password}
          message="Please input the device password"
        >
          <Input placeholder="Extension" type="password" />
        </FormItem>
        <CancelSubmitButtonBar
          showDelete={this.props.bean.id !== undefined}
          onClose={this.props.onClose}
          onDelete={this.handleDelete}
          createText={this.props.bean.id ? 'Edit' : 'Create'}
          principal={this.props.principal}
          permission="customer/delete/device"
        />
      </Form>
    );
  }
}

export default Form.create()(DeviceForm);
