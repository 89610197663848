import React, { Component } from 'react';
import logo from './vibe-logo_with-badge_white.png';

class NavigationLogo extends Component {
  render() {
    return (
      <div className="logo">
        <img src={logo} />
      </div>
    );
  }
}

export default NavigationLogo;
