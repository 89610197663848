export const SELECT = "user/select";
export const SYNC = "user/sync";
export const CLOSE = "user/close";
export const CLOSE_CREATE = "user/close-create";
export const OPEN_CREATE = "user/open-create";
export const SEARCH = "user/search";
export const DELETE = "user/delete";
export const UPDATE = "user/update";
export const CREATE = "user/create";
export const ADD_ROLE = "user/add-role";
export const ADD_CUSTOMER = "user/add-customer";
export const SELECT_SUCCESS = "user/select-success";
export const SEARCH_SUCCESS = "user/search-success";
export const DELETE_SUCCESS = "user/delete-success";
export const UPDATE_SUCCESS = "user/update-success";
export const CREATE_SUCCESS = "user/create-success";
export const CLOSE_SUCCESS = "user/close-success";
export const ADD_CUSTOMER_SUCCESS = "user/add-customer-success";
export const ADD_ROLE_SUCCESS = "user/add-role-success";
export const SELECT_FAILED = "user/select-failed";
export const SEARCH_FAILED = "user/search-failed";
export const DELETE_FAILED = "user/delete-failed";
export const UPDATE_FAILED = "user/update-failed";
export const CREATE_FAILED = "user/create-failed";
export const LOADING = "user/loading";

export function loading() {
    return { type: LOADING }
}

export function sync(id) {
    return { type: SYNC, id }
}

export function close() {
    return { type: CLOSE }
}

export function closeCreate() {
    return { type: CLOSE_CREATE }
}

export function openCreate() {
    return { type: OPEN_CREATE }
}

export function closeSuccess() {
    return { type: CLOSE_SUCCESS }
}


export function removeSuccess(id) {
    return { type: DELETE_SUCCESS, id }
}

export function removeFailed() {
    return { type: DELETE_FAILED }
}

export function remove(id) {
    return { type: DELETE, id }
}

export function selectSuccess(selected) {
    return { type: SELECT_SUCCESS, selected }
}

export function selectFailed() {
    return { type: SELECT_FAILED }
}

export function select(id) {
    return { type: SELECT, id }
}

export function createSuccess(payload) {
    return { type: CREATE_SUCCESS, payload }
}

export function createFailed() {
    return { type: CREATE_FAILED }
}

export function create(payload) {
    return { type: CREATE, payload }
}

export function update(payload) {
    return { type: UPDATE, payload }
}

export function updateSuccess(payload) {
    return { type: UPDATE_SUCCESS, payload }
}

export function updateFailed() {
    return { type: UPDATE_FAILED }
}

export function search(filter) {
    return { type: SEARCH, filter }
}

export function searchSuccess(filter, pageable) {
    return { type: SEARCH_SUCCESS, filter, pageable }
}

export function searchFailed() {
    return { type: SEARCH_FAILED }
}

export function addRole(role) {
    return { type: ADD_ROLE, role }
}

export function addCustomer(customer) {
    return { type: ADD_CUSTOMER, customer }
}

export function addRoleSuccess(role) {
    return { type: ADD_ROLE_SUCCESS, role }
}

export function addCustomerSuccess(customer) {
    return { type: ADD_CUSTOMER_SUCCESS, customer }
}