import React, { Component } from 'react';
import { Button, Checkbox, Form, Icon, Input } from "antd";

const FormItem = Form.Item;

class LoginForm extends Component {

    onSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onLogin(values);
            }
        });
    };

    render() {

        const { getFieldDecorator } = this.props.form;

        const userIcon = <Icon type="user" />;
        const lockIcon = <Icon type="lock" />;

        const usernameConfig = { rules: [{ required: true, message: 'Please input your username!' }] };
        const passwordConfig = { rules: [{ required: true, message: 'Please input your Password!' }] };
        const rememberMeConfig = { valuePropName: 'checked', initialValue: true };

        return (
            <Form onSubmit={this.onSubmit} className="login-form">
                <FormItem>
                    {getFieldDecorator('username', usernameConfig)(
                        <Input
                            prefix={userIcon}
                            placeholder="Username"
                            autoComplete="username"
                            autoFocus />
                    )}
                </FormItem>
                <FormItem>
                    {getFieldDecorator('password', passwordConfig)(
                        <Input
                            prefix={lockIcon}
                            type="password"
                            placeholder="Password"
                            autoComplete="current-password" />
                    )}
                </FormItem>
                <FormItem>
                    {getFieldDecorator('remember', rememberMeConfig)(
                        <Checkbox>Remember me</Checkbox>
                    )}

                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        block={true}
                    >Log in
                    </Button>

                    <Button
                        type="dashed"
                        htmlType="button"
                        block={true}
                        onClick={this.props.onViewRegister}
                    >
                        Register
                    </Button>

                    <div className="login-form-forgot-holder">
                        <button
                            type="button"
                            className="login-form-forgot"
                            onClick={this.props.onViewForgotPassword}>
                            Forgot password
                        </button>
                    </div>
                </FormItem>
            </Form>
        )
    }
}


export default Form.create()(LoginForm);
