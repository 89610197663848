import React, { Fragment } from 'react';
import { Table, Pagination, Card, Tag } from 'antd';

const getTagColor = t => {
  switch (t) {
    case 'q20':
      return 'blue';
    case 'm6':
      return 'red';
    case 'viva':
      return 'green';
    default:
      return 'grey';
  }
};

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'MBN',
    dataIndex: 'mbn',
    key: 'mbn'
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: t => (t ? <Tag color={getTagColor(t)}>{t.toUpperCase()}</Tag> : '')
  }
];

const determineRoleClick = (principal, id, onSelectCustomer) => {
  if (principal.permissions.filter(p => p.permission === 'customer/view').length > 0)
    onSelectCustomer(id);
};

const renderTable = (principal, pageable, loading, onSelectCustomer) => {
  if (pageable) {
    return (
      <Table
        rowKey="id"
        bordered
        size="small"
        loading={loading}
        pagination={false}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => determineRoleClick(principal, record.id, onSelectCustomer)
          };
        }}
        dataSource={pageable ? pageable.content : []}
        columns={columns}
      />
    );
  }
};

const renderPagination = (pageable, loading, filter, onSearchCustomer) => {
  if (pageable) {
    return (
      <Pagination
        size="small"
        loading={loading}
        defaultCurrent={pageable.number + 1}
        page={pageable.number + 1}
        total={pageable.totalElements}
        pageSize={pageable.size}
        onChange={page =>
          onSearchCustomer({
            ...filter,
            page: page - 1
          })
        }
      />
    );
  }
};

const renderList = (
  principal,
  pageable,
  selected,
  loading,
  filter,
  onSearchCustomer,
  onSelectCustomer
) => {
  if (!selected)
    return (
      <Card type="inner" style={{ minHeight: '100vh' }}>
        {renderTable(principal, pageable, loading, onSelectCustomer)}
        {renderPagination(pageable, loading, filter, onSearchCustomer)}
      </Card>
    );
};

const CustomerList = ({
  principal,
  pageable,
  loading,
  selected,
  filter,
  onSelectCustomer,
  onSearchCustomer
}) => {
  return (
    <Fragment>
      {renderList(
        principal,
        pageable,
        selected,
        loading,
        filter,
        onSearchCustomer,
        onSelectCustomer
      )}
    </Fragment>
  );
};

export default CustomerList;
