import React from 'react';
import SecuredButton from '../shared/SecuredButton';
import UserFilterForm from './UserFilterForm';
import StandardToolbar from '../shared/StandardToolbar';

const createButton = (principal, onOpenCreateCustomer) => (
  <SecuredButton
    icon="plus"
    type="primary"
    title="Create User"
    principal={principal}
    permission="user/create"
    onClick={onOpenCreateCustomer}
  />
);

const filterForm = (principal, loading, filter, onSearchUser) => (
  <UserFilterForm principal={principal} loading={loading} filter={filter} onFilter={onSearchUser} />
);

const Toolbar = ({ principal, loading, filter, selected, onSearchUser, onOpenCreateUser }) => {
  if (!selected)
    return (
      <StandardToolbar
        left={filterForm(principal, loading, filter, onSearchUser)}
        right={createButton(principal, onOpenCreateUser)}
      />
    );
  else return '';
};

const UserToolbar = ({ principal, loading, filter, selected, onSearchUser, onOpenCreateUser }) => (
  <Toolbar
    principal={principal}
    loading={loading}
    filter={filter}
    selected={selected}
    onSearchUser={onSearchUser}
    onOpenCreateUser={onOpenCreateUser}
  />
);

export default UserToolbar;
