import React, { Component } from 'react';
import { Button, Form, Icon, Input } from 'antd';

const FormItem = Form.Item;

class ResetEmailForm extends Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onResetEmail(values.email);
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const lockIcon = <Icon type="lock" />;
    const emailConfig = { rules: [{ required: true, message: 'Please input your Email!' }] };
    return (
      <Form onSubmit={this.handleSubmit} className="reset-email-form">
        <FormItem>
          {getFieldDecorator('email', emailConfig)(
            <Input
              prefix={lockIcon}
              type="email"
              placeholder="Email"
              autoComplete="current-email"
            />
          )}
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit" className="reset-email-form-button" block={true}>
            Confirm
          </Button>
          <Button
            type="dashed"
            htmlType="button"
            onClick={() => this.props.onViewLogin()}
            block={true}
          >
            Back to Login
          </Button>
        </FormItem>
      </Form>
    );
  }
}

export default Form.create()(ResetEmailForm);
