import { VIVA_BASE_URL } from "../Props.js";
import { get as _get } from "lodash";
import axios from "axios";

function filter(filter, path, headers) {
    return axios.post(VIVA_BASE_URL + path + "filter", filter, headers)
        .catch(e => {
            return error(e);
        });
}

function get(id, path, headers) {
    return axios.get(VIVA_BASE_URL + path + id, headers)
        .catch(e => error(e));
}

function list(path, headers) {
    return axios.get(VIVA_BASE_URL + path, headers)
        .catch(e => error(e));
}

function put(id, payload, path, headers) {
    return axios.put(VIVA_BASE_URL + path + id, payload, headers)
        .catch(e => error(e));
}

function post(payload, path, headers) {
    return axios.post(VIVA_BASE_URL + path, payload, headers)
        .catch(e => error(e));
}

function remove(id, path, headers) {
    return axios.delete(VIVA_BASE_URL + path + id, headers)
        .catch(e => error(e));
}


function error(e) {
    let msg = _get(e.response, "data.messages[0]");
    if (msg === undefined)
        msg = "Sorry an error occurred.";
    return Promise.reject(msg);
}

export default {
    get, filter, put, post, remove, list
}

