import { combineReducers } from "redux";
import { routerReducer } from 'react-router-redux';
import authReducer from "../reducers/auth-reducer";
import viewReducer from "../reducers/views-reducer";
import customerReducer from "../reducers/customer-reducer";
import userReducer from "../reducers/user-reducer";
import trunkReducer from "../reducers/trunk-reducer";
import ddiReducer from "../reducers/ddi-reducer";
import deviceReducer from "../reducers/device-reducer";
import settingReducer from "../reducers/setting-reducer";
import callbackReducer from "../reducers/callback-reducer";
import recorderReducer from "../reducers/recorder-reducer";
import dashboardReducer from "../reducers/dashboard-reducer";

const rootReducer = combineReducers({
    routing: routerReducer,
    auth: authReducer,
    view: viewReducer,
    customer: customerReducer,
    trunk: trunkReducer,
    ddi: ddiReducer,
    device: deviceReducer,
    setting: settingReducer,
    user: userReducer,
    callback: callbackReducer,
    recorder: recorderReducer,
    dashboard: dashboardReducer
})

export default rootReducer;

