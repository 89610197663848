import React, { Component, Fragment } from 'react';
import { Layout } from 'antd';
import { Router, Route, Switch } from 'react-router-dom';
import NavigationContainer from './containers/NavigationContainer';
import LoginContainer from './containers/LoginContainer';
import DashboardContainer from './containers/DashboardContainer';
import RegisterContainer from './containers/RegisterContainer';
import ResetPasswordContainer from './containers/ResetPassWordContainer';
import ResetEmailContainer from './containers/ResetEmailContainer';
import LogoutContainer from './containers/LogoutContainer';
import CustomerContainer from './containers/CustomerContainer';
import UserContainer from './containers/UserContainer';
import RecorderContainer from './containers/RecorderContainer';
import Footer from './components/footer';
import routes from './routes.js';
import NotFound from './components/notfound/NotFound';
import './App.css';

const { Content } = Layout;

class App extends Component {
  constructor(props) {
    super(props);
    props.onUpdateView(props.history.location.pathname.replace('ui', 'view').substr(1));
  }

  renderContent = () => {
    const { principal } = this.props;
    if (principal) {
      return (
        <Switch>
          <Route
            strict
            exact
            path={routes.dashboard}
            component={() => <DashboardContainer principal={principal} />}
          />
          <Route
            strict
            exact
            path={routes.customers}
            component={() => <CustomerContainer principal={principal} />}
          />
          <Route
            strict
            exact
            path={routes.users}
            component={() => <UserContainer principal={principal} />}
          />
          <Route
            strict
            exact
            path={routes.recorder}
            component={() => <RecorderContainer principal={principal} />}
          />
          <Route strict exact path={routes.notfound} component={NotFound} />
        </Switch>
      );
    } else {
      return (
        <Switch>
          <Route strict exact path={routes.logout} component={LogoutContainer} />
          <Route strict exact path={routes.login} component={LoginContainer} />
          <Route strict exact path={routes.forgotPassword} component={ResetEmailContainer} />
          <Route strict exact path={routes.register} component={RegisterContainer} />
          <Route path={routes.resetPassword} component={ResetPasswordContainer} />
          <Route strict exact path={routes.notfound} component={NotFound} />
        </Switch>
      );
    }
  };

  render() {
    const { title, history } = this.props;

    return (
      <Fragment>
        <Layout className="layout-main">
          <NavigationContainer title={title} />
          <Layout className="layout-child">
            <Content className="layout-content">
              <Router history={history}>{this.renderContent()}</Router>
            </Content>
          </Layout>
          <Footer />
        </Layout>
      </Fragment>
    );
  }
}

export default App;
