import React from 'react';
import LoginForm from './LoginForm';
import { Avatar } from "antd";
import "./login.css";

const Login = ({ onLogin, onViewRegister, onViewForgotPassword }) => (
  <div className="login-form-holder">
    <div className="login-avatar">
      <Avatar size="large" icon="lock"></Avatar>
      <h2>Login</h2>
    </div>
    <LoginForm
      onLogin={onLogin}
      onViewRegister={onViewRegister}
      onViewForgotPassword={onViewForgotPassword} />
  </div>
)

export default Login;