import React, { Component } from 'react';
import { Button, Form, Icon, Input } from "antd";
import { message } from "antd";

const FormItem = Form.Item;

class ResetPasswordForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (values.password !== values.repeatPassword) {
          message.warn("Passwords do not match");
        } else {
          this.props.onResetPassword(values.repeatPassword, this.props.token);
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const lockIcon = <Icon type="lock" />;
    const passwordConfig = { rules: [{ required: true, message: 'Please input your Password!' }] };

    return (
      <Form onSubmit={this.handleSubmit} className="reset-password-form">
        <FormItem>
          {getFieldDecorator('password', passwordConfig)(
            <Input prefix={lockIcon} type="password" placeholder="Password" autoComplete="new-password" />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('repeatPassword', passwordConfig)(
            <Input prefix={lockIcon} type="password" placeholder="Repeat Password" autoComplete="repeat-password" />
          )}
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit" className="reset-password-form-button" block={true}>Confirm</Button>
        </FormItem>
      </Form>
    )
  }
}

export default Form.create()(ResetPasswordForm);
