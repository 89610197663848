import React, { Component, Fragment } from 'react';
import { Spin } from 'antd';
import moment from 'moment';
import {
  FlexibleXYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  Crosshair,
  VerticalGridLines,
  MarkSeries
} from 'react-vis';

class IVRErrorChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: props.filter,
      crosshairValues: []
    };
    const filter = {
      ...props.filter,
      id: props.id
    };
    props.sync(filter);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.filter !== this.state.filter) {
      const filter = {
        ...nextProps.filter,
        id: this.props.id
      };
      this.setState({ filter: nextProps.filter }, () => this.props.sync(filter));
    }
  }

  render() {
    const { ivrErrors, height, loading, onFilter, filter } = this.props;
    const { crosshairValues } = this.state;
    return (
      <Fragment>
        {!loading && (
          <FlexibleXYPlot
            height={height || 160}
            onMouseLeave={() => this.setState({ crosshairValues: [] })}
          >
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis
              title="Date"
              tickFormat={v => moment(v).format('DD/MM HH:mm')}
              tickLabelAngle={-25}
            />
            <YAxis titwl="Error Count" />
            <MarkSeries
              data={ivrErrors}
              onNearestXY={value => {
                this.setState({
                  crosshairValues: [value]
                });
              }}
            />
            <Crosshair
              titleFormat={d => {
                return { title: 'Customer', value: d[0].name };
              }}
              itemsFormat={d => [
                { title: 'Errors', value: d[0].y },
                { title: 'Date', value: `${moment(parseInt(d[0].x)).format('DD/MM HH:mm')}hrs` }
              ]}
              values={crosshairValues}
            />
          </FlexibleXYPlot>
        )}
        {loading && (
          <div style={{ textAlign: 'center', height: `${height}px` }}>
            <Spin tip="Loading..." style={{ marginTop: '200px' }} />
          </div>
        )}
      </Fragment>
    );
  }
}

export default IVRErrorChart;
