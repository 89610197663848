import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { principalType, pageableListType, columnType } from '../../types';

const determineRoleClick = (principal, bean, permission, onSelect) => {
  if (!principal) return;
  if (principal.permissions.filter(p => p.permission === permission).length > 0) onSelect(bean);
};

const StandardTable = ({ principal, pageable, permission, loading, columns, onSelect }) => (
  <Table
    rowKey="id"
    size="small"
    bordered
    loading={loading}
    pagination={false}
    dataSource={pageable.content}
    columns={columns}
    onRow={bean => {
      return {
        onClick: () => determineRoleClick(principal, bean, permission, onSelect)
      };
    }}
  />
);

StandardTable.propTypes = {
  principal: principalType.isRequired,
  pageable: pageableListType.isRequired,
  permission: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  columns: PropTypes.arrayOf(columnType).isRequired,
  onSelect: PropTypes.func.isRequired
};

export default StandardTable;
