import React, { Component } from 'react';
import SettingForm from './SettingForm';
import PropTypes from 'prop-types';

class SettingCreateForm extends Component {
  static propTypes = {
    create: PropTypes.object.isRequired,
    onCreate: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
  };
  render() {
    return (
      <SettingForm
        principal={this.props.principal}
        bean={this.props.create}
        onSubmit={this.props.onCreate}
        onClose={this.props.onClose}
      />
    );
  }
}
export default SettingCreateForm;
