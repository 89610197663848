export const REDIRECT_BASE_URL = 'https://dev.viva-eu.net'; // 'http://localhost:3000'; // 'https://api.viva-eu.net';
export const VIVA_BASE_URL = 'https://api.viva-eu.net'; // 'http://34.228.128.209:5000';

export const LOGIN_PATH = '/login';
export const REGISTER_PATH = '/login/register';
export const CHANGE_PASSWORD_PATH = '/login/changePassword';
export const RESET_EMAIL_PATH = '/login/forgotten';

export const CUSTOMER_PATH = '/customer/';
export const USER_PATH = '/user/';
export const TRUNK_PATH = '/trunk/';
export const DDI_PATH = '/ddi/';
export const DEVICE_PATH = '/device/';
export const SETTING_PATH = '/setting/';
export const CALLBACK_PATH = '/callback/';
export const RECORDER_PATH = '/recording/';
export const DASHBOARD_PATH = '/stat/dashboard/';
export const STAT_IVR_ERROR_PATH = '/stat/ivrErrors';
export const STAT_CATEGORY_PATH = '/stat/keyword';
