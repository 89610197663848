import React, { Component } from 'react';
import SettingForm from "./SettingForm";
import PropTypes from "prop-types";

class SettingEditForm extends Component {
    static propTypes = {
        selected: PropTypes.object.isRequired,
        onUpdate: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired
    }
    handleSubmit = (payload) => {
        this.props.onUpdate({
            ...this.props.selected,
            ...payload
        })
    }
    render() {
        return (
            <SettingForm
                bean={this.props.selected}
                onSubmit={this.handleSubmit}
                onClose={this.props.onClose}
                onDelete={this.props.onDelete}
            />
        )
    }
}
export default SettingEditForm;