import { connect } from 'react-redux';
import App from '../App';

const mapStateToProps = (state, ownProps) => {
  return {
    principal: state.auth.principal,
    title: state.view.title,
    history: ownProps.history
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateView: type => dispatch({ type })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
