import { put, takeEvery, call, select } from "redux-saga/effects";
import * as actions from "../actions/trunk-actions";
import * as customerActions from "../actions/customer-actions";
import API from "../services/trunk-service";
import { message } from 'antd';

const getCustomer = (state) => state.customer.selected;

function* doTrunkSelect({ selected }) {
    yield put(actions.loading());
    try {
        const { data } = yield call(API.get, selected);
        yield put(actions.selectSuccess(data));
    } catch (e) {
        message.error(e);
    }
};


function* doTrunkUpdate({ payload }) {
    yield put(actions.loading());
    const customer = yield select(getCustomer);
    try {
        yield call(API.put, payload, customer);
        yield put(actions.updateSuccess(payload));
    } catch (e) {
        message.error(e);
    }
};

function* doTrunkCreate({ payload }) {
    yield put(actions.loading());
    const customer = yield select(getCustomer);
    try {
        const data = yield call(API.post, payload, customer);
        payload.id = yield data.headers.location.replace("trunk/", "");
        yield put(actions.createSuccess(payload));
        yield put(customerActions.select(customer.id))
        yield put(actions.closeCreate());
    } catch (e) {
        message.error(e);
    }
};

function* doTrunkDelete({ id }) {
    yield put(actions.loading());
    const customer = yield select(getCustomer);
    try {
        yield call(API.remove, id, customer);
        yield put(actions.removeSuccess(id));
        yield put(customerActions.select(customer.id))
        yield put(actions.closeEdit());
    } catch (e) {
        message.error(e);
    }
};


function* doTrunkFilter({ filter }) {
    yield put(actions.loading());
    const customer = yield select(getCustomer);
    try {
        const { data } = yield call(API.filter, filter, customer);
        yield put(actions.searchSuccess(filter, data));
    } catch (e) {
        message.error(e);
    }
};


export default function* watchTrunk() {
    yield takeEvery(actions.CREATE, doTrunkCreate);
    yield takeEvery(actions.DELETE, doTrunkDelete);
    yield takeEvery(actions.SEARCH, doTrunkFilter);
    yield takeEvery(actions.SELECT, doTrunkSelect);
    yield takeEvery(actions.UPDATE, doTrunkUpdate);
};